import { useCallback, useEffect, useRef } from "react";
import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE } from "@/colors";
import { WIDTH as SCALE_WIDTH } from "@/components/pages/play/ScaleContainer";
import { sfx } from "@/helpers/audio";
import confetti from "canvas-confetti";

export default function useConfetti(options) {
	const optionsRef = useRef(options);
	useEffect(() => void (optionsRef.current = options), [options]);

	const showConfetti = useCallback((parent, element) => {
		const { left, top, width, height } = element.getBoundingClientRect();

		// transform coordinates to normalized window space
		const x = (left + 0.5 * width) / window.innerWidth;
		const y = (top + 0.5 * height) / window.innerHeight;

		const s = parent.getBoundingClientRect().width / SCALE_WIDTH;

		const calculatedOptions = {
			...optionsRef.current,
			origin: { x, y },
			startVelocity: optionsRef.current.scalar * optionsRef.current.startVelocity * s,
			scalar: optionsRef.current.scalar * s,
		};

		confetti(calculatedOptions);

		if (!optionsRef.current.mute) {
			sfx.play("confetti_poof");
		}
	}, []);

	return showConfetti;
}
