import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import geoCentroids from "@/geo/geoCentroids.json";
import { haversineDistance } from "@/helpers/map";
import { PLACE_CODE_SEPARATOR } from "@/app-constants.mjs";
import { CDN_BASE_URL, PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE } from "@/constants";
import isFinite from "lodash/isFinite";
import isObject from "lodash/isObject";

const state = {
	geoFeatures: {
		url: `${CDN_BASE_URL}/geo/geo-features.json`,
		data: null,
		loading: false,
		callbacks: [],
	},
	geoStateMask: {
		url: `${CDN_BASE_URL}/geo/geo-state-mask.json`,
		data: null,
		loading: false,
		callbacks: [],
	},
};

function fetchJSON(state, callback) {
	if (state.data) {
		return callback(null, state.data);
	}

	state.callbacks.push(callback);

	if (!state.loading) {
		state.loading = true;

		const onError = (error) => {
			while (state.callbacks.length) {
				const callback = state.callbacks.shift();
				callback(error);
			}
		};
		fetch(state.url)
			.then((response) => response.json())
			.then((data) => {
				if (isObject(data)) {
					state.data = data;
					while (state.callbacks.length) {
						const callback = state.callbacks.shift();
						callback(null, state.data);
					}
				} else {
					onError("Invalid response");
				}
			})
			.catch((error) => onError(error));
	}
}

export async function getGeoFeatures() {
	return new Promise((resolve, reject) =>
		fetchJSON(state.geoFeatures, (error, geoFeatures) => {
			if (error) {
				return reject(error);
			}
			resolve(geoFeatures);
		})
	);
}

export async function getGeoStateMask() {
	return new Promise((resolve, reject) =>
		fetchJSON(state.geoStateMask, (error, geoStateMask) => {
			if (error) {
				return reject(error);
			}
			resolve(geoStateMask);
		})
	);
}

export async function getGeoFeature({ lat, lng, placeType, placeCode, country, broadSearch }) {
	const geoFeatures = await getGeoFeatures();

	if (placeType && isFinite(lat) && isFinite(lng)) {
		const features = geoFeatures[placeType]?.features?.filter(
			(feature) => !country || feature.properties.id.startsWith(country)
		);
		const boxFeatures = (features || []).filter((feature) => {
			const [west, south, east, north] = feature.bbox;
			if (east < west) {
				return (lng >= west || lng <= east) && lat >= south && lat <= north;
			} else {
				return lng >= west && lng <= east && lat >= south && lat <= north;
			}
		});

		const feature1 = boxFeatures.find((feature) => booleanPointInPolygon([lng, lat], feature));

		if (feature1) {
			return feature1;
		} else if (broadSearch === true && boxFeatures.length > 0) {
			// Find closest country centroid
			const places = boxFeatures
				.map((feature) => [feature, geoCentroids[feature.properties.id]])
				.map(([feature, centroid]) => ({
					feature,
					distance: haversineDistance({ lat, lng }, { lat: centroid[1], lng: centroid[0] }),
				}));

			const closest = places.reduce((acc, loc) => (acc.distance < loc.distance ? acc : loc));
			return closest.feature;
		} else {
			return null;
		}
	} else if (placeCode) {
		const placeType = placeCode.includes(PLACE_CODE_SEPARATOR) ? PLACE_TYPE_STATE : PLACE_TYPE_COUNTRY;
		const features = geoFeatures[placeType]?.features;
		return features?.find((feature) => feature.properties.id === placeCode);
	} else {
		return undefined;
	}
}
