import React, { useState, useCallback, useEffect, useRef } from "react";
import { sfx } from "@/helpers/audio";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Button from "@/components/interactives/Button";
import CheckboxButton from "@/components/interactives/CheckboxButton";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-multicolor.svg";
import trans from "@/helpers/trans";
import { BUTTON_RADIUS } from "@/components/interactives/Button";
import { BUTTON_MAX_HEIGHT } from "@/components/pages/play/slides/BaseSlide";
import isBoolean from "lodash/isBoolean";
import isArray from "lodash/isArray";
import { PLAY_STATUS_WAIT_FOR_ANSWER } from "@/constants";
import useViewportSize from "@/hooks/useViewportSize";

export default function CheckboxAnswersFragment({
	slide,
	slideIndex,
	visible = true,
	onAnswer = null,
	haveLocalPlayer,
	paused,
	setAnswerRef,
	showCorrectAnswers,
	statusWithProgress,
	submittedAnswer,
	noAnimation,
}) {
	const { isDesktop } = useViewportSize();

	const id = slide?.id || null;
	const answers = slide?.answers || [];

	const [haveAnswered, setHaveAnswered] = useState(false);
	const [checkboxValues, setCheckboxValues] = useState(Array(4).fill(false));
	const [hasInteracted, setHasInteracted] = useState(false);

	const [highlightSubmit, setHighlightSubmit] = useState(false);

	const submitButton = useRef(null);

	useEffect(() => {
		if (!highlightSubmit && hasInteracted) {
			// 5s after interaction, highlight the submit button
			const timeout = setTimeout(() => void setHighlightSubmit(true), 5000);
			return () => void clearTimeout(timeout);
		}
	}, [hasInteracted, highlightSubmit]);

	useEffect(() => {
		if (highlightSubmit) {
			submitButton.current.scrollIntoView({ behavior: "smooth", inline: "nearest" });
		}
	}, [highlightSubmit]);

	const anySelected = checkboxValues.some(Boolean);

	useEffect(() => {
		if (
			haveLocalPlayer &&
			onAnswer &&
			statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER &&
			statusWithProgress.progress === 1 &&
			submittedAnswer === null &&
			anySelected
		) {
			// auto-submit answer when time's up
			onAnswer(slideIndex, [...checkboxValues], null, true);
			setHaveAnswered(true);
		}
	}, [
		anySelected,
		checkboxValues,
		haveLocalPlayer,
		onAnswer,
		slideIndex,
		statusWithProgress.name,
		statusWithProgress.progress,
		submittedAnswer,
	]);

	const handleCheckboxSubmit = useCallback(
		(ev) => {
			if (onAnswer) {
				onAnswer(slideIndex, [...checkboxValues]);
			}
			setHaveAnswered(true);
		},
		[checkboxValues, onAnswer, slideIndex]
	);

	useEffect(() => {
		setCheckboxValues(Array(4).fill(false));
	}, [slideIndex]);

	const pointerEvents = !haveAnswered && !paused;

	const [anySelectedAnyTime, setAnySelectedAnyTime] = useState(false);
	useEffect(() => {
		if (anySelected) {
			setAnySelectedAnyTime(true);
		}
	}, [anySelected]);

	const setValue = useCallback(
		(checked, ev) => {
			const newCheckboxAnswers = [...checkboxValues];
			const index = parseInt(ev.currentTarget.getAttribute("data-index"));
			newCheckboxAnswers[index] = checked;
			setCheckboxValues(newCheckboxAnswers);
			setHasInteracted(true);
			setHighlightSubmit(false);
		},
		[checkboxValues]
	);

	const onMouseEnter = useCallback((ev) => {
		sfx.play("hoverFeedback", false, 0.75);
	}, []);

	return (
		<div
			className={tailwindCascade("flex flex-col items-center w-full gap-1 md:gap-[18px]", {
				"opacity-0": !visible,
				"pointer-events-none": !visible,
			})}
		>
			{answers.map((answer, index, answers) => (
				<div
					ref={(element) => {
						if (setAnswerRef) {
							return setAnswerRef(
								index,
								[element, index, answers.length, answer.isCorrect],
								answers.length
							);
						}
					}}
					key={`${id}-${index}`}
					className={tailwindCascade("flex flex-row justify-center w-full", {
						"animate-media": visible && !noAnimation,
					})}
					style={{
						animationDelay: `${((1 - 0.64) / answers.length) * index}s`,
						animationFillMode: "backwards",
					}}
				>
					<div className="relative flex w-full">
						<CheckboxButton
							data-index={index}
							disabled={!haveAnswered ? false : true}
							border={true}
							borderRadius={BUTTON_RADIUS}
							value={
								haveLocalPlayer || hasInteracted
									? checkboxValues[index]
									: answer.isCorrect && showCorrectAnswers
							}
							setValue={setValue}
							onMouseEnter={onMouseEnter}
							className={tailwindCascade(
								"w-full",
								"max-w-lg",
								{
									"pointer-events-auto": pointerEvents,
									"pointer-events-none": !pointerEvents,
									"opacity-25 transition-opacity duration-300":
										showCorrectAnswers && !answer.isCorrect,
								},
								"leading-none"
							)}
							maxHeight={isDesktop ? BUTTON_MAX_HEIGHT : undefined}
							align="left"
						>
							<div className="md:py-4">{answer.text}</div>
						</CheckboxButton>
						{haveLocalPlayer &&
							showCorrectAnswers &&
							isArray(submittedAnswer) &&
							submittedAnswer[index] &&
							(submittedAnswer.some((value, j) => value && !answers[j].isCorrect)
								? !answer.isCorrect && <CorrectionMarker value={false} />
								: answer.isCorrect && <CorrectionMarker value={true} />)}
					</div>
				</div>
			))}

			{(haveLocalPlayer || hasInteracted) && (
				<Button
					ref={submitButton}
					color={anySelectedAnyTime ? "green-light" : "error"}
					border={true}
					onClick={handleCheckboxSubmit}
					className={tailwindCascade("w-full mt-2 md:mt-8 text-white text-2xl", {
						"pointer-events-auto": pointerEvents,
						"pointer-events-none": !pointerEvents || !anySelected,
						"animate-media": visible && !noAnimation,
						"animate-flash":
							highlightSubmit &&
							submittedAnswer === null &&
							anySelected &&
							statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER,
						"pause-anim": paused,
					})}
					disabled={!anySelected || haveAnswered}
					disabledOpacity={anySelectedAnyTime ? true : false}
					style={{
						animationDelay: `${1 - 0.64}s`,
						animationFillMode: "backwards",
					}}
				>
					{anySelectedAnyTime ? trans("Submit answer") : trans("Select one or more")}
				</Button>
			)}
		</div>
	);
}

function CorrectionMarker({ value }) {
	return (
		isBoolean(value) && (
			<div className="top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full">
				{value ? (
					<CorrectIcon className="fill-white w-8 h-8 text-black" />
				) : (
					<WrongIcon className="fill-white w-8 h-8 text-black" />
				)}
			</div>
		)
	);
}
