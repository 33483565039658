import { useEffect } from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { BUTTON_WIDTH, MARGIN_HEIGHT, MARGIN_WIDTH } from "./BaseSlide";
import { sfx, WRONG_ANSWER_SOUNDS } from "@/helpers/audio";
import useSettingsStore from "@/stores/settings";
import trans from "@/helpers/trans";

export default function WrongTypeAnswers({
	className,
	isHost,
	haveLocalPlayer,
	hideIncorrectTypeAnswers,
	maxHeight = 0,
	visible,
	wrongAnswers,
	...props
}) {
	useEffect(() => {
		if (!haveLocalPlayer && wrongAnswers?.length >= 1) {
			sfx.play(WRONG_ANSWER_SOUNDS);
		}
	}, [haveLocalPlayer, wrongAnswers?.length]);

	return (
		<>
			<div
				className={tailwindCascade("md:flex hidden flex-col gap-2 text-white", className)}
				style={{
					width: BUTTON_WIDTH,
					maxHeight: `${maxHeight}px`,
					paddingTop: MARGIN_HEIGHT,
					paddingBottom: MARGIN_WIDTH,
				}}
			>
				{isHost && wrongAnswers.length > 0 && (
					<button
						className="self-start text-2xl underline opacity-50"
						onClick={() => {
							useSettingsStore
								.getState()
								.set((draft) => void (draft.hideIncorrectTypeAnswers = !hideIncorrectTypeAnswers));
						}}
					>
						{hideIncorrectTypeAnswers
							? trans("Show incorrect answers (%d)", wrongAnswers.length)
							: trans("Hide incorrect answers")}
					</button>
				)}
				<div
					className={tailwindCascade(
						"flex-grow w-full h-full overflow-hidden",
						"gap-x-4 gap-y-3 flex flex-row flex-wrap items-center",
						"text-2xl font-bold",
						{ "opacity-0 transition-opacity duration-300": !visible || hideIncorrectTypeAnswers }
					)}
				>
					{(wrongAnswers || [])
						.map((answer, index) => (
							<div className="animate-smash whitespace-nowrap line-through" key={index}>
								{answer}
							</div>
						))
						.reverse()}
				</div>
			</div>
			<div className="md:hidden w-full">
				{isHost && wrongAnswers.length > 0 && (
					<button
						className="self-start text-base text-white underline opacity-50"
						onClick={() => {
							useSettingsStore
								.getState()
								.set((draft) => void (draft.hideIncorrectTypeAnswers = !hideIncorrectTypeAnswers));
						}}
					>
						{hideIncorrectTypeAnswers
							? trans("Show incorrect answers (%d)", wrongAnswers.length)
							: trans("Hide incorrect answers")}
					</button>
				)}
				{wrongAnswers && (
					<div
						className={tailwindCascade(
							"w-full overflow-hidden",
							"text-white font-bold",
							"md:hidden text-base flex flex-row items-center space-x-4",
							{ "opacity-0": hideIncorrectTypeAnswers }
						)}
					>
						{wrongAnswers
							.map((answer, index) => (
								<div className="animate-smash whitespace-nowrap line-through" key={index}>
									{answer}
								</div>
							))
							.reverse()}
					</div>
				)}
			</div>
		</>
	);
}
