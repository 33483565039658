import { useEffect, useState } from "react";

export default function useElementSize(element) {
	const [size, setSize] = useState({
		offsetWidth: 0,
		offsetHeight: 0,
		clientWidth: 0,
		clientHeight: 0,
		domRect: undefined,
	});

	useEffect(() => {
		if (element) {
			const updateSize = (el) =>
				void setSize({
					domRect: el.getBoundingClientRect(), // note: x/y not accurate because resize observer only updates on width/height change!
					offsetWidth: el.offsetWidth,
					offsetHeight: el.offsetHeight,
					clientWidth: el.clientWidth,
					clientHeight: el.clientHeight,
				});
			updateSize(element);

			const observer = new ResizeObserver((entries) => void updateSize(entries[0].target));
			observer.observe(element);

			return () => void observer.disconnect();
		}
	}, [element]);

	return size;
}
