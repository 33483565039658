import isString from "lodash/isString";

const NUM_MAJOR_TICKS = 6;
const getNumDecimals = (str) => (isString(str) && str.includes(".") ? str.split(".")[1].length : 0);

export default function getAutoRange(value) {
	const numDecimals = getNumDecimals(value);
	// console.log("numDecimals", numDecimals);

	if (!isFinite(parseFloat(value))) {
		return ["0", "50"];
	}

	if (numDecimals === 0 && parseFloat(value) >= 0 && parseFloat(value) <= 50) {
		return ["0", "50"];
	}

	const numMinorTicks = (NUM_MAJOR_TICKS - 1) * 10;
	const pos = Math.floor(Math.random() * numMinorTicks) / numMinorTicks;

	const logRange = 3;

	const minRange = numMinorTicks / 10 ** numDecimals;

	let autoMin = parseFloat(value) / (1 + (logRange - 1) * pos);
	let autoMax = logRange * autoMin;

	if (autoMax - autoMin < minRange) {
		const diff = minRange - (autoMax - autoMin);
		const j = Math.random() * diff;
		autoMin -= j;
		autoMax += diff - j;
	}

	return [autoMin.toFixed(numDecimals), autoMax.toFixed(numDecimals)];
}
