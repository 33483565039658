import React, { useRef, memo } from "react";

import isString from "lodash/isString";
import isEqual from "lodash/isEqual";

import onWindowResize from "@/helpers/onWindowResize";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";

import Header from "@/components/Header";

import TypeWriter from "@/components/pages/play/TypeWriter";

const FONT_SIZES = [
	[3, { lineHeight: `${3.5 / 3}em`, letterSpacing: "0.03em" }],
	[2.25, { lineHeight: `${2.7 / 2.25}em`, letterSpacing: "0.03em" }],
	[1.875, { lineHeight: `${2.25 / 1.875}em`, letterSpacing: "0.03em" }],
	[1.5, { lineHeight: `${2 / 1.5}em`, letterSpacing: "0.03em" }],
	[1.25, { lineHeight: `${1.75 / 1.25}em`, letterSpacing: "0.03em" }],
	[1.125, { lineHeight: `${1.75 / 1.125}em`, letterSpacing: "0.03em" }],
	[1, { lineHeight: `${1.5 / 1.1}em`, letterSpacing: "0.03em" }],
	[0.875, { lineHeight: `${1.25 / 0.87}em`, letterSpacing: "0.03em" }],
	[0.75, { lineHeight: `${1 / 0.75}em`, letterSpacing: "0.03em" }],
	[0.625, { lineHeight: `${0.75 / 0.635}em`, letterSpacing: "0.03em" }],
	[0.5, { lineHeight: `${0.5 / 0.5}em`, letterSpacing: "0.03em" }],
	[0.375, { lineHeight: `${0.5 / 0.375}em`, letterSpacing: "0.03em" }],
	[0.25, { lineHeight: `${0.375 / 0.25}em`, letterSpacing: "0.03em" }],
];

function SlideHeader({
	maxHeight = null,
	maxFontSize = 3,
	progress = 1,
	children = null,
	className,
	innerClassName,
	...props
}) {
	const text = isString(children) ? children : "";

	const wrapperRef = useRef(null);
	const headerRef = useRef(null);

	useIsomorphicLayoutEffect(() => {
		if (maxHeight && wrapperRef.current && headerRef.current) {
			const wrapper = wrapperRef.current;
			const header = headerRef.current;

			const resize = () => {
				wrapper.style.position = "absolute";

				for (let i = 0; i < FONT_SIZES.length; i++) {
					header.style.fontSize = `${FONT_SIZES[i][0]}rem`;
					header.style.lineHeight = FONT_SIZES[i][1].lineHeight;
					header.style.letterSpacing = FONT_SIZES[i][1].letterSpacing;

					if (header.offsetHeight <= maxHeight) {
						break;
					}
				}

				wrapper.style.position = null;
			};

			resize(); // Initial resize

			const disposeOnWindowResize = onWindowResize(resize);

			return () => {
				disposeOnWindowResize();
				header.style.fontSize = FONT_SIZES[0][0];
				header.style.lineHeight = FONT_SIZES[0][1].lineHeight;
				header.style.letterSpacing = FONT_SIZES[0][1].letterSpacing;
			};
		}
	}, [progress, text, maxHeight, maxFontSize]);

	return (
		<div className={tailwindCascade("relative", "w-full", "h-full", className)}>
			<div ref={wrapperRef} className="relative">
				<Header
					ref={headerRef}
					className={tailwindCascade(
						"relative text-5xl font-bold tracking-wide text-center text-white",
						innerClassName
					)}
					{...props}
				>
					<TypeWriter text={text} progress={progress} />
				</Header>
			</div>
		</div>
	);
}

const MemorizedSlideHeader = memo(SlideHeader, isEqual);
export default MemorizedSlideHeader;
