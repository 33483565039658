import { useEffect, useState, useRef, useMemo } from "react";
import camelCase from "camelcase";
import sum from "lodash/sum";
import seedrandom from "seedrandom";

import { REVEAL_TYPE_TIMELAPSE_REVERSE } from "@/constants";

const FILL_TIME = 30;

function FYShuffle(arr, seed) {
	const rng = seedrandom(seed);
	let i = arr.length;
	while (--i > 0) {
		const j = Math.floor(rng() * (i + 1));
		const temp = arr[j];
		arr[j] = arr[i];
		arr[i] = temp;
	}
}

export default function TimelapsePlayer({ src, onLoad, type, seed, className, progress, ...props }) {
	const svgRef = useRef(null);

	const [animation, setAnimation] = useState({
		paths: [],
		count: 0,
	});

	const [background, setBackground] = useState([]);

	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		if (loaded && onLoad) {
			onLoad();
		}
	}, [loaded, onLoad]);

	function getPath(node, i) {
		console.assert(node.tagName === "path");

		const path = { i, dSplit: node.getAttribute("d").split(" ") };
		const isFace = node.hasAttribute("fill") && !["none", "undefined"].includes(node.getAttribute("fill"));
		const isEdge = node.hasAttribute("stroke") && !["none", "undefined"].includes(node.getAttribute("stroke"));

		if (isFace) {
			path.fill = node.getAttribute("fill");
		}

		if (isEdge) {
			path.strokeWidth = node.getAttribute("stroke-width");
			path.stroke = node.getAttribute("stroke");
		}

		path.duration = path.stroke ? path.dSplit.length / 3 : FILL_TIME;

		return path;
	}

	useEffect(() => {
		setLoaded(false);

		if (!src) {
			return;
		}

		const controller = new AbortController();
		fetch(src, { cache: "no-cache", signal: controller.signal }) // "no-cache" means poll server for changes even if cache is fresh
			.then((response) => response.text())
			.then((text) => {
				const parser = new DOMParser();
				const svgNode = parser.parseFromString(text, "image/svg+xml").firstElementChild;

				const bgNodes = [];

				while (svgNode.firstElementChild && ["rect", "image"].includes(svgNode.firstElementChild.tagName)) {
					const bgNode = svgNode.removeChild(svgNode.firstElementChild);
					const bgAttrs = {};

					for (const attr of bgNode.attributes) {
						if (["height", "width", "fill", "href", "x", "y"].includes(attr.name)) {
							const name = camelCase(attr.name);
							bgAttrs[attr.name] = attr.value;
						}
					}

					bgNodes.push({ tagName: bgNode.tagName, attributes: bgAttrs });
				}

				setBackground(bgNodes);

				const paths = [];

				for (const node of svgNode.childNodes) {
					if (node.tagName === "defs") {
						for (const def of node.childNodes) {
							paths.push(getPath(def, paths.length));
						}
					} else if (node.tagName === "path") {
						paths.push(getPath(node, paths.length));
					}
				}

				const count = sum(paths.map((path) => path.duration));
				setAnimation({ paths, count });

				setLoaded(true);
			})
			.catch((error) => {
				if (error.name !== "AbortError") {
					console.error(error);
				}
			});

		return () => void controller.abort();
	}, [src, type, seed]);

	const pathOrder = useMemo(() => {
		const arr = Array.from(Array(animation.paths.length).keys());
		if (type === "timelapseRandom") {
			FYShuffle(arr, seed);
		}

		return arr;
	}, [animation.paths.length, seed, type]);

	const numVisiblePoints = useMemo(() => {
		const n = Math.min(animation.count, Math.round(animation.count * progress));
		return type === REVEAL_TYPE_TIMELAPSE_REVERSE ? animation.count - n : n;
	}, [animation.count, progress, type]);

	const visiblePaths = useMemo(() => {
		const paths = new Array(animation.paths.length);
		let numAddedPoints = 0;

		for (const i of pathOrder) {
			const path = animation.paths[i];
			const numPathPoints = path.duration;
			const numPointsToAdd = Math.min(numPathPoints, numVisiblePoints - numAddedPoints);

			if (path.stroke) {
				paths[i] = {
					d: path.dSplit.slice(0, 3 * numPointsToAdd).join(" "),
					i: path.i,
				};

				if (path.stroke) {
					paths[i].stroke = path.stroke;
					paths[i].strokeWidth = path.strokeWidth;
				}

				if (numPointsToAdd === numPathPoints && path.fill) {
					paths[i].fill = path.fill;
					paths[i].fillOpacity = 1;
				}

				numAddedPoints += numPointsToAdd;
			} else {
				paths[i] = {
					d: path.dSplit.join(" "),
					i: path.i,
					fill: path.fill,
					fillOpacity: numPointsToAdd / FILL_TIME,
				};
				numAddedPoints += numPointsToAdd;
			}
		}

		return paths;
	}, [animation.paths, numVisiblePoints, pathOrder]);

	return (
		<svg
			className="left-1/2 relative h-full transform -translate-x-1/2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 400 300"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			ref={svgRef}
		>
			<defs>
				{visiblePaths &&
					visiblePaths.map(({ strokeWidth, stroke, d, fill, i, fillOpacity }, index) => (
						<path
							key={index}
							id={`${seed}-${i}`}
							strokeWidth={strokeWidth}
							stroke={stroke}
							d={d}
							fill={fill}
							fillOpacity={fillOpacity}
						/>
					))}
			</defs>
			{background && (
				<g>
					{background.map((el, i) => (
						<el.tagName key={`bg${i}`} {...el.attributes} />
					))}
				</g>
			)}
			{visiblePaths && (
				<>
					<g>
						{visiblePaths
							.filter((path) => !path.stroke)
							.map((path) => (
								<use key={path.i} href={`#${seed}-${path.i}`} />
							))}
					</g>
					<g>
						{visiblePaths
							.filter((path) => path.stroke)
							.map((path) => (
								<use key={path.i} href={`#${seed}-${path.i}`} />
							))}
					</g>
				</>
			)}
		</svg>
	);
}
