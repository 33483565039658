import React, { useEffect, useRef, memo } from "react";
import { useImmer } from "use-immer";
import isEqual from "lodash/isEqual";

import { createAvatarImageJSON } from "@/helpers/avatar";

function Avatar({
	map,
	position,
	avatar,
	name = null,
	distance = null,
	width = 72,
	height = 72,
	isHost = false,
	zIndex = undefined,
}) {
	const markerRef = useRef(null);

	const [state, updateState] = useImmer({
		map,
		position: {
			lat: isFinite(position?.lat) ? position?.lat : 0,
			lng: isFinite(position?.lng) ? position?.lng : 0,
		},
		width,
		height,
		avatar: avatar,
		distance: distance,
		name: name,
		zIndex,
	});

	useEffect(() => {
		markerRef.current = new window.google.maps.Marker();

		return () => {
			if (markerRef.current) {
				markerRef.current.setMap(null);
				markerRef.current = null;
			}
		};
	}, []);

	useEffect(() => void updateState((draft) => void (draft.map = map)), [map, updateState]);
	useEffect(
		() =>
			void updateState((draft) => {
				draft.position.lat = isFinite(position?.lat) ? position?.lat : 0;
				draft.position.lng = isFinite(position?.lng) ? position?.lng : 0;
			}),
		[position, updateState]
	);

	useEffect(() => {
		updateState((draft) => {
			draft.avatar = avatar;
			draft.distance = distance;
			draft.name = name;
		});
	}, [avatar, distance, name, updateState]);

	useEffect(() => void updateState((draft) => void (draft.width = width)), [updateState, width]);
	useEffect(() => void updateState((draft) => void (draft.height = height)), [height, updateState]);
	useEffect(() => void updateState((draft) => void (draft.zIndex = zIndex)), [zIndex, updateState]);

	useEffect(() => {
		let mounted = true;
		(async () => {
			let image = null;
			try {
				image = await createAvatarImageJSON({
					avatar: state.avatar,
					width: state.width * 2,
					height: state.height * 2,
					border: true,
					distance: state.distance,
					name: state.name,
					isHost: isHost,
				});
				if (mounted) {
					updateState((draft) => void (draft.url = image || null));
				}
			} catch (error) {
				console.error(error);
			}
		})();

		return () => void (mounted = false);
	}, [isHost, state.avatar, state.distance, state.height, state.name, state.width, updateState]);

	/*
	useEffect(() => {
		if (isString(state.avatar)) {
			const url = `${CDN_BASE_URL}/${state.avatar}`;
			const image = new Image();
			image.crossOrigin = "anonymous";
			image.onload = () => {
				image.onload = image.onerror = null;
				image.setAttribute("width", 1000);
				image.setAttribute("height", 1000);
				const canvas = document.createElement("canvas");
				canvas.width = canvas.height = 100;
				const context = canvas.getContext("2d");
				context.drawImage(image, 0, 0, 1000, 1000, 0, 0, 1000, 1000);
				window.open(canvas.toDataURL("image/png"));
			};
			image.onerror = () => {
				image.onload = image.onerror = null;
			};
			image.src = url;
		}
	}, [state.avatar]);
	*/

	useEffect(() => {
		if (markerRef.current) {
			markerRef.current.setPosition(new window.google.maps.LatLng(state.position.lat, state.position.lng));
		}
	}, [state.position]);

	useEffect(() => {
		if (markerRef.current) {
			markerRef.current.setZIndex(state.zIndex);
		}
	}, [state.zIndex]);

	useEffect(() => {
		if (markerRef.current && state.url) {
			let mounted = true;
			markerRef.current.setMap(null);
			const listener = markerRef.current.addListener("icon_changed", () => {
				if (mounted && markerRef.current) {
					markerRef.current.setMap(state.map);
				}
			});
			markerRef.current.setIcon({
				url: state.url,
				size: { width: state.width, height: state.height },
				scaledSize: { width: state.width, height: state.height },
				anchor: { x: state.width / 2, y: state.height / 2 },
				zIndex: state.zIndex,
			});
			return () => {
				mounted = false;
				listener.remove();
			};
		}
	}, [state.map, state.width, state.height, state.url, state.zIndex]);

	return null;
}

const MemorizedAvatar = memo(Avatar, isEqual);
export default MemorizedAvatar;
