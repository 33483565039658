import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle, memo } from "react";
import { createPortal } from "react-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useImmer } from "use-immer";

import debounce from "lodash/debounce";
import isFinite from "lodash/isFinite";
import isEqual from "lodash/isEqual";

import useRefMounted from "@/hooks/useRefMounted";
import useForwardRef from "@/hooks/useForwardRef";

import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import HandPointIcon from "@/images/icons/hand-point.svg";
import EnterFullscreenIcon from "@/images/icons/icon-enter-fullscreen.svg";
import ExitFullscreenIcon from "@/images/icons/icon-exit-fullscreen.svg";

import { deserialize } from "@/helpers/map";

import { SLIDE_TYPE_LOCATION } from "@/app-constants.mjs";

import {
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_API_VERSION,
	GOOGLE_MAPS_LIBRARIES,
	STREET_VIEW_PORTAL_ID,
} from "@/constants";
import { MEDIA_SIZE_BIG, MEDIA_SIZE_SMALL } from "@/components/pages/play/slides/Location";
import { useListener } from "@/hooks/useListener";

function StreetViewInner({ className, media, setMedia, slideAnswers, slideType, ...props }) {
	const [mounted] = useRefMounted();
	const ref = useRef(null);

	const sourceString = media?.source;

	const locationString = useMemo(() => {
		if (slideType === SLIDE_TYPE_LOCATION) {
			const { target } = deserialize(slideAnswers);
			if (isFinite(target?.lat) && isFinite(target?.lng)) {
				return [target.lat, target.lng].join("/");
			}
		}
		return null;
	}, [slideAnswers, slideType]);

	const [initialView, setInitialView] = useState(null);

	useEffect(() => {
		if (sourceString && initialView === null) {
			const [, lat, lng, heading, pitch, zoom] = sourceString.split("/").map(parseFloat);
			setInitialView({ lat, lng, heading, pitch, zoom });
		}
	}, [initialView, sourceString]);

	const streetView = useMemo(() => {
		return (
			mounted &&
			initialView !== null &&
			new window.google.maps.StreetViewPanorama(ref.current, {
				position: { lat: initialView.lat, lng: initialView.lng },
				pov: { heading: initialView.heading, pitch: initialView.pitch, zoom: initialView.zoom },
				panControl: false,
				zoomControl: false,
				addressControl: false,
				showRoadLabels: false,
			})
		);
	}, [initialView, mounted]);

	useEffect(() => {
		if (streetView && locationString) {
			const [lat, lng] = locationString.split("/").map(parseFloat);
			const pos = streetView.getPosition().toJSON();

			if (lat !== pos.lat || lng !== pos.lng) {
				streetView.setPosition({ lat, lng });
				streetView.setPov({ heading: 0, pitch: 0, zoom: 1 });
			}
		}
	}, [locationString, setMedia, streetView]);

	useEffect(() => {
		if (streetView && sourceString) {
			const [, lat, lng, heading, pitch, zoom] = sourceString.split("/").map(parseFloat);

			const position = streetView.getPosition().toJSON();
			const pov = streetView.getPov();

			if (lat !== position.lat || lng !== position.lng) {
				streetView.setPosition({ lat, lng });
			}

			if (heading !== pov.heading || pitch !== pov.pitch || zoom !== pov.zoom) {
				streetView.setPov({ heading, pitch, zoom });
			}
		}
	}, [sourceString, streetView]);

	const onViewChange = useCallback(
		(streetView) => {
			const { lat, lng } = streetView.getPosition().toJSON();
			const { heading, pitch, zoom } = streetView.getPov();
			const source = ["street", lat, lng, heading, pitch, zoom].join("/");
			setMedia({ source });
		},
		[setMedia]
	);

	useListener(streetView, "position_changed", onViewChange);
	useListener(streetView, "pov_changed", onViewChange);

	return (
		<div className={tailwindCascade("pb-4/3 relative w-full h-0", className)}>
			<div className="absolute inset-0" ref={ref}></div>
		</div>
	);
}

export default function StreetView({ ...props }) {
	return (
		<Wrapper apiKey={GOOGLE_MAPS_API_KEY} version={GOOGLE_MAPS_API_VERSION} libraries={GOOGLE_MAPS_LIBRARIES}>
			<StreetViewInner {...props} />
		</Wrapper>
	);
}

const StreetViewPlayInner = forwardRef(function StreetViewPlayInner(
	{
		position = null,
		pov = null,
		dimMedia = false,
		visible = true,
		fullscreen = false,
		interactive = true,
		onLoad,
		onClickFullscreen,
		paused,
		mediaSize,
		...props
	},
	forwardedRef
) {
	const [mounted] = useRefMounted();
	const [loaded, setLoaded] = useState(false);
	const imperativeHandleRef = useForwardRef(forwardedRef);

	const ref = useRef(null);
	const containerRef = useRef(null);

	const streetView = useMemo(() => {
		const options = {
			position,
			pov,
			panControl: false,
			zoomControl: false,
			addressControl: false,
			showRoadLabels: false,
			fullscreenControl: false,
		};

		if (mounted && position !== null && pov !== null) {
			setLoaded(false);
			return new window.google.maps.StreetViewPanorama(ref.current, options);
		} else {
			return undefined;
		}
	}, [position, pov, mounted]);

	useImperativeHandle(
		imperativeHandleRef,
		() => ({
			resize() {
				if (streetView && loaded && visible) {
					window.google.maps.event.trigger(streetView, "resize");
				}
			},
		}),
		[streetView, loaded, visible]
	);

	useListener(streetView, "status_changed", (streetView) => {
		if (streetView.getStatus() === "OK") {
			setLoaded(true);
			if (onLoad) {
				onLoad();
			}
		}
	});

	const [hasInteracted, setHasInteracted] = useState(false);
	const onFirstInteraction = useMemo(
		() => (hasInteracted ? undefined : () => void setHasInteracted(true)),
		[hasInteracted]
	);

	useEffect(() => {
		if (streetView && !hasInteracted && !paused && loaded && visible && interactive) {
			const interval = setInterval(() => {
				const pov = streetView.getPov();
				pov.heading += 0.1;
				streetView.setPov(pov);
			}, 1000 / 25);
			return () => void clearInterval(interval);
		}
	}, [streetView, hasInteracted, paused, loaded, visible, interactive]);

	return (
		<div
			ref={containerRef}
			className={tailwindCascade("absolute top-0 left-0 w-full h-full", {
				"sr-only": !visible,
				"pointer-events-none": mediaSize === MEDIA_SIZE_SMALL,
			})}
		>
			<button
				className={tailwindCascade("absolute top-0 left-0 block w-full h-full cursor-default", {
					"pointer-events-none": dimMedia,
				})}
				onMouseDown={onFirstInteraction}
				onTouchStart={onFirstInteraction}
				onWheel={onFirstInteraction}
				ref={ref}
			/>
			{!hasInteracted && mediaSize !== MEDIA_SIZE_SMALL && (
				<div
					className={tailwindCascade(
						"absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
						"z-1 md:px-4 md:py-2 py-1 pr-4 pl-2 rounded-lg bg-black bg-opacity-70",
						"text-white md:text-xl lg:text-3xl text-base font-bold",
						"flex flex-row gap-4 items-center",
						"pointer-events-none"
					)}
				>
					<HandPointIcon className="w-12 h-12 text-white" />
					<p className="whitespace-nowrap">{trans("Drag here to look around")}</p>
				</div>
			)}
			{hasInteracted && onClickFullscreen && (
				<button
					className="right-2 top-2 z-1 bg-opacity-70 absolute p-2 bg-black rounded-md cursor-pointer"
					onClick={onClickFullscreen}
				>
					{fullscreen ? (
						<ExitFullscreenIcon className="md:w-7 md:h-7 w-5 h-5 text-white" />
					) : (
						<EnterFullscreenIcon className="md:w-7 md:h-7 w-5 h-5 text-white" />
					)}
				</button>
			)}
			{dimMedia && <div className="z-1 absolute inset-0 bg-black bg-opacity-50 pointer-events-none"></div>}
		</div>
	);
});

const StreetViewPortal = forwardRef(function StreetViewPortal(
	{ className, visible = true, fullscreen = false, onResize = null, children, mediaSize },
	forwardedRef
) {
	const [mounted, mountedRef] = useRefMounted();

	const onResizeRef = useRef(onResize);
	useEffect(() => void (onResizeRef.current = onResize), [onResize]);

	const ref = useForwardRef(forwardedRef);
	const containerRef = useRef(null);
	const [boundingClientRect, updateBoundingClientRect] = useImmer({
		left: 0,
		top: 0,
		width: 0,
		height: 0,
	});

	useImperativeHandle(
		ref,
		() => ({
			update(boundingClientRect) {
				if (mountedRef.current) {
					updateBoundingClientRect((draft) => {
						draft.left = boundingClientRect.left;
						draft.top = boundingClientRect.top;
						draft.width = boundingClientRect.width;
						draft.height = boundingClientRect.height;
					});
				}
			},
		}),
		[]
	);

	useEffect(() => {
		const element = document.getElementById(STREET_VIEW_PORTAL_ID);
		if (element) {
			const style = element.style;

			style.pointerEvents = visible ? "auto" : "none";

			if (fullscreen) {
				style.left = "0";
				style.top = "0";
				style.width = "100%";
				style.height = "100%";
			} else {
				style.left = `${boundingClientRect.left}px`;
				style.top = `${boundingClientRect.top}px`;
				style.width = `${boundingClientRect.width}px`;
				style.height = `${boundingClientRect.height}px`;
			}

			if (onResizeRef.current) {
				onResizeRef.current();
			}
		}
	}, [
		boundingClientRect.left,
		boundingClientRect.top,
		boundingClientRect.width,
		boundingClientRect.height,
		fullscreen,
		mounted,
		visible,
	]);

	if (!mounted) {
		return null;
	}

	return createPortal(
		<div
			ref={containerRef}
			className={tailwindCascade(
				"absolute left-0 top-0 w-full h-full flex items-center justify-center z-3",
				{
					"rounded-md md:rounded-[calc(1.5rem*var(--scale))] overflow-hidden": !fullscreen,
					"bg-black bg-opacity-70": fullscreen && visible,
					"pointer-events-none": mediaSize === MEDIA_SIZE_SMALL,
					"opacity-0 pointer-events-none": !visible,
				},
				className
			)}
		>
			<div
				className={tailwindCascade("absolute left-0 top-0 w-full h-full", {
					"md:left-[1rem] md:top-[4rem] md:w-[calc(100%-2rem)] md:h-[calc(100%-5rem)] md:rounded-[calc(1.5rem*var(--scale))] overflow-hidden":
						fullscreen && visible,
				})}
			>
				{children}
			</div>
		</div>,
		document.getElementById(STREET_VIEW_PORTAL_ID)
	);
});

export const StreetViewPlay = memo(StreetViewPlayInternal, isEqual);

function StreetViewPlayInternal({ visible = true, mediaSize, onError, ...props }) {
	const [mounted, mountedRef] = useRefMounted();
	const measureRef = useRef(null);
	const modalRef = useRef(null);
	const streetViewPlayInnerRef = useRef(null);

	const onResize = useCallback(() => {
		if (streetViewPlayInnerRef.current) {
			streetViewPlayInnerRef.current.resize();
		}
	}, []);
	const onResizeDebounced = useMemo(() => debounce(onResize, 250), [onResize]);

	const [fullscreen, setFullscreen] = useState(false);
	const onClickFullscreen = useCallback(() => void setFullscreen(!fullscreen), [fullscreen]);

	useEffect(() => {
		let active = true;
		let request = null;
		let state = {
			left: 0,
			top: 0,
			width: 0,
			height: 0,
		};

		const loop = () => {
			request = null;

			if (measureRef.current && modalRef.current) {
				const boundingClientRect = measureRef.current.getBoundingClientRect();

				const left = boundingClientRect.left + window.pageXOffset;
				const top = boundingClientRect.top + window.pageYOffset;

				let updated = false;
				if (left !== state.left) {
					state.left = left;
					updated = true;
				}
				if (top !== state.top) {
					state.top = top;
					updated = true;
				}
				if (boundingClientRect.width !== state.width) {
					state.width = boundingClientRect.width;
					updated = true;
				}
				if (boundingClientRect.height !== state.height) {
					state.height = boundingClientRect.height;
					updated = true;
				}

				if (mountedRef.current && updated) {
					modalRef.current.update(state);
				}
			}

			if (mountedRef.current && active) {
				request = requestAnimationFrame(loop);
			}
		};

		loop(); // Request animation frame to handle reveal transition

		return () => {
			active = false;
			if (request) {
				cancelAnimationFrame(request);
				request = null;
			}
		};
	}, [mounted, mountedRef]);

	const staticSrc = useMemo(() => {
		const { lat, lng } = props.position || {};
		const { heading, pitch, zoom } = props.pov || {};
		const fov = (Math.atan(Math.pow(2, 1 - zoom)) * 360) / Math.PI;

		return (
			[lat, lng, heading, pitch, fov].every(isFinite) &&
			"https://maps.googleapis.com/maps/api/streetview?" +
				[
					`size=${640}x${480}`,
					`location=${lat},${lng}`,
					`fov=${fov}`,
					`heading=${heading}`,
					`pitch=${pitch}`,
					`key=${GOOGLE_MAPS_API_KEY}`,
				].join("&")
		);
	}, [props.position, props.pov]);

	const metadataUrl = useMemo(() => {
		const { lat, lng } = props.position || {};
		const { heading, pitch, zoom } = props.pov || {};
		const fov = (Math.atan(Math.pow(2, 1 - zoom)) * 360) / Math.PI;

		return (
			"https://maps.googleapis.com/maps/api/streetview/metadata?" +
			[
				`size=${640}x${480}`,
				`location=${lat},${lng}`,
				`fov=${fov}`,
				`heading=${heading}`,
				`pitch=${pitch}`,
				`key=${GOOGLE_MAPS_API_KEY}`,
			].join("&")
		);
	}, [props.position, props.pov]);

	useEffect(() => {
		if (onError && metadataUrl) {
			fetch(metadataUrl)
				.then((response) => response.json())
				.then((obj) => {
					if (obj.status === "ZERO_RESULTS") {
						onError();
					}
				});
		}
	}, [metadataUrl, onError]);

	return (
		<>
			<div className="pb-4/3 relative w-full h-0">
				<div ref={measureRef} className="-top-9 md:top-0 absolute left-0 w-full h-full" />
				{staticSrc && <img src={staticSrc} className="absolute w-full h-full" alt="" />}
			</div>
			{props.prefetch !== true && (
				<StreetViewPortal
					ref={modalRef}
					visible={visible}
					fullscreen={mediaSize !== MEDIA_SIZE_SMALL && fullscreen}
					onResize={onResizeDebounced}
					className={tailwindCascade({ "opacity-0 pointer-events-none": props.interactive === false })}
				>
					<Wrapper
						apiKey={GOOGLE_MAPS_API_KEY}
						version={GOOGLE_MAPS_API_VERSION}
						libraries={GOOGLE_MAPS_LIBRARIES}
					>
						<StreetViewPlayInner
							ref={streetViewPlayInnerRef}
							visible={visible}
							fullscreen={mediaSize !== MEDIA_SIZE_SMALL && fullscreen}
							onClickFullscreen={mediaSize !== MEDIA_SIZE_SMALL && onClickFullscreen}
							mediaSize={mediaSize}
							{...props}
						/>
					</Wrapper>
				</StreetViewPortal>
			)}
		</>
	);
}
