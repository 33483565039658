import React, { forwardRef, useMemo } from "react";

import useForwardRef from "@/hooks/useForwardRef";
import { tailwindCascade } from "@/helpers/tailwindCascade";

export const WIDTH = 1920;
export const HEIGHT = 1080;

export default forwardRef(function ScaleContainer({ className, children, props, noeffect }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	const style = useMemo(() => {
		if (noeffect) {
			return undefined;
		} else {
			return {
				width: `${WIDTH}px`,
				height: `${HEIGHT}px`,
				transform: "translate(var(--x), var(--y)) scale(var(--scale)) translate(-50%, -50%)",
			};
		}
	}, [noeffect]);

	return (
		<div ref={ref} className={tailwindCascade({ "fixed inset-0": !noeffect }, className)} {...props}>
			<div
				className={tailwindCascade({
					"top-1/2 left-1/2 absolute overflow-visible origin-top-left": !noeffect,
					"w-full h-full": noeffect,
				})}
				style={style}
			>
				<div className={tailwindCascade("relative w-full h-full")}>{children}</div>
			</div>
		</div>
	);
});
