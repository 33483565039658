import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import gsap from "@/helpers/gsap";
import { useImmer } from "use-immer";
import isEqual from "lodash/isEqual";

import Avatar from "@/components/Avatar";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import onWindowResize from "@/helpers/onWindowResize";
import useStatusSequence from "@/hooks/useStatusSequence";

import {
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_WAIT_FOR_ANSWER,
} from "@/constants";
import { isPlayerConnected } from "@/helpers/player";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";

const AVATAR_SIZE = 152 * 2;

function Player({
	player,
	showSelectedAnswer,
	showCorrectness,
	showDone,
	numberOfVisiblePlayers,
	yOffset = 0,
	isLobby = false,
	teamMode,
	slideIndex,
}) {
	const ref = useRef(null);

	const numberOfVisiblePlayersRef = useRef(numberOfVisiblePlayers);
	useEffect(() => void (numberOfVisiblePlayersRef.current = numberOfVisiblePlayers), [numberOfVisiblePlayers]);

	useEffect(() => {
		if (ref.current) {
			gsap.set(ref.current, {
				xPercent: 100 * numberOfVisiblePlayersRef.current + 100 * 2,
				yPercent: yOffset,
			}); // Start 2 player avatars width to the right
		}
	}, [yOffset]);

	const selectedAnswer = showSelectedAnswer ? player?.selectedAnswer : undefined;
	const correctness = showCorrectness ? player?.correctness : undefined;
	const checkmark = showDone && player ? (player.created ? true : undefined) : undefined;

	const onLoad = useCallback((src) => {
		if (ref.current) {
			gsap.set(ref.current, { visibility: "visible" });
		}
	}, []);

	const numPlayersAnswered = useMemo(
		() => (teamMode ? player.history?.[slideIndex]?.points.length : undefined),
		[player.history, slideIndex, teamMode]
	);

	useEffect(() => {
		if (numPlayersAnswered > 1 && ref.current) {
			gsap.fromTo(ref.current, { scale: 1.3 }, { scale: 1, duration: 0.3 });
		}
	}, [numPlayersAnswered]);

	const playerPoints = useMemo(() => {
		if (!teamMode) {
			return player.previousPoints;
		}

		const team = player;

		const historyEntry = team.history[slideIndex];
		if (historyEntry) {
			return `${historyEntry.points.length}/${team.numPlayers}`;
		}

		return "";
	}, [player, slideIndex, teamMode]);

	return (
		<div
			ref={ref}
			data-type="player"
			className={tailwindCascade("absolute left-0 invisible transform", {
				"top-[20px] w-[10%]": !isLobby,
				"top-[34px] w-[25%] sm:w-[20%] md:w-[25%] lg:w-[16.666667%] xl:w-[14.285714%] 2xl:w-[12.5%]": isLobby,
			})}
		>
			<div className="md:pr-6 lg:pr-8 relative w-full pr-4">
				<div className="pb-full relative w-full">
					<div className="left-2 md:left-3 lg:left-4 absolute top-0 w-full h-full">
						<Avatar
							className="relative w-full h-full"
							playerAvatar={player.avatar}
							playerName={player.name}
							playerPoints={playerPoints}
							playerConnectionId={player.connectionId}
							showShuffle={false}
							showName={true}
							showPoints={true}
							selectedAnswer={selectedAnswer}
							correctness={correctness}
							checkmark={checkmark}
							onLoad={onLoad}
							isLobby={isLobby}
							width={AVATAR_SIZE}
							height={AVATAR_SIZE}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
const MemorizedPlayer = memo(Player, isEqual); // Use deep comparison

const PLACE_HOLDERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

function PlaceHolder({ index }) {
	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) {
			gsap.set(ref.current, {
				opacity: 1,
				xPercent: index * 100,
			});
		}
	}, [index]);

	return (
		<div
			ref={ref}
			className="absolute w-[25%] sm:w-[20%] md:w-[25%] lg:w-[16.666667%] xl:w-[14.285714%] 2xl:w-[12.5%] opacity-0"
		>
			<div className="pb-full relative w-full">
				<div className="absolute w-full h-full p-4 top-[4px] left-0">
					<div className="bg-opacity-10 relative w-full h-full bg-white rounded-full" />
				</div>
			</div>
		</div>
	);
}

function PlaceHolders({ visible = true }) {
	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) {
			gsap.to(ref.current, {
				opacity: visible ? 1 : 0,
			});
		}
	}, [visible]);

	return (
		<div ref={ref} className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-100">
			{PLACE_HOLDERS.map((_placeholder, index) => (
				<PlaceHolder key={index} index={index} />
			))}

			<div className="absolute w-[25%] sm:w-[20%] md:w-[25%] lg:w-[16.666667%] xl:w-[14.285714%] 2xl:w-[12.5%] left-1/2 transform -translate-x-1/2">
				<div className="pb-full relative w-full">
					<div className="absolute w-full h-full p-4 top-[4px] left-0">
						<div className="whitespace-nowrap top-1/2 left-1/2 absolute font-sans text-xl font-black leading-none text-center text-white transform -translate-x-1/2 -translate-y-1/2">
							<span className="md:inline hidden">
								{trans("Ask your friends to join with the PIN above")}
							</span>
							<span className="md:hidden inline text-base">
								{trans("Ask you friends to join with the link below")}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function Players({
	slideIndex = -1,
	players = null,
	allowLocalJoin = false,
	haveLocalPlayer = false,
	onLocalJoin = null,
	showSelectedAnswer = undefined,
	showCorrectness = undefined,
	showLocalJoin = undefined,
	isLobby = false,
	yOffset = 0,
	className,
	teamMode,
}) {
	const ref = useRef(null);
	const containerRef = useRef(null);

	const [playersOrder, updatePlayersOrder] = useImmer([]);
	const [numVisiblePlayers, setNumVisiblePlayers] = useState(isLobby ? 4 : 10);
	const numVisiblePlayersRef = useRef(numVisiblePlayers);

	useEffect(() => {
		const resize = () => {
			let numVisiblePlayers = isLobby ? 4 : 10;
			if (isLobby) {
				const innerWidth = window.innerWidth;
				if (innerWidth >= 1536) {
					numVisiblePlayers = 8;
				} else if (innerWidth >= 1280) {
					numVisiblePlayers = 7;
				} else if (innerWidth >= 1024) {
					numVisiblePlayers = 6;
				} else if (innerWidth >= 768) {
					numVisiblePlayers = 4;
				} else if (innerWidth >= 640) {
					numVisiblePlayers = 5;
				}
			}
			setNumVisiblePlayers(numVisiblePlayers);
		};

		resize(); // Initial resize

		return onWindowResize(resize);
	}, [isLobby]);

	useIsomorphicLayoutEffect(() => {
		updatePlayersOrder((draft) => {
			for (const player of players.values()) {
				let found = false;
				for (let j = 0; j < draft.length; j++) {
					if (player.connectionId === draft[j].connectionId) {
						if (!isPlayerConnected(player)) {
							found = true;
							draft.splice(j, 1);
							break;
						} else if (isLobby || slideIndex in player.history) {
							found = true;
							draft[j] = player;
							break;
						}
					}
				}

				if (!found && isPlayerConnected(player) && player.avatar) {
					if (isLobby || slideIndex in player.history) {
						draft.push(player);
					}
				}
			}

			//Remove deleted players
			for (let i = draft.length - 1; i >= 0; i--) {
				if (!players.has(draft[i].connectionId)) {
					draft.splice(i, 1);
				}
			}
		});
	}, [players, slideIndex, updatePlayersOrder, isLobby]);

	useEffect(() => {
		const forceUpdate = numVisiblePlayersRef.current !== numVisiblePlayers;
		numVisiblePlayersRef.current = numVisiblePlayers;

		if (forceUpdate) {
			if (containerRef.current) {
				const elements = [...containerRef.current.querySelectorAll(`[data-type="player"]`)];
				let offset = numVisiblePlayers - elements.length;
				if (offset > 0) {
					offset /= 2; // Center
				}
				const elementsLength = elements.length;
				elements.forEach((element, index) => {
					index = elementsLength - 1 - index;
					let x = index + offset;
					let hideOnComplete = false;
					if (elementsLength - index > numVisiblePlayers) {
						hideOnComplete = true;
					}

					gsap.set(element, {
						xPercent: x * 100,
						yPercent: yOffset,
						display: hideOnComplete ? "none" : "block",
					});
				});
			}
		} else {
			const timeline = gsap.timeline();

			if (containerRef.current) {
				const elements = [...containerRef.current.querySelectorAll(`[data-type="player"]`)];
				let offset = numVisiblePlayers - elements.length;
				if (offset > 0) {
					offset /= 2; // Center
				}
				const elementsLength = elements.length;
				elements.forEach((element, index) => {
					index = elementsLength - 1 - index;
					let x = index + offset;
					let hideOnComplete = false;
					if (elementsLength - index > numVisiblePlayers) {
						hideOnComplete = true;
					}

					timeline.to(
						element,
						{
							xPercent: x * 100,
							yPercent: yOffset,
							display: "block",
							onComplete: (element, hideOnComplete) => {
								gsap.set(element, { display: hideOnComplete ? "none" : "block" });
							},
							onCompleteParams: [element, hideOnComplete],
						},
						"<"
					);
				});
			}

			return () => void timeline.kill();
		}
	}, [playersOrder, numVisiblePlayers, yOffset]);

	useEffect(() => {
		if (containerRef.current) {
			const elements = [...containerRef.current.querySelectorAll(`[data-type="player"]`)];
			elements.forEach((element, index) => {
				if (index > numVisiblePlayers) {
					gsap.set(element, {
						xPercent: 2 * -100,
						yPercent: yOffset,
					}); // Start 2 player avatars width to the left
				}
			});
		}
	}, [numVisiblePlayers, yOffset]);

	const playerElements = useMemo(() => {
		const playerElements = [];
		for (let i = playersOrder.length - 1, index = 0; i >= 0; i--, index++) {
			if (index >= numVisiblePlayers * 2 + 1) {
				break;
			}

			const player = playersOrder[i];
			playerElements.push(
				<MemorizedPlayer
					key={player.connectionId}
					player={player}
					showDone={isLobby}
					showSelectedAnswer={showSelectedAnswer}
					showCorrectness={showCorrectness}
					yOffset={yOffset}
					numberOfVisiblePlayers={numVisiblePlayers}
					isLobby={isLobby}
					teamMode={teamMode}
					slideIndex={slideIndex}
				/>
			);
		}
		return playerElements;
	}, [playersOrder, numVisiblePlayers, isLobby, showSelectedAnswer, showCorrectness, yOffset, teamMode, slideIndex]);

	const onClickLocalJoin = useCallback(() => {
		if (onLocalJoin) {
			onLocalJoin();
		}
	}, [onLocalJoin]);

	return (
		<div
			ref={ref}
			className={tailwindCascade(
				"relative flex flex-col justify-center",
				{
					"w-full pb-[25%] sm:pb-[20%] md:pb-[25%] lg:pb-[16.666667%] xl:pb-[14.285714%] 2xl:pb-[12.5%":
						isLobby,
					"w-full h-[248px]": !isLobby,
				},
				className
			)}
		>
			{isLobby && <PlaceHolders visible={playersOrder.length === 0} />}

			<div
				className={tailwindCascade("top-0 left-0 w-full h-full overflow-hidden", {
					absolute: isLobby,
					relative: !isLobby,
				})}
			>
				<div ref={containerRef} className="absolute top-0 left-0 w-full h-full">
					{playerElements}
				</div>
			</div>
		</div>
	);
}

export function LobbyPlayers({ ...props }) {
	return <Players isLobby={true} {...props} />;
}

export function SlidePlayers({ teamMode, players, teams, ...props }) {
	const { getVisibility } = useStatusSequence(props.slideType, { name: props.status, progress: 0 });
	const showSelectedAnswer = getVisibility(PLAY_STATUS_SHOW_AVATAR_COLORS);
	const showCorrectness = getVisibility(PLAY_STATUS_SHOW_AVATAR_CORRECTNESS);
	const showLocalJoin = getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_CORRECT_ANSWER);

	return (
		<Players
			showSelectedAnswer={!teamMode && showSelectedAnswer}
			showCorrectness={!teamMode && showCorrectness}
			showLocalJoin={showLocalJoin}
			yOffset={8}
			players={teamMode ? teams : players}
			teamMode={teamMode}
			{...props}
		/>
	);
}
