import React, { useEffect, memo } from "react";
import unicodeSubstring from "unicode-substring";
import clamp from "lodash/clamp";
import isEqual from "lodash/isEqual";
import { useImmer } from "use-immer";

function TypeWriter({ text, progress }) {
	const [state, updateState] = useImmer({
		text: "",
		textProgress: 0,
		visible: "",
		hidden: "",
	});

	useEffect(
		() =>
			void updateState((draft) => {
				const textProgress = text ? Math.round(clamp(progress, 0, 1) * text.length - 1) : 0;
				draft.text = text;
				draft.textProgress = textProgress;
			}),
		[text, progress, updateState]
	);

	useEffect(
		() =>
			void updateState((draft) => {
				draft.visible = unicodeSubstring(state.text, 0, state.textProgress + 1);
				draft.hidden = unicodeSubstring(state.text, state.textProgress + 1, state.text.length);
			}),
		[state.text, state.textProgress, updateState]
	);

	// prettier-ignore
	return (<>{state.visible}<span aria-hidden="true" className="opacity-0">{state.hidden}</span></>);
}

const MemorizedTypeWriter = memo(TypeWriter, isEqual);
export default MemorizedTypeWriter;
