import React, {
	forwardRef,
	memo,
	use,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";

import isEqual from "lodash/isEqual";

import Image from "@/components/Image";
import GiphyVideo from "@/components/interactives/GiphyVideo";
import YoutubePlayer2 from "@/components/interactives/YoutubePlayer2";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useForwardRef from "@/hooks/useForwardRef";
import useRefMounted from "@/hooks/useRefMounted";

import useSettingsStore from "@/stores/settings";

const SLIDE_IMAGE_DEFAULT_STYLE = {
	width: 0,
	height: 0,
	transform: `translate(-50%,-50%)`,
};

const SlideImage = forwardRef(function SlideImage({ onSizeUpdate, onLoad, src, alt, ...props }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	const updateSize = useCallback(() => {
		if (ref.current && ref.current.complete) {
			const { naturalWidth, naturalHeight } = ref.current;
			onSizeUpdate && onSizeUpdate(naturalWidth || 0, naturalHeight || 0);
		} else {
			onSizeUpdate && onSizeUpdate(0, 0);
		}
	}, [onSizeUpdate]);

	const onLoadImage = useCallback(() => {
		updateSize();
		if (onLoad) {
			onLoad();
		}
	}, [onLoad, updateSize]);

	return <Image ref={ref} src={src} onLoad={onLoadImage} alt={alt} {...props} />;
});

const FunFactMedia = forwardRef(function FunFactMedia(
	{
		paused: isGamePaused,
		onError,
		onLoad,
		onYoutubeEnd,
		debugYoutube = false,
		setIsPlayingWithSound = () => {},
		envelope = 1,
		...props
	},
	forwardedRef
) {
	const [, mountedRef] = useRefMounted();

	const youtubePlayerRef = useRef();
	const imageRef = useRef();
	const giphyVideoRef = useRef();
	const youtubePlayPromiseRef = useRef(null);

	const onYoutubeEndRef = useRef(onYoutubeEnd);
	useEffect(() => void (onYoutubeEndRef.current = onYoutubeEnd), [onYoutubeEnd]);

	const mediaVolume = useSettingsStore((state) => state.mediaVolume);

	const [loaded, setLoaded] = useState(false);

	const onSlideImageSizeUpdate = useCallback((naturalWidth, naturalHeight) => {
		if (imageRef.current) {
			if (naturalWidth && naturalHeight) {
				const ratio = naturalWidth / naturalHeight / (4 / 3);
				imageRef.current.style.width = `${ratio * 100}%`;
				imageRef.current.style.height = `${(1 / ratio) * 100}%`;
			} else {
				imageRef.current.width = imageRef.current.height = 0;
			}
		}
	}, []);

	const onGiphyVideoSizeUpdate = useCallback((width, height) => {
		if (giphyVideoRef.current && giphyVideoRef.current.containerElement) {
			if (width && height) {
				const ratio = width / height / (4 / 3);
				giphyVideoRef.current.containerElement.style.width = `${ratio * 100}%`;
				giphyVideoRef.current.containerElement.style.height = `${(1 / ratio) * 100}%`;
			} else {
				giphyVideoRef.current.containerElement.width = giphyVideoRef.current.containerElement.height = 0;
			}
		}
	}, []);

	const onSlideImageLoaded = useCallback(() => void setLoaded(true), []);
	const onTimelapsePlayerLoaded = useCallback(() => void setLoaded(true), []);
	const onYoutubePlayerLoaded = useCallback(() => void setLoaded(true), []);
	const onYoutubePlayerStart = useCallback(() => {
		if (youtubePlayPromiseRef.current) {
			youtubePlayPromiseRef.current.resolve();
			youtubePlayPromiseRef.current = null;
		}
		const isMuted = props.mute === true || props.volume === 0;
		setIsPlayingWithSound(!isMuted);
	}, [props.mute, props.volume, setIsPlayingWithSound]);
	const onYoutubePlayerEnd = useCallback(() => {
		if (onYoutubeEndRef.current) {
			onYoutubeEndRef.current();
		}
		setIsPlayingWithSound(false);
	}, [setIsPlayingWithSound]);

	useEffect(() => {
		return () => void setIsPlayingWithSound(false);
	}, [setIsPlayingWithSound]);

	useEffect(() => void setLoaded(false), [props.type, props.url]);
	useEffect(() => {
		if (loaded && onLoad) {
			onLoad();
		}
	}, [loaded, onLoad]);

	const playMedia = useCallback(() => {
		if (youtubePlayerRef.current) {
			youtubePlayerRef.current.play();
		}

		if (giphyVideoRef.current) {
			giphyVideoRef.current.play();
		}
	}, []);

	const playMediaRef = useRef(playMedia);
	useEffect(() => void (playMediaRef.current = playMedia), [playMedia]);

	const pauseStateRef = useRef(null);
	useEffect(() => {
		if (youtubePlayerRef.current) {
			if (isGamePaused) {
				pauseStateRef.current = youtubePlayerRef.current.getDesiredState();
				youtubePlayerRef.current.pause();
			} else {
				if (pauseStateRef.current === "playing") {
					youtubePlayerRef.current.play();
					pauseStateRef.current = null;
				}
			}
		}

		if (giphyVideoRef.current) {
			if (isGamePaused) {
				giphyVideoRef.current.pause();
			} else {
				giphyVideoRef.current.play();
			}
		}
	}, [isGamePaused]);

	useEffect(() => {
		return () => {
			if (youtubePlayPromiseRef.current) {
				youtubePlayPromiseRef.current.resolve();
				youtubePlayPromiseRef.current = null;
			}
		};
	}, []);

	useImperativeHandle(
		forwardedRef,
		() => ({
			async play() {
				if (props.type === "youtube") {
					try {
						if (!youtubePlayerRef.current) {
							console.error("No youtube player instance");
							return;
						}
						await youtubePlayerRef.current.untilReady();

						if (mountedRef.current) {
							const youtubePlayPromise = new Promise(
								(resolve, reject) => void (youtubePlayPromiseRef.current = { resolve, reject })
							);

							playMediaRef.current && playMediaRef.current();

							await youtubePlayPromise; // this will be resolved when the video has started
						}
					} catch (error) {
						console.error(error);
					}
				}
			},
			setVolume(value) {
				if (props.type === "youtube") {
					if (youtubePlayerRef.current) {
						youtubePlayerRef.current.setVolume(Math.round(value * 100));
					}
				}
			},
		}),
		[props.type]
	);

	const giphyVideoStyle = useMemo(() => {
		const translate = `translate(${(props.transformX || 0) - 50}%,${(props.transformY || 0) - 50}%)`;
		const scale = `scale(${props.transformZ || 1})`;
		return {
			transform: `${translate} ${scale}`,
		};
	}, [props.transformX, props.transformY, props.transformZ]);

	const [url, isGiphyVideo] = useMemo(() => {
		let url = props.url;

		let isGiphyVideo = false; // TODO: Replace when serverside is done hosting mp4 for Giphy
		if (url && url.indexOf("/giphy/") !== -1) {
			if (url.indexOf("?") !== -1) {
				url = url.split("?")[0]; // Remove query string
			}
			let extension = url.substring(url.length - 4, url.length);
			if (extension === ".gif") {
				url = `${url.substring(0, url.length - 4)}.mp4`;
				extension = ".mp4";
			}
			if (extension === ".mp4") {
				isGiphyVideo = true;
			}
		}

		return [url, isGiphyVideo];
	}, [props.url]);

	if (props.type === "youtube") {
		return (
			<YoutubePlayer2
				ref={youtubePlayerRef}
				id={props.slideId}
				videoId={props.id}
				mute={props.mute || props.volume === 0}
				start={props.start}
				duration={props.duration || 30}
				loop={props.loop}
				onError={onError}
				onLoad={onYoutubePlayerLoaded}
				onEnd={onYoutubePlayerEnd}
				onStart={onYoutubePlayerStart}
				volume={isFinite(props.volume) ? props.volume * envelope : props.volume}
				showVolumeSlider={true}
				volumeMultiplier={mediaVolume}
				isGamePaused={isGamePaused}
			/>
		);
	} else if (props.type === "image" || props.type === "svg") {
		return (
			<div ref={forwardedRef} style={props.style}>
				{isGiphyVideo ? (
					<GiphyVideo
						ref={giphyVideoRef}
						src={url}
						className="max-w-none left-1/2 top-1/2 absolute block min-w-full min-h-full pointer-events-none"
						style={giphyVideoStyle}
						onLoad={onSlideImageLoaded}
						onSizeUpdate={onGiphyVideoSizeUpdate}
					/>
				) : (
					<SlideImage
						ref={imageRef}
						src={url}
						alt=""
						className="max-w-none left-1/2 top-1/2 absolute block min-w-full min-h-full  pointer-events-none"
						style={SLIDE_IMAGE_DEFAULT_STYLE}
						onLoad={onSlideImageLoaded}
						onSizeUpdate={onSlideImageSizeUpdate}
					/>
				)}
			</div>
		);
	}

	return null;
});

export default memo(FunFactMedia, isEqual); // Use deep comparison
