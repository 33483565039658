import React, { memo, useCallback, useEffect, useRef } from "react";

import { isEqual } from "lodash";
import isFinite from "lodash/isFinite";

import Button from "@/components/interactives/Button";

import { sfx } from "@/helpers/audio";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { upperFirst } from "@/helpers/text";

import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-multicolor.svg";

import { BUTTON_MAX_HEIGHT } from "./BaseSlide";

function AnswersFragment({
	slide,
	slideIndex,
	active = true,
	visible = true,
	setAnswerRef = null,
	onAnswer = null,
	paused,
	haveLocalPlayer,
	submittedAnswer,
	showCorrectAnswers,
	noAnimation,
	language,
}) {
	const id = slide?.id || null;
	const answers = slide?.answers || [];
	const disabled = !visible || isFinite(submittedAnswer) || paused || !active;

	const onClick = useCallback(
		(ev) => {
			if (onAnswer && !isFinite(submittedAnswer)) {
				const index = parseInt(ev.currentTarget.getAttribute("data-index"));
				onAnswer(slideIndex, index);
			}
		},
		[onAnswer, slideIndex, submittedAnswer]
	);

	const onMouseEnter = useCallback(() => {
		sfx.play("hoverFeedback", false, 0.75);
	}, []);

	const BUTTON_COLOR_ORDER = ["answer1", "answer2", "answer3", "answer4"];

	return (
		<div
			className={tailwindCascade("flex", "flex-col", "items-center", "w-full", {
				"opacity-0 transition-opacity duration-300": !visible,
				"pointer-events-none": disabled,
			})}
		>
			{answers.map((answer, index, answers) => (
				<div
					ref={(element) =>
						setAnswerRef
							? setAnswerRef(index, [element, index, answers.length, answer.isCorrect], answers.length)
							: () => {}
					}
					key={`${id}-${index}`}
					className={tailwindCascade("flex flex-row justify-center w-full")}
					style={{
						margin: "9px 0",
					}}
				>
					<div className="relative flex w-full">
						<Button
							data-index={index}
							disabled={disabled}
							disabledOpacity={false}
							color={BUTTON_COLOR_ORDER[index % BUTTON_COLOR_ORDER.length]}
							border={true}
							onClick={onClick}
							onMouseEnter={onMouseEnter}
							className={tailwindCascade("w-full", "text-2xl", {
								"animate-media": visible && !noAnimation,
								"opacity-25 transition-opacity duration-300":
									(submittedAnswer !== null && submittedAnswer !== index) ||
									(haveLocalPlayer && submittedAnswer === null && showCorrectAnswers) ||
									(!haveLocalPlayer && showCorrectAnswers && !answer.isCorrect),
								"pointer-events-none": disabled,
							})}
							style={{
								animationDelay: `${((1 - 0.64) / answers.length) * index}s`,
								animationFillMode: "backwards",
							}}
							hoverClassName="group-hover:bg-opacity-40"
							maxHeight={BUTTON_MAX_HEIGHT}
						>
							<div className="py-4">{upperFirst(answer.text, language)}</div>
						</Button>
						{showCorrectAnswers && answer.isCorrect && (
							<div className="top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full">
								<CorrectIcon className="fill-white w-8 h-8 text-black" />
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

const MemorizedAnswersFragment = memo(AnswersFragment, isEqual);
export default MemorizedAnswersFragment;
