import React, { useEffect, useRef, memo } from "react";

import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";

import createCustomOverlay from "@/helpers/createCustomOverlay";

import dartboardSVG from "!!raw-loader!@/images/maps/dartboard.svg";
import { haversineDistance } from "@/helpers/map";

const EARTHS_RADIUS = 6371;

export function getDartboardOpacity(map, radius) {
	const nw = {
		lat: map.getBounds().getNorthEast().lat(),
		lng: map.getBounds().getNorthEast().lng(),
	};
	const se = {
		lat: map.getBounds().getSouthWest().lat(),
		lng: map.getBounds().getSouthWest().lng(),
	};
	const diagonal = haversineDistance(nw, se);
	return Math.min((0.5 * diagonal) / radius, 1);
}

export default memo(function Dartboard({ map, center, radius, opacity = 1, zIndex = 0, minSize = 0 }) {
	const customOverlayRef = useRef(null);

	useEffect(() => {
		const earthsRadius = EARTHS_RADIUS * 1000; /// Radius of the Earth in m
		const rlat = (radius / earthsRadius) * (180 / Math.PI);
		const rlng = rlat / Math.cos(center.lat * (Math.PI / 180));

		const bounds = new window.google.maps.LatLngBounds(
			new window.google.maps.LatLng(center.lat - rlat, center.lng - rlng),
			new window.google.maps.LatLng(center.lat + rlat, center.lng + rlng)
		);

		customOverlayRef.current = createCustomOverlay(
			map,
			bounds,
			center,
			() => {
				const div = document.createElement("div");
				const style = div.style;
				style.borderStyle = "none";
				style.borderWidth = "0px";
				style.position = "absolute";
				style.opacity = opacity * getDartboardOpacity(map, radius);
				style.zIndex = zIndex;
				div.innerHTML = dartboardSVG;

				const svg = div.querySelector("svg");
				if (svg) {
					svg.style.width = svg.style.height = "100%";
				}

				return div;
			},
			minSize
		);
		customOverlayRef.current.setMap(map);

		const boundsChangedListener =
			map &&
			map.addListener("bounds_changed", () => {
				if (customOverlayRef.current && customOverlayRef.current.div) {
					customOverlayRef.current.div.style.opacity = opacity * getDartboardOpacity(map, radius);
				}
			});

		return () => {
			if (boundsChangedListener) {
				boundsChangedListener.remove();
			}
			if (customOverlayRef.current) {
				customOverlayRef.current.setMap(null);
				customOverlayRef.current = null;
			}
		};
	}, [map, center, radius, zIndex, opacity]);

	return null;
}, isEqual);
