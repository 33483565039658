import clamp from "lodash/clamp";

export default function createCustomOverlay(map, bounds, center, onAdd = null, minSize = 0, maxSize = 0) {
	const OverlayView = typeof window !== "undefined" ? window.google.maps.OverlayView : {};

	// https://developers.google.com/maps/documentation/javascript/customoverlays
	class CustomOverlay extends OverlayView {
		constructor(map, bounds, center, onAdd = null, minSize = 0, maxSize = 0) {
			super();

			this.bounds = bounds;
			this._center = center;
			this._onAdd = onAdd;
			this._minSize = minSize;
			this._maxSize = maxSize;
			this.div = null;

			this.setMap(map);
		}

		/**
		 * onAdd is called when the map's panes are ready and the overlay has been
		 * added to the map.
		 */
		onAdd() {
			if (this._onAdd) {
				const div = (this.div = this._onAdd());
				// Add the element to the "overlayLayer" pane.
				const panes = this.getPanes();
				panes.overlayLayer.appendChild(div);
			}
		}

		draw() {
			// We use the south-west and north-east
			// coordinates of the overlay to peg it to the correct position and size.
			// To do this, we need to retrieve the projection from the overlay.
			const overlayProjection = this.getProjection();
			// Retrieve the south-west and north-east coordinates of this overlay
			// in LatLngs and convert them to pixel coordinates.
			// We'll use these coordinates to resize the div.
			const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
			const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

			const pixelCenter = overlayProjection.fromLatLngToDivPixel(this._center);

			// Resize the image's div to fit the indicated dimensions.
			if (this.div) {
				const style = this.div.style;
				let width = ne.x - sw.x;
				let height = sw.y - ne.y;
				if (this._minSize && this._maxSize) {
					width = clamp(width, this._minSize, this._maxSize);
					height = clamp(height, this._minSize, this._maxSize);
				} else if (this._minSize) {
					width = Math.max(width, this._minSize);
					height = Math.max(height, this._minSize);
				} else if (this._maxSize) {
					width = Math.min(width, this._maxSize);
					height = Math.min(height, this._maxSize);
				}
				style.left = `${pixelCenter.x - width / 2}px`;
				style.top = `${pixelCenter.y - height / 2}px`;
				style.width = `${width}px`;
				style.height = `${height}px`;
			}
		}

		/**
		 * The onRemove() method will be called automatically from the API if
		 * we ever set the overlay's map property to 'null'.
		 */
		onRemove() {
			if (this.div) {
				this.div.parentNode.removeChild(this.div);
				delete this.div;
			}
		}
	}

	return new CustomOverlay(map, bounds, center, onAdd, minSize, maxSize);
}
