import React, { useCallback, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { useImmer } from "use-immer";
import useForwardRef from "@/hooks/useForwardRef";
import useRefState from "@/hooks/useRefState";

import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function GiphyVideo(
	{ src, autoPlay = true, onLoad = null, onSizeUpdate = null, interactive = false, className, ...props },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);
	const containerRef = useRef(null);
	const videoRef = useRef(null);
	const autoPlayRef = useRef(autoPlay);
	const playQueuedRef = useRef(autoPlay);
	const [videoSize, updateVideoSize] = useImmer({ width: 0, height: 0 });
	const [loaded, setLoaded, loadedRef] = useRefState(false);

	const playVideo = useCallback(() => {
		if (videoRef.current && loadedRef.current) {
			playQueuedRef.current = false;
			const promise = videoRef.current.play();
			if (promise && promise.catch) {
				promise.catch(() => {
					//
				});
			}
		} else {
			playQueuedRef.current = true;
		}
	}, []);

	const pauseVideo = useCallback(() => {
		if (videoRef.current) {
			const promise = videoRef.current.pause();
			if (promise && promise.catch) {
				promise.catch((error) => {
					console.error(error);
				});
			}
		}
		playQueuedRef.current = false;
	}, []);

	const onClickVideo = useCallback(() => {
		if (interactive && videoRef.current) {
			if (videoRef.current.paused) {
				playVideo();
			} else {
				pauseVideo();
			}
		}
	}, [interactive]);

	useImperativeHandle(
		ref,
		() => ({
			get containerElement() {
				return containerRef.current;
			},
			play() {
				playVideo();
			},
			pause() {
				pauseVideo();
			},
		}),
		[]
	);

	useEffect(() => {
		if (onSizeUpdate) {
			onSizeUpdate(videoSize.width, videoSize.height);
		}
	}, [onSizeUpdate, videoSize.width, videoSize.height]);

	useEffect(() => {
		if (loaded && onLoad) {
			onLoad();
		}
	}, [loaded, onLoad]);

	useEffect(() => {
		setLoaded(false);
		playQueuedRef.current = autoPlayRef.current;
	}, [src]);

	useEffect(() => void (autoPlayRef.current = playQueuedRef.current = autoPlay), [autoPlay]);

	useEffect(() => {
		let mounted = true;
		let onInitialPlay = null;
		let onLoadedMetaData = null;
		if (videoRef.current) {
			const video = videoRef.current;
			if (video.videoWidth && video.videoHeight) {
				updateVideoSize((draft) => {
					draft.width = video.videoWidth;
					draft.height = video.videoHeight;
				});
			} else {
				onLoadedMetaData = () => {
					if (video) {
						video.removeEventListener("loadedmetadata", onLoadedMetaData);
					}
					onLoadedMetaData = null;

					if (mounted) {
						updateVideoSize((draft) => {
							draft.width = video.videoWidth;
							draft.height = video.videoHeight;
						});
					}
				};
				video.addEventListener("loadedmetadata", onLoadedMetaData);
			}
			if (video.readyState >= 4) {
				if (playQueuedRef.current) {
					const promise = video.play();
					if (promise && promise.catch) {
						promise.catch((error) => {
							console.error(error);
						});
					}
				}
				setLoaded(true);
			} else {
				onInitialPlay = () => {
					video.removeEventListener("play", onInitialPlay);
					onInitialPlay = null;

					if (!playQueuedRef.current) {
						const promise = video.pause();
						if (promise && promise.catch) {
							promise.catch((error) => {
								console.error(error);
							});
						}
						video.currentTime = 0;
					}
					playQueuedRef.current = false;

					if (mounted && video.videoWidth && video.videoHeight) {
						updateVideoSize((draft) => {
							draft.width = video.videoWidth;
							draft.height = video.videoHeight;
						});
					}
					if (mounted) {
						setLoaded(true);
					}
				};
				video.addEventListener("play", onInitialPlay);
				const promise = video.play();
				if (promise && promise.catch) {
					promise.catch((error) => {
						console.error(error);
					});
				}
			}

			return () => {
				mounted = false;

				if (onInitialPlay) {
					video.removeEventListener("play", onInitialPlay);
				}
				if (onLoadedMetaData) {
					video.removeEventListener("loadedmetadata", onLoadedMetaData);
				}
			};
		}
	}, [src]);

	return (
		<div
			ref={containerRef}
			className={tailwindCascade("relative", "w-full", "h-full", "overflow-hidden", className)}
			{...props}
		>
			<video
				ref={videoRef}
				className="max-w-none max-h-none absolute block w-full h-full min-w-0 min-h-0"
				src={src}
				muted={true}
				loop={true}
				onClick={onClickVideo}
				playsInline={true}
			/>
		</div>
	);
});
