import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import cloneDeep from "lodash/cloneDeep";

import FunFactMedia from "@/components/pages/play/FunFactMedia";
import { SlidePlayers } from "@/components/pages/play/Players";
import ScaleContainer, { WIDTH as SCALE_WIDTH } from "@/components/pages/play/ScaleContainer";
import SlideHeader from "@/components/pages/play/SlideHeader";
import SlideMedia from "@/components/pages/play/SlideMedia";
import AnswersFragment from "@/components/pages/play/slides/AnswersFragment";

import onWindowResize from "@/helpers/onWindowResize";
import { formatAnswerString } from "@/helpers/stringHelper";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useBrowser from "@/hooks/useBrowser";
import useDynamicRefArray from "@/hooks/useDynamicRefArray";
import useStatusSequence from "@/hooks/useStatusSequence";

import useSettingsStore from "@/stores/settings";

import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
	voiceNameToLanguageCode,
} from "@/app-constants.mjs";
import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE } from "@/colors";
import {
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_QUIZNAME,
	PLAY_STATUS_SHOW_RESULTS,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
} from "@/constants";

import useConfetti from "../../../../hooks/useConfetti";
import ProgressIndicator from "../../../ProgressIndicator";
import TextVoice from "../../TextVoice";
import CheckboxAnswersFragment from "./CheckboxAnswersFragment";
import RangeAnswersFragment from "./RangeAnswersFragment";
import ReorderAnswersFragment from "./ReorderAnswersFragment";
import TypeAnswerFragment from "./TypeAnswerFragment";
import WrongTypeAnswers from "./WrongTypeAnswers";

const WIDTH = 1920;
const HEIGHT = 1080;

export const FUN_FACT_MEDIA_LOAD_TIMEOUT = 1000;
const MEDIA_WIDTH = 1050;

export const BUTTON_WIDTH = 500;
export const MARGIN_WIDTH = 32;
export const MARGIN_HEIGHT = 48;

const QUESTION_MAX_HEIGHT = (3.5 / 3) * 16 * 3 * 2; // 2 lines
const QUESTION_TYPE_ANSWER_MAX_HEIGHT = (3.5 / 3) * 16 * 3 * 4; // 4 lines
export const FUNFACT_MAX_HEIGHT = (3.5 / 3) * 16 * 3 * 2; // 2 lines
export const BUTTON_MAX_HEIGHT = (2 / 1.5) * 16 * 1.5 * 3; // 3 lines

export default function BaseSlide({
	doublePoints,
	haveLocalPlayer,
	hideIncorrectTypeAnswers,
	mute = false,
	onComplete,
	onFunFactEnd,
	onLoad,
	onAnswer,
	onQuestionStart,
	onError,
	onLocalJoin,
	isPaused: paused,
	players,
	setFunFactMediaIsPlayingWithSound,
	setSlideMediaIsPlayingWithSound,
	setSlideTypeAnswer,
	slide: currentSlide,
	slideIndex,
	statusWithProgress,
	submittedAnswer,
	submittedAnswerProgress,
	isHost,
	typeAnswerState,
	voiceOverride,
	reducedMotion,
	teams,
	teamMode,
}) {
	const ref = useRef(null);
	const browser = useBrowser();

	const onLoadRef = useRef(onLoad);
	useEffect(() => void (onLoadRef.current = onLoad), [onLoad]);
	const onCompleteRef = useRef(onComplete);
	useEffect(() => void (onCompleteRef.current = onComplete), [onComplete]);
	const onQuestionStartRef = useRef(onQuestionStart);
	useEffect(() => void (onQuestionStartRef.current = onQuestionStart), [onQuestionStart]);

	const onFunFactEndRef = useRef(onFunFactEnd);
	useEffect(() => void (onFunFactEndRef.current = onFunFactEnd), [onFunFactEnd]);
	const onYoutubeEnd = useCallback(() => {
		if (onFunFactEndRef.current) {
			onFunFactEndRef.current();
		}
	}, []);

	const [pendingAnswer, setPendingAnswer] = useState(null);
	const onAnswerInternal = useCallback(
		(answerSlideIndex, answer) => void setPendingAnswer({ answerSlideIndex, answer }),
		[]
	);
	useEffect(() => {
		if (onAnswer && pendingAnswer && statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && !submittedAnswer) {
			onAnswer(pendingAnswer.answerSlideIndex, pendingAnswer.answer);
			setPendingAnswer(null);
		}
	}, [pendingAnswer, onAnswer, statusWithProgress.name, submittedAnswer]);

	const [slideMedia, setSlideMedia] = useState(null);
	const slideMediaRef = useRef(null);
	useEffect(() => {
		slideMediaRef.current = cloneDeep(currentSlide.media);
		setSlideMedia(cloneDeep(slideMediaRef.current));
	}, [currentSlide]);

	const [funFactMedia, setFunFactMedia] = useState(null);
	const funFactMediaRef = useRef(null);
	useEffect(() => {
		funFactMediaRef.current = cloneDeep(currentSlide.funFactMedia);
		setFunFactMedia(cloneDeep(funFactMediaRef.current));
	}, [currentSlide]);

	const hasQuestion = currentSlide && currentSlide.question;
	const hasVoice =
		currentSlide && currentSlide.id && (currentSlide.questionVoice || voiceOverride) && currentSlide.question;
	const hasAnswerVoice = currentSlide && currentSlide.id && (currentSlide.answerVoice || voiceOverride);
	const hasAnswers = currentSlide && currentSlide.answers;
	const hasSlideMedia = currentSlide && currentSlide.media;
	const hasFunFactText = currentSlide && currentSlide.funFact;
	const hasFunFactMedia = currentSlide && currentSlide.funFactMedia;

	const [isLayoutWithMedia, setIsLayoutWithMedia] = useState(false);
	useEffect(() => {
		if (currentSlide && (currentSlide.media || currentSlide.funFactMedia || currentSlide.funFact)) {
			setIsLayoutWithMedia(true);
		}
	}, [currentSlide]);

	const answerRef = useRef();
	const [answersRef, setAnswerRef] = useDynamicRefArray();
	const slideMediaElementRef = useRef();
	const funFactMediaElementRef = useRef();

	const { getVisibility, getProgress } = useStatusSequence(currentSlide.type, statusWithProgress);

	const showQuizName = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUIZNAME, PLAY_STATUS_SHOW_QUESTION),
		[getVisibility]
	);

	const readQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_SHOW_ANSWERS),
		[getVisibility]
	);

	const noAnimation = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const showQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const questionProgress = useMemo(() => getProgress(PLAY_STATUS_SHOW_QUESTION), [getProgress]);

	const mountMedia = useMemo(
		() => getVisibility(PLAY_STATUS_LOAD_SLIDE, hasFunFactMedia ? PLAY_STATUS_SHOW_FUN_FACT : null),
		[getVisibility, hasFunFactMedia]
	);

	const showMedia = useMemo(
		() =>
			getVisibility(
				PLAY_STATUS_SHOW_MEDIA,
				hasFunFactMedia ? PLAY_STATUS_SHOW_FUN_FACT : PLAY_STATUS_HIDE_FUN_FACT
			),
		[getVisibility, hasFunFactMedia]
	);

	const showFunFactMedia = useMemo(
		() => hasFunFactMedia && getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility, hasFunFactMedia]
	);

	const showFunFact = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);

	const showProgressBar = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);
	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const waitForAnswer = useMemo(
		() => statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && !submittedAnswer,
		[statusWithProgress.name, submittedAnswer]
	);

	const showAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_ANSWERS, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const showCorrectAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_CORRECT_ANSWER, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const showWrongTypeAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const slideMediaVolumeEnvelope =
		1 - getProgress(hasFunFactText && !hasFunFactMedia ? PLAY_STATUS_YOUTUBE_END_2 : PLAY_STATUS_YOUTUBE_END_1);
	const funFactMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_HIDE_FUN_FACT);

	const interactiveStreetView = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	const reducedMotionRef = useRef(reducedMotion);
	useEffect(() => void (reducedMotionRef.current = reducedMotion), [reducedMotion]);

	const showConfetti = useConfetti({
		particleCount: 200,
		angle: 90,
		spread: 360,
		startVelocity: 21,
		decay: 0.8,
		gravity: 0.4,
		drift: 0,
		ticks: 200,
		colors: [ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE],
		shapes: ["square", "square", "square", "circle"],
		scalar: 1.9,
	});
	const showConfettiRef = useRef(showConfetti);
	useEffect(() => void (showConfettiRef.current = showConfetti), [showConfetti]);

	useEffect(() => {
		if (!hasSlideMedia && onLoadRef.current) {
			onLoadRef.current();
		}
	}, [hasSlideMedia]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_SHOW_CORRECT_ANSWER) {
			switch (currentSlide.type) {
				case SLIDE_TYPE_TYPE_ANSWER:
					if (!haveLocalPlayer && answerRef.current) {
						if (!reducedMotionRef.current && showConfettiRef.current) {
							showConfettiRef.current(ref.current, answerRef.current);
						}
					}
					break;
				case SLIDE_TYPE_CLASSIC:
					for (let i = 0; i < answersRef.current.length; i++) {
						const answerRef = answersRef.current[i];
						const [answer, _index, length, isCorrect] = answerRef.current;
						if (answer && isCorrect && (!haveLocalPlayer || (haveLocalPlayer && submittedAnswer === i))) {
							if (!reducedMotionRef.current && showConfettiRef.current) {
								showConfettiRef.current(ref.current, answer);
							}
						}
					}
					break;
			}
		}
	}, [answersRef, currentSlide.type, haveLocalPlayer, statusWithProgress.name, submittedAnswer]);

	useEffect(() => {
		if (funFactMediaElementRef.current) {
			if (statusWithProgress.name === PLAY_STATUS_SHOW_FUN_FACT) {
				if (funFactMediaRef.current && funFactMediaRef.current.type === "youtube") {
					funFactMediaElementRef.current.play().catch((error) => console.error(error));
				}
			}
		}
	}, [statusWithProgress.name, isLayoutWithMedia]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_WAIT_FOR_MEDIA) {
			if (slideMediaElementRef.current) {
				let canceled = false;
				slideMediaElementRef.current
					.play()
					.then(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					})
					.catch(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					});
				return () => void (canceled = true);
			}
		}
	}, [statusWithProgress.name]);

	const [slideMediaContainerStyle, setSlideMediaContainerStyle] = useState(null);
	useEffect(() => {
		const isYoutube = slideMedia && slideMedia.type === "youtube";
		setSlideMediaContainerStyle({
			height: `${MEDIA_WIDTH * (isYoutube ? 9 / 16 : 3 / 4)}px`,
			border: "0.25rem solid #000",
			borderRadius: isYoutube && browser && browser.satisfies({ safari: ">=1" }) ? "0" : "1.5rem",
		});
	}, [slideMedia, browser]);

	const [funFactMediaContainerStyle, setFunFactMediaContainerStyle] = useState(null);
	useEffect(() => {
		const isYoutube = funFactMedia && funFactMedia.type === "youtube";
		setFunFactMediaContainerStyle({
			height: `${MEDIA_WIDTH * (isYoutube ? 9 / 16 : 3 / 4)}px`,
			border: "0.25rem solid #000",
			borderRadius: isYoutube && browser && browser.satisfies({ safari: ">=1" }) ? "0" : "1.5rem",
		});
	}, [funFactMedia, browser]);

	const [typeAnswersMaxHeight, setTypeAnswersMaxHeight] = useState(0);

	const leftContainerRef = useRef(null);
	const topContainerRef = useRef(null);
	const bottomContainerRef = useRef(null);

	useEffect(() => {
		const resize = () => {
			if (leftContainerRef.current) {
				let typeAnswersMaxHeight = leftContainerRef.current.offsetHeight;
				if (topContainerRef.current) {
					typeAnswersMaxHeight -= topContainerRef.current.offsetHeight;
				}
				if (bottomContainerRef.current) {
					typeAnswersMaxHeight -= bottomContainerRef.current.offsetHeight;
				}
				setTypeAnswersMaxHeight(typeAnswersMaxHeight - 16 - 160); // padding - "mb-40"
			}
		};
		resize(); // Initial resize

		if (topContainerRef.current) {
			return onWindowResize(resize);
		}
	}, []);

	const [funFactMediaLoaded, setFunFactMediaLoaded] = useState(false);
	useEffect(() => {
		if (!showFunFactMedia) {
			setFunFactMediaLoaded(false);
		} else {
			const timeout = setTimeout(() => setFunFactMediaLoaded(true), FUN_FACT_MEDIA_LOAD_TIMEOUT);
			return () => void clearTimeout(timeout);
		}
	}, [showFunFactMedia]);
	const onLoadFunFactMedia = useCallback(() => void setFunFactMediaLoaded(true), []);

	return (
		<>
			{hasVoice && (
				<TextVoice
					isHost={isHost}
					slideId={currentSlide.id}
					play={readQuestion}
					savedVoice={currentSlide.questionVoice}
					voiceOverride={voiceOverride}
					text={currentSlide.question}
				/>
			)}
			{hasAnswerVoice &&
				![SLIDE_TYPE_LOCATION, SLIDE_TYPE_RANGE, SLIDE_TYPE_REORDER].includes(currentSlide.type) && (
					<TextVoice
						isHost={isHost}
						slideId={currentSlide.id}
						play={showCorrectAnswers}
						savedVoice={currentSlide.answerVoice}
						voiceOverride={voiceOverride}
						text={formatAnswerString(currentSlide.type, currentSlide.answers)}
					/>
				)}
			{currentSlide?.quiz && (
				<TextVoice
					isHost={isHost}
					slideId={currentSlide.id}
					play={showQuizName}
					savedVoice={"Charles"}
					voiceOverride={voiceOverride}
					text={currentSlide.quiz.name}
				/>
			)}
			<ScaleContainer ref={ref} marginBottom={true}>
				{currentSlide?.quiz && (
					<div
						className={tailwindCascade("flex flex-col justify-center w-full h-full", {
							hidden: !showQuizName || showQuestion,
						})}
					>
						<div className="w-full mb-[32px]">
							<SlideHeader className="w-full h-full" maxHeight={QUESTION_MAX_HEIGHT}>
								{currentSlide.quiz.name}
							</SlideHeader>
						</div>
					</div>
				)}
				<div className="flex flex-col w-full">
					<div
						className={tailwindCascade("relative", "flex", "flex-row", "w-full", "h-[852px]", {
							"justify-start": isLayoutWithMedia,
							"justify-center": !isLayoutWithMedia,
							"ml-[74px]": isLayoutWithMedia,
						})}
					>
						<div
							ref={leftContainerRef}
							className={tailwindCascade("flex", "flex-col", {
								"justify-start": currentSlide.type === SLIDE_TYPE_TYPE_ANSWER || !isLayoutWithMedia,
								"justify-center": !(currentSlide.type === SLIDE_TYPE_TYPE_ANSWER || !isLayoutWithMedia),
								"w-[700px]": isLayoutWithMedia,
								"w-[1050px]": !isLayoutWithMedia,
								"mr-32px": isLayoutWithMedia,
							})}
						>
							<div
								className={tailwindCascade("flex flex-col items-center w-full h-full", {
									"mt-36": isLayoutWithMedia && currentSlide.type !== SLIDE_TYPE_RANGE,
									"mt-28": !isLayoutWithMedia && currentSlide.type !== SLIDE_TYPE_RANGE,
									"mb-40": currentSlide.type !== SLIDE_TYPE_RANGE,
									"justify-around": currentSlide.type === SLIDE_TYPE_RANGE,
								})}
							>
								{hasQuestion && (
									<div ref={topContainerRef} className="w-full">
										<div className="w-full mb-[32px]">
											<SlideHeader
												className={tailwindCascade("w-full", "h-full", {
													"opacity-0 transition-opacity duration-300": !showQuestion,
													"pointer-events-none": !showQuestion,
												})}
												maxHeight={
													currentSlide.type === SLIDE_TYPE_TYPE_ANSWER
														? isLayoutWithMedia
															? QUESTION_TYPE_ANSWER_MAX_HEIGHT
															: null
														: QUESTION_MAX_HEIGHT
												}
												progress={questionProgress}
											>
												{currentSlide.question}
											</SlideHeader>
										</div>
									</div>
								)}
								<div
									ref={bottomContainerRef}
									className={tailwindCascade("flex flex-col items-center w-full", {
										"h-full grow justify-around": currentSlide.type === SLIDE_TYPE_RANGE,
									})}
								>
									{hasAnswers && (
										<div
											className={tailwindCascade("w-[500px]", {
												"w-full h-full": currentSlide.type === SLIDE_TYPE_RANGE,
											})}
										>
											<div
												className={tailwindCascade("flex items-stretch justify-center w-full", {
													"h-full": currentSlide.type === SLIDE_TYPE_RANGE,
												})}
											>
												{currentSlide.type === SLIDE_TYPE_TYPE_ANSWER && (
													<TypeAnswerFragment
														answerRef={answerRef}
														answers={currentSlide.answers}
														disabled={
															!haveLocalPlayer ||
															typeAnswerState.isCorrect ||
															!showAnswers ||
															paused
														}
														haveLocalPlayer={haveLocalPlayer}
														isClose={typeAnswerState.isClose}
														isCorrect={typeAnswerState.isCorrect}
														onAnswer={onAnswerInternal}
														setValue={setSlideTypeAnswer}
														showCorrectAnswers={showCorrectAnswers}
														slideIndex={slideIndex}
														value={typeAnswerState.value}
														visible={showAnswers}
													/>
												)}
												{currentSlide.type === SLIDE_TYPE_CLASSIC && (
													<AnswersFragment
														onAnswer={onAnswerInternal}
														haveLocalPlayer={haveLocalPlayer}
														paused={paused}
														setAnswerRef={setAnswerRef}
														showCorrectAnswers={showCorrectAnswers}
														slide={currentSlide}
														slideIndex={slideIndex}
														submittedAnswer={submittedAnswer}
														visible={showAnswers}
														active={showAnswers}
														noAnimation={noAnimation}
														language={voiceNameToLanguageCode(currentSlide.answerVoice)}
													/>
												)}
												{currentSlide.type === SLIDE_TYPE_CHECK_BOXES && (
													<CheckboxAnswersFragment
														haveLocalPlayer={haveLocalPlayer}
														onAnswer={onAnswerInternal}
														paused={paused}
														setAnswerRef={setAnswerRef}
														showCorrectAnswers={showCorrectAnswers}
														slide={currentSlide}
														slideIndex={slideIndex}
														visible={showAnswers}
														submittedAnswer={submittedAnswer}
														statusWithProgress={statusWithProgress}
														noAnimation={noAnimation}
													/>
												)}
												{currentSlide.type === SLIDE_TYPE_RANGE && (
													<RangeAnswersFragment
														doublePoints={doublePoints}
														onAnswer={onAnswerInternal}
														haveLocalPlayer={haveLocalPlayer}
														paused={paused}
														setAnswerRef={setAnswerRef}
														showCorrectAnswers={showCorrectAnswers}
														slide={currentSlide}
														slideIndex={slideIndex}
														submittedAnswer={submittedAnswer}
														visible={showAnswers}
														active={showAnswers}
														showProgressBar={showProgressBar}
														waitForAnswerProgress={waitForAnswerProgress}
														submittedAnswerProgress={submittedAnswerProgress}
														hasAnswerVoice={hasAnswerVoice}
														isHost={isHost}
														voiceOverride={voiceOverride}
														statusWithProgress={statusWithProgress}
														players={players}
														reducedMotion={reducedMotion}
														teamMode={teamMode}
														teams={teams}
													/>
												)}
												{currentSlide.type === SLIDE_TYPE_REORDER && (
													<ReorderAnswersFragment
														active={showAnswers}
														haveLocalPlayer={haveLocalPlayer}
														language={voiceNameToLanguageCode(currentSlide.answerVoice)}
														isHost={isHost}
														onAnswer={onAnswerInternal}
														paused={paused}
														setAnswerRef={setAnswerRef}
														showCorrectAnswers={showCorrectAnswers}
														slide={currentSlide}
														slideIndex={slideIndex}
														statusWithProgress={statusWithProgress}
														submittedAnswer={submittedAnswer}
														visible={showAnswers}
														voiceOverride={voiceOverride}
														noAnimation={noAnimation}
													/>
												)}
											</div>
										</div>
									)}
									{currentSlide.type !== SLIDE_TYPE_RANGE && (
										<div className="w-[500px]">
											<div
												className={tailwindCascade("h-8", {
													"mt-[48px]": hasAnswers,
												})}
											>
												<ProgressIndicator
													className="w-full h-8"
													progress={waitForAnswerProgress}
													submittedAnswerProgress={submittedAnswerProgress}
													visible={showProgressBar}
													paused={paused}
													doublePoints={doublePoints}
												/>
											</div>
										</div>
									)}
								</div>

								{currentSlide.type === SLIDE_TYPE_TYPE_ANSWER && (
									<WrongTypeAnswers
										className="flex-grow w-full"
										isHost={isHost}
										haveLocalPlayer={haveLocalPlayer}
										maxHeight={typeAnswersMaxHeight}
										visible={showWrongTypeAnswers}
										wrongAnswers={typeAnswerState?.wrongAnswers}
										hideIncorrectTypeAnswers={hideIncorrectTypeAnswers}
									/>
								)}
							</div>
						</div>
						{isLayoutWithMedia && (
							<div className="relative flex flex-col items-center justify-center w-[1050px] ml-[32px]">
								{hasSlideMedia && (mountMedia || showMedia) ? (
									<div
										className={tailwindCascade("absolute w-full", {
											"opacity-0 transition-opacity duration-300": !showMedia,
										})}
									>
										<div className="mt-36 flex flex-col items-center w-full mb-40">
											<div
												className={tailwindCascade(
													"relative",
													"w-full",
													"bg-black",
													"overflow-hidden",
													"z-1",
													{
														"animate-media": showMedia && !noAnimation,
														"pointer-events-none": !showMedia,
													}
												)}
												style={slideMediaContainerStyle}
											>
												<SlideMedia
													ref={slideMediaElementRef}
													paused={!showMedia || paused}
													onLoad={onLoad}
													onError={onError}
													{...slideMedia}
													progress={waitForAnswerProgress}
													mute={mute}
													setIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
													envelope={slideMediaVolumeEnvelope}
													visible={showMedia}
													interactiveStreetView={interactiveStreetView}
												/>

												{hasFunFactText && (
													<div
														className={tailwindCascade(
															"absolute",
															"inset-0",
															"z-20",
															"flex",
															"flex-col",
															"justify-center",
															"w-full",
															"h-full",
															"p-8",
															"text-center",
															"bg-black",
															"bg-opacity-50",
															"pointer-events-none",
															{
																"opacity-0 transition-opacity duration-300":
																	!showFunFact,
															}
														)}
													>
														<SlideHeader
															className="flex flex-col justify-center"
															maxHeight={FUNFACT_MAX_HEIGHT}
															maxFontSize={2.25}
														>
															{currentSlide.funFact}
														</SlideHeader>
													</div>
												)}
											</div>
										</div>
									</div>
								) : (
									(mountMedia || showMedia) &&
									!showFunFact &&
									!(hasSlideMedia && !hasFunFactMedia && hasFunFactText) && (
										<div className="mt-36 w-full pb-4/3 mb-40 border-[0.25rem] border-black border-solid rounded-[1.5rem] bg-black bg-opacity-50" />
									)
								)}
								{showFunFact && !(hasSlideMedia && !hasFunFactMedia && hasFunFactText) && (
									<div
										className={tailwindCascade("absolute", "w-full", {
											"sr-only": !showFunFact,
										})}
									>
										<div className="mt-36 flex flex-col items-center w-full mb-40">
											<div
												className={tailwindCascade(
													"relative",
													"w-full",
													"overflow-hidden",
													"z-1",
													{
														"bg-black": hasFunFactMedia,
														"bg-transparent": !hasFunFactMedia,
													},
													{
														"opacity-0 pointer-events-none transition-opacity duration-300":
															!(showFunFact && (funFactMediaLoaded || !hasFunFactMedia)),
														"animate-media":
															showFunFact && (funFactMediaLoaded || !hasFunFactMedia),
													}
												)}
												style={funFactMediaContainerStyle}
											>
												{hasFunFactMedia && (
													<FunFactMedia
														ref={funFactMediaElementRef}
														paused={!showFunFact || paused}
														onLoad={onLoadFunFactMedia}
														onError={onLoadFunFactMedia}
														onYoutubeEnd={onYoutubeEnd}
														{...funFactMedia}
														setIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
														envelope={funFactMediaVolumeEnvelope}
													/>
												)}

												{hasFunFactText && (
													<div
														className={tailwindCascade(
															"absolute",
															"inset-0",
															"z-20",
															"flex",
															"flex-col",
															"justify-center",
															"w-full",
															"h-full",
															"p-8",
															"text-center",
															"bg-black",
															"bg-opacity-50",
															"pointer-events-none",
															{ "opacity-0": !showFunFact }
														)}
													>
														<SlideHeader
															className="flex flex-col justify-center"
															maxHeight={FUNFACT_MAX_HEIGHT}
															maxFontSize={2.25}
														>
															{currentSlide.funFact}
														</SlideHeader>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>

					<div
						className={tailwindCascade("absolute w-full h-[248px] bottom-0", {
							"opacity-0 transition-opacity duration-300":
								!showQuestion && !showMedia && !showProgressBar,
						})}
					>
						<SlidePlayers
							status={statusWithProgress.name}
							slideType={currentSlide.type}
							slideIndex={slideIndex}
							players={players}
							allowLocalJoin={isHost}
							haveLocalPlayer={haveLocalPlayer}
							onLocalJoin={onLocalJoin}
							teams={teams}
							teamMode={teamMode}
						/>
					</div>
				</div>
			</ScaleContainer>
		</>
	);
}
