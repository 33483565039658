const PRECISION = 2;

export function softenPath(d) {
	const h = 0.0; // closer to 1 for more sharpness
	const g = (1 - h) / 2;

	const e = d.slice();

	for (let i = 1; i < d.length - 1; i++) {
		// TODO: include more points for softer result

		e[i].x = d[i - 1].x * g + d[i].x * h + d[i + 1].x * g;
		e[i].y = d[i - 1].y * g + d[i].y * h + d[i + 1].y * g;
	}

	return e;
}

function round(x) {
	return Math.round(x * (PRECISION * 10)) / (PRECISION * 10);
}
export function quantizePath(d) {
	return d.map(({ x, y }) => ({ x: round(x), y: round(y) }));
}
