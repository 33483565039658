import { useRef, useCallback, createRef } from "react";

export default function useDynamicRefArray() {
	const ref = useRef([]);

	const setRef = useCallback((index, value, length = null) => {
		if (length === null) {
			length = Math.max(index + 1, ref.current.length);
		}

		while (length > ref.current.length) {
			ref.current.push(createRef());
		}
		ref.current.length = length;

		if (index < ref.current.length) {
			ref.current[index].current = value;
		}
	}, []);

	return [ref, setRef];
}
