import React, { useEffect, useMemo } from "react";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import { sfx } from "@/helpers/audio";

import Header from "@/components/Header";
import TextVoice from "@/components/pages/TextVoice";
import TypeWriter from "@/components/pages/play/TypeWriter";

export default function MobileQuestion({
	className,
	isHost,
	progress,
	question,
	questionVoice,
	slideId,
	visible,
	voiceOverride,
	readQuestion,
}) {
	const active = useMemo(() => {
		return progress > 0 && progress < 1;
	}, [progress]);

	useEffect(() => {
		if (active) {
			const sound = sfx.play(["typewriter1", "typewriter2"], true);
			return () => {
				void sfx.stop(sound);
			};
		}
	}, [active]);

	if (question) {
		return (
			<>
				<TextVoice
					isHost={isHost}
					slideId={slideId}
					play={readQuestion}
					savedVoice={questionVoice}
					text={question}
					voiceOverride={voiceOverride}
				/>
				<div
					className={tailwindCascade(
						"flex flex-row justify-center",
						"w-full",
						"origin-center",
						{ "transition-opacity opacity-0": !visible },
						className
					)}
				>
					<Header className="md:text-2xl md:leading-normal text-xl leading-none text-center">
						<TypeWriter text={question} progress={progress} />
					</Header>
				</div>
			</>
		);
	}

	return <></>;
}
