import * as React from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Wrapper } from "@googlemaps/react-wrapper";
import useAuthStore from "@/stores/auth";

import Marker from "@/components/interactives/maps/Marker";
import Map from "@/components/interactives/maps/Map";
import Slider from "@/components/interactives/Slider";
import Dartboard from "@/components/interactives/maps/Dartboard";
import Input from "@/components/interactives/Input";
import SlideEditorHeader from "@/components/pages/edit/SlideEditorHeader";

import TargetIcon from "@/images/icons/icon-target.svg";
import CorrectIcon from "@/images/icons/check-bold.svg";

import trans from "@/helpers/trans";
import { RADIUS_MAX, RADIUS_MIN, deserialize, serialize } from "@/helpers/map";
import { haversineDistance } from "@/helpers/map";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { getGeoFeature, getGeoFeatures, getGeoStateMask } from "@/helpers/geo";

import useRefMounted from "@/hooks/useRefMounted";
import useGeoFeature from "@/hooks/useGeoFeature";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";
import isString from "lodash/isString";
import clamp from "lodash/clamp";

import geoDefaultViews from "@/geo/geoDefaultViews.json";

import { BLACK, WHITE, GREEN_DARK, GREEN_LIGHT } from "@/colors";

import {
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_API_VERSION,
	GOOGLE_MAPS_LIBRARIES,
	GOOGLE_MAPS_MAP_IDS,
	MAP_TYPE_HYBRID,
	MAP_TYPE_REGULAR,
	MAP_TYPE_SATELLITE,
	PLACE_TYPE_COUNTRY,
	PLACE_TYPE_PINPOINT,
	PLACE_TYPE_STATE,
} from "@/constants";
import { useListener } from "@/hooks/useListener";
import { PLACE_CODE_SEPARATOR } from "@/app-constants.mjs";
import RequiredFieldWarning from "@/components/pages/edit/RequiredFieldWarning";

// The map doesn't start at 90 degrees, but rather the degree that makes the map square. This is can be simply calaculated as: atan(sinh(PI)) * 180 / PI = 85.05112878....
const MAP_RESTRICTION = {
	latLngBounds: {
		north: (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
		south: -((Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI),
		east: 180,
		west: -180,
	},
};

export default function LocationSlideEditor({
	options,
	setCompletionSlide,
	setOptions,
	setPlaceType: setPlaceTypeOuter,
	slide,
	slideHistory,
	aiComplete,
	aiService,
	newlyCreated,
	...props
}) {
	const [, mountedRef] = useRefMounted();

	const adminFeaturesEnabled = useAuthStore((state) => state.adminFeaturesEnabled);

	const [t1, t2, t3] = useMemo(() => options.map((opt) => opt.text), [options]);
	// t1...5 strings (not options) are used as dependencies because strings are compared by value
	const { target, view } = useMemo(
		() =>
			deserialize(
				[t1, t2, t3].map((text) => ({ text })),
				false
			),
		[t1, t2, t3]
	);

	const setOptionsRef = useRef(setOptions);
	useEffect(() => void (setOptionsRef.current = setOptions), [setOptions]);

	const targetRef = useRef(target);
	useEffect(() => void (targetRef.current = cloneDeep(target)), [target]);

	const viewRef = useRef(view);
	useEffect(() => void (viewRef.current = cloneDeep(view)), [view]);

	const streetViewLatLngStr = useMemo(() => {
		if (slide?.media?.source?.startsWith("street/")) {
			const [, lat, lng] = slide.media.source.split("/").map(parseFloat);
			return [lat, lng].join("/");
		} else {
			return null;
		}
	}, [slide?.media?.source]);

	const [map, setMap] = useState(null);
	const [tilesLoaded, setTilesLoaded] = useState(false);

	const [newView, setNewView] = useState(null);
	useEffect(() => {
		if (map && newView) {
			const { fitBounds, zoom, center, panTo, panToBounds } = newView;

			if (fitBounds) {
				try {
					map.fitBounds(fitBounds);
				} catch (error) {
					console.error(error);
				}
			}

			if (panTo) {
				try {
					map.panTo(panTo);
				} catch (error) {
					console.error(error);
				}
			}

			if (isFinite(zoom)) {
				try {
					map.setZoom(zoom);
				} catch (error) {
					console.error(error);
				}
			}

			if (center) {
				try {
					map.setCenter(center);
				} catch (error) {
					console.error(error);
				}
			}

			if (panToBounds) {
				try {
					map.panToBounds(panToBounds, 40);
				} catch (error) {
					console.error(error);
				}
			}

			setNewView(null);
		}
	}, [newView, map]);

	useEffect(() => {
		if (view?.bounds) {
			setNewView({ panToBounds: view?.bounds });
		}
	}, [view?.bounds]);

	useEffect(() => void getGeoFeatures(), []);

	const primarySelectedFeature = useGeoFeature({ placeCode: target?.placeCode });
	const secondarySelectedFeature = useGeoFeature({
		placeCode:
			target?.placeCode?.includes(PLACE_CODE_SEPARATOR) && target?.placeCode?.split(PLACE_CODE_SEPARATOR)[0],
	});

	const [selectedPlaceType, setSelectedPlaceType] = useState(PLACE_TYPE_COUNTRY);
	const placeType = useMemo(
		() => (target ? (isString(target.placeType) ? target.placeType : PLACE_TYPE_PINPOINT) : selectedPlaceType),
		[selectedPlaceType, target]
	);
	useEffect(() => void setPlaceTypeOuter(placeType), [placeType, setPlaceTypeOuter]);

	const [geoStateMask, setGeoStateMask] = useState(null);

	useEffect(() => {
		let mounted = true;
		getGeoStateMask()
			.then((geoStateMask) => {
				if (mounted) {
					setGeoStateMask(geoStateMask);
				}
			})
			.catch(() => {
				if (mounted) {
					setGeoStateMask(null);
				}
			});
		return () => void (mounted = false);
	}, []);

	const [firstIdle, setFirstIdle] = useState(true);
	const onIdle = useCallback(
		(map) => {
			const zoom = map.getZoom();
			const center = map.getCenter().toJSON();

			if (firstIdle) {
				setFirstIdle(false);
			} else {
				const newView = { ...viewRef.current, zoom, center };
				if (!isEqual(viewRef.current, newView)) {
					setOptions(serialize({ target: targetRef.current, view: newView }));
				}
			}
		},
		[firstIdle, setOptions]
	);

	const [renderingType, setRenderingType] = useState("UNINITIALIZED");

	useEffect(() => {
		if (
			map &&
			(!targetRef.current || targetRef.current?.placeType === PLACE_TYPE_PINPOINT) &&
			renderingType !== "UNINITIALIZED" &&
			streetViewLatLngStr
		) {
			const [lat, lng] = streetViewLatLngStr.split("/").map(parseFloat);

			if (!targetRef.current || lat !== targetRef.current.lat || lng !== targetRef.current.lng) {
				const radius = targetRef.current?.radius || 1e3;

				setOptions(serialize({ target: { lat, lng, radius }, view: viewRef.current }));

				if (!targetRef.current) {
					setNewView({ zoom: 14, center: { lat, lng } });
				} else {
					setNewView({ panToBounds: { south: lat, north: lat, west: lng, east: lng } });
				}
			}
		}
	}, [map, renderingType, setOptions, streetViewLatLngStr]);

	const mediaIsStreetView = streetViewLatLngStr !== null;
	useEffect(() => {
		if (map && mediaIsStreetView && placeType === PLACE_TYPE_PINPOINT) {
			var svLayer = new window.google.maps.StreetViewCoverageLayer();
			svLayer.setMap(map);

			return () => void svLayer.setMap(null);
		}
	}, [map, mediaIsStreetView, placeType]);

	const searchBoxRef = useRef(null);
	useEffect(() => {
		if (searchBoxRef.current) {
			searchBoxRef.current.value = "";
			searchBoxRef.current.blur();
		}
	}, [slide?.id]);

	const getCountryView = useCallback((placeCode) => {
		// Return center/zoom for country if placeCode is a state
		const countryCode = placeCode?.includes(PLACE_CODE_SEPARATOR) && placeCode?.split(PLACE_CODE_SEPARATOR)[0];

		if (countryCode in geoDefaultViews) {
			const { lat, lng, zoom } = geoDefaultViews[countryCode];
			return { center: { lat, lng }, zoom };
		} else {
			return undefined;
		}

		// 	return {
		// 		AU: { center: { lat: -27.182538, lng: 134.139222 }, zoom: 3 },
		// 		CA: { center: { lat: 59.426048, lng: -100.4782 }, zoom: 3 },
		// 		CN: { center: { lat: 37.098875, lng: 102.186119 }, zoom: 3 },
		// 		BR: { center: { lat: -15.455029, lng: -55.926 }, zoom: 3 },
		// 		GB: { center: { lat: 54.600562, lng: -3.168405 }, zoom: 5 },
		// 		ID: { center: { lat: -6.270649, lng: 120.567959 }, zoom: 3 },
		// 		IN: { center: { lat: 22.045972, lng: 79.433573 }, zoom: 4 },
		// 		RU: { center: { lat: 66.940217, lng: 102.193163 }, zoom: 2 },
		// 		US: { center: { lat: 39.259662, lng: -96.891345 }, zoom: 3 },
		// 		ZA: { center: { lat: -28.740611, lng: 25.100347 }, zoom: 5 },
		// 	}[countryCode];
	}, []);

	useEffect(() => {
		if (map && searchBoxRef.current) {
			const searchBox = new window.google.maps.places.SearchBox(searchBoxRef.current);
			//developers.google.com/maps/documentation/javascript/place-autocomplete#places_searchbox

			// Bias the SearchBox results towards current map's viewport.
			map.addListener("bounds_changed", () => void searchBox.setBounds(map.getBounds()));

			// Listen for the event fired when the user selects a prediction and retrieve
			// more details for that place.
			searchBox.addListener("places_changed", () => {
				const places = searchBox.getPlaces();

				if (places.length == 0) {
					return;
				}

				const place = places[0];
				const { lat, lng } = place?.geometry?.location?.toJSON() || {};

				let placeType = place?.types?.find((t) => [PLACE_TYPE_STATE, PLACE_TYPE_COUNTRY].includes(t));

				getGeoFeature({ lat, lng, placeType, broadSearch: true })
					.then((feature) => {
						const placeCode = feature?.properties.id;
						if (placeCode) {
							setOptions(
								serialize({
									target: {
										placeCode,
										placeType,
									},
									view: viewRef.current,
								})
							);

							// Adjust view to surrounding country (for states) or selected country
							setNewView(getCountryView(placeCode) || { fitBounds: place.geometry.viewport.toJSON() });
						} else {
							const diagonal = haversineDistance(
								place.geometry.viewport.getNorthEast().toJSON(),
								place.geometry.viewport.getSouthWest().toJSON()
							);

							const radius = clamp(diagonal * 2, RADIUS_MIN, RADIUS_MAX);
							setOptions(serialize({ target: { lat, lng, radius }, view: viewRef.current }));

							// Adjust view to viewport of selected place
							setNewView({ fitBounds: place.geometry.viewport.toJSON() });
						}
					})
					.catch((err) => void console.log(err));
			});
		}
	}, [getCountryView, map, setOptions]);

	const [flashPhase, setFlashPhase] = useState(1);

	const [hoverLatLng, setHoverLatLng] = useState(null);

	const primaryHoverFeature = useGeoFeature({
		lat: hoverLatLng?.lat,
		lng: hoverLatLng?.lng,
		placeType: [PLACE_TYPE_STATE, PLACE_TYPE_COUNTRY].includes(placeType) && placeType,
	});

	const secondaryHoverFeature = useGeoFeature({
		placeCode:
			primaryHoverFeature?.properties.id.includes(PLACE_CODE_SEPARATOR) &&
			primaryHoverFeature?.properties.id.split(PLACE_CODE_SEPARATOR)[0],
	});

	const setTargetPos = useCallback(
		async ({ lat, lng }) => {
			if (map) {
				const feature = await getGeoFeature({
					lat,
					lng,
					placeType: [PLACE_TYPE_STATE, PLACE_TYPE_COUNTRY].includes(placeType) && placeType,
				});
				if (feature) {
					// Click inside valid country/state
					const placeCode = feature.properties.id;

					setOptions(
						serialize({
							target: {
								placeCode,
							},
							view: viewRef.current,
						})
					);

					const countryView = getCountryView(placeCode) || {};
					const [west, south, east, north] = feature.bbox;

					// Adjust view to surrounding country (for states) and pan so selected state/country is visible
					setNewView({ ...countryView, panToBounds: { west, south, east, north } });
				} else if ([PLACE_TYPE_STATE, PLACE_TYPE_COUNTRY].includes(placeType)) {
					// Click outside valid country/state
					if (targetRef.current) {
						setOptions(serialize({ target: null, view: { ...viewRef.current } }));
					} else {
						let timestamp0 = null;
						const flashMode = (timestamp) => {
							if (mountedRef.current) {
								if (timestamp0 === null) {
									timestamp0 = timestamp;
								}
								const ms = timestamp - timestamp0;
								const phase = Math.min(ms / 300, 1);
								setFlashPhase(phase);

								if (ms < 300) {
									requestAnimationFrame(flashMode);
								}
							}
						};
						requestAnimationFrame(flashMode);
					}
				} else {
					// Pin-point click
					const diagonal = haversineDistance(
						map.getBounds().getNorthEast().toJSON(),
						map.getBounds().getSouthWest().toJSON()
					);

					const radius = targetRef.current?.radius || diagonal * 0.05;

					if (streetViewLatLngStr) {
						const panoRadius = 0.3 * diagonal;
						const service = new window.google.maps.StreetViewService();
						service
							.getPanorama({ location: { lat, lng }, radius: panoRadius, preference: "nearest" })
							.then((response) => {
								const { lat, lng } = response.data.location.latLng.toJSON();
								setOptions(serialize({ target: { lat, lng, radius }, view: viewRef.current }));
							})
							.catch(() => {
								// There is no streetview available for the given position
							});
					} else {
						setOptions(serialize({ target: { lat, lng, radius }, view: viewRef.current }));
					}
				}
			}
		},
		[getCountryView, map, mountedRef, placeType, setOptions, streetViewLatLngStr]
	);

	const switchPlaceType = useCallback(
		(to) => {
			setSelectedPlaceType(to);

			getGeoFeature({
				placeType: to,
				lat: target?.lat,
				lng: target?.lng,
			})
				.then((feature) => {
					if (target?.placeCode && placeType === PLACE_TYPE_STATE && to === PLACE_TYPE_COUNTRY) {
						setOptions(
							serialize({
								target: { placeCode: target?.placeCode?.split(PLACE_CODE_SEPARATOR)[0], placeType: to },
								view: viewRef.current,
							})
						);
					} else if (feature) {
						setOptions(
							serialize({
								target: { placeCode: feature.properties.id, placeType: to },
								view: viewRef.current,
							})
						);
					} else if (to === PLACE_TYPE_PINPOINT && streetViewLatLngStr) {
						// If we switch to pinpoint when there is streetview media, we should get that location
						const [lat, lng] = streetViewLatLngStr.split("/").map(parseFloat);

						if (!targetRef.current || lat !== targetRef.current.lat || lng !== targetRef.current.lng) {
							const radius = targetRef.current?.radius || 1e3;
							setOptions(serialize({ target: { lat, lng, radius }, view: viewRef.current }));

							if (!map.getBounds().contains({ lat, lng })) {
								setNewView({ zoom: 14, center: { lat, lng } });
							}
						} else {
							setOptions(serialize({ target: null, view: viewRef.current }));
						}
					} else {
						setOptions(serialize({ target: null, view: viewRef.current }));
					}
				})
				.catch(() => {});
		},

		[map, placeType, setOptions, streetViewLatLngStr, target?.lat, target?.lng, target?.placeCode]
	);

	const onMapClick = useCallback((ev) => void setTargetPos(ev.latLng.toJSON()), [setTargetPos]);
	const onMapMove = useCallback((ev) => void setHoverLatLng(ev.latLng.toJSON()), []);
	const onMapOut = useCallback((ev) => void setHoverLatLng(null), []);

	const slideHistoryUndoEnabled = useMemo(() => {
		if (
			!slideHistory ||
			!slideHistory.current ||
			!slideHistory.previous ||
			slideHistory.current.answers.length === 0 ||
			slideHistory.previous.answers.length === 0
		) {
			return false;
		}

		const currentAnswers = slideHistory.current.answers;
		const previousAnswers = slideHistory.previous.answers;

		if (currentAnswers.length !== previousAnswers.length) {
			return true;
		}

		for (let i = 0; i < currentAnswers.length; i++) {
			if (currentAnswers[i].text !== previousAnswers[i].text) {
				return true;
			}
		}

		return false;
	}, [slideHistory]);

	const onClickSlideHistoryUndo = useCallback(() => {
		if (slideHistory) {
			if (setOptionsRef.current && slideHistory.previous.answers) {
				setOptionsRef.current(cloneDeep(slideHistory.previous.answers));
			}

			if (slideHistory.update) {
				slideHistory.update((current, previous) => {
					current.answers = cloneDeep(previous.answers);
				});
			}
		}
	}, [slideHistory]);

	const legendRef = useRef(null);

	useEffect(() => {
		if (map) {
			map.controls[window.google.maps.ControlPosition.BOTTOM_LEFT].push(legendRef.current);
		}
	}, [map]);

	return (
		<div className="flex flex-col w-full">
			<SlideEditorHeader
				title={
					<>
						{trans("Correct answer")}
						<RequiredFieldWarning
							hidden={
								newlyCreated ||
								(options?.[0]?.text || "").length >= 1 ||
								(options?.[1]?.text || "").length >= 1
							}
						/>
					</>
				}
				titleClassName="bg-green-light"
				undoEnabled={slideHistoryUndoEnabled}
				onUndo={onClickSlideHistoryUndo}
				onReGenerate={aiService ? () => void aiComplete("answer") : null}
			/>
			<div className="bg-green-light rounded-tr-xl flex flex-col items-stretch w-full gap-3 p-1">
				<div className=" flex flex-col w-full p-2 space-y-1">
					<div className="justify-items-stretch grid grid-cols-3 gap-3">
						{[
							["Countries", PLACE_TYPE_COUNTRY],
							["States", PLACE_TYPE_STATE],
							["Pin-point", PLACE_TYPE_PINPOINT],
						].map(([name, id], i, arr) => (
							<button
								key={i}
								// color={placeType === id ? "cyan" : "white"}
								className={tailwindCascade(
									"h-auto px-0 rounded-lg bg-opacity-20 bg-black overflow-hidden relative",
									"text-white font-bold py-2",
									{
										"border-white border-solid border-4": id === placeType,
										"hover:border-white hover:border-solid hover:border-4 hover:border-opacity-50":
											id !== placeType,
									}
								)}
								onClick={() => void switchPlaceType(id)}
								disabled={id === placeType}
							>
								<div
									className={tailwindCascade("absolute inset-0 bg-white pointer-events-none", {
										hidden: id !== placeType,
									})}
									style={{ opacity: 1 - flashPhase }}
								></div>
								{name}
							</button>
						))}
					</div>
				</div>
			</div>
			<div
				className={tailwindCascade("bg-[#008657] w-full h-[512px] flex flex-col overflow-hidden z-0", {
					"rounded-b-xl": !target,
				})}
			>
				<div className="p-3">
					<Input ref={searchBoxRef} className="m-0" placeholder={trans("Search location")}></Input>
				</div>
				<div className="bg-[#008657] relative flex h-full min-h-[20rem]">
					<Wrapper
						apiKey={GOOGLE_MAPS_API_KEY}
						version={GOOGLE_MAPS_API_VERSION}
						libraries={GOOGLE_MAPS_LIBRARIES}
					>
						<Map
							center={view?.center || { lat: 0, lng: 0 }}
							onClick={onMapClick}
							onMouseMove={onMapMove}
							onMouseOut={onMapOut}
							gestureHandling={"greedy"}
							onIdle={onIdle}
							onMapLoaded={setMap}
							onTilesLoaded={() => void setTilesLoaded(true)}
							zoom={view?.zoom || 1}
							clickableIcons={false}
							mapType={view?.type || MAP_TYPE_REGULAR}
							disableDefaultUI={true}
							zoomControl={true}
							fullscreenControl={true}
							style={{
								flexGrow: "1",
								height: "100%",
								visibility: tilesLoaded ? "visible" : "hidden",
							}}
							onRenderingTypeChanged={(map) => void setRenderingType(map.getRenderingType())}
							restriction={MAP_RESTRICTION}
						>
							{placeType === PLACE_TYPE_STATE && (
								<FeaturePoly
									feature={geoStateMask}
									strokeColor={WHITE}
									strokeOpacity={0}
									strokeWeight={0}
									fillColor={BLACK}
									fillOpacity={0.6}
									clickable={false}
								/>
							)}
							{target &&
								["lat", "lng", "radius"].every((prop) =>
									Object.prototype.hasOwnProperty.call(target, prop)
								) && <Marker position={target} />}
							<FeaturePoly
								feature={secondarySelectedFeature}
								strokeColor={BLACK}
								strokeOpacity={0.7}
								strokeWeight={2}
								fillOpacity={0}
								clickable={false}
							/>
							<FeaturePoly
								feature={secondaryHoverFeature}
								strokeColor={BLACK}
								strokeOpacity={0.7}
								strokeWeight={2}
								fillOpacity={0}
								clickable={false}
							/>
							<FeaturePoly
								feature={primarySelectedFeature}
								strokeColor={
									[MAP_TYPE_HYBRID, MAP_TYPE_SATELLITE].includes(view?.type) ? WHITE : GREEN_DARK
								}
								strokeOpacity={1}
								strokeWeight={2}
								fillColor={GREEN_LIGHT}
								fillOpacity={0.9}
								clickable={false}
							/>
							<FeaturePoly
								feature={primaryHoverFeature}
								strokeColor={
									[MAP_TYPE_HYBRID, MAP_TYPE_SATELLITE].includes(view?.type) ? WHITE : GREEN_DARK
								}
								strokeOpacity={0.5}
								strokeWeight={2}
								fillColor={GREEN_LIGHT}
								fillOpacity={0.4}
								clickable={false}
							/>
							{target &&
								["lat", "lng", "radius"].every((prop) =>
									Object.prototype.hasOwnProperty.call(target, prop)
								) && <Dartboard center={target} radius={target.radius} opacity={0.75} zIndex={1} />}
						</Map>
					</Wrapper>
					<div
						ref={legendRef}
						className={tailwindCascade(
							"m-4",
							"bg-pink-light text-white rounded-lg",
							"flex flex-row items-center gap-2",
							"h-11 w-69 px-2", // left-14 absolute bottom-8
							"transform -rotate-90 origin-bottom-left",
							"-translate-x-[40px] -translate-y-[16px]",
							{
								hidden:
									!map ||
									!target ||
									!["lat", "lng", "radius"].every((prop) =>
										Object.prototype.hasOwnProperty.call(target, prop)
									) ||
									!tilesLoaded,
							}
						)}
					>
						<div className="w-7 h-7 relative overflow-visible">
							<TargetIcon className="top-1/2 left-1/2 absolute w-4 h-4 transform -translate-x-1/2 -translate-y-1/2" />
						</div>
						<Slider
							className="red-slider m-0"
							value={parseFloat(Math.log10(target?.radius >> 0).toFixed(3))}
							setValue={(value) => {
								const radius = parseFloat((10 ** parseFloat(value.toFixed(3))).toPrecision(5));
								setOptions(serialize({ target: { ...target, radius }, view }));
							}}
							min={Math.log10(RADIUS_MIN)}
							max={Math.log10(RADIUS_MAX)}
							vertical={true}
						/>
						<div className="w-7 h-7 relative overflow-visible">
							<TargetIcon className="top-1/2 left-1/2 absolute w-8 h-8 transform -translate-x-1/2 -translate-y-1/2" />
						</div>
					</div>
					{primarySelectedFeature && (
						<div
							className={tailwindCascade(
								"absolute left-1/2 bottom-4 -translate-x-1/2",
								"bg-green-light text-white font-bold",
								"border-green-dark border-solid border-3",
								"leading-none",
								"px-4 py-2 rounded-full",
								"flex flex-row gap-1 items-center"
							)}
							title={adminFeaturesEnabled ? primarySelectedFeature.properties.id : undefined}
						>
							<CorrectIcon className="h-4" />
							<p className="whitespace-nowrap">
								{primarySelectedFeature.properties.name +
									(secondarySelectedFeature
										? ` (${
												{ "United States of America": "USA", "United Kingdom": "UK" }[
													secondarySelectedFeature.properties.name
												] || secondarySelectedFeature.properties.name
										  })`
										: "")}
							</p>
						</div>
					)}
				</div>
				<div className="md:gap-2 flex flex-row justify-between gap-1 p-2">
					{Object.entries(GOOGLE_MAPS_MAP_IDS)
						.filter(([key, value]) => !value.exclude)
						.map(([key, value], i) => (
							<button
								className={tailwindCascade("p-0 m-0 overflow-hidden rounded-lg box-content flex-grow", {
									"border-4 border-white border-solid": view?.type === key,
									"m-1 hover:m-0 hover:border-4 hover:border-white hover:border-solid hover:border-opacity-50":
										view?.type !== key,
								})}
								disabled={view?.type === key}
								key={i}
								title={value.description}
								onClick={() => void setOptions(serialize({ target, view: { ...view, type: key } }))}
							>
								<div className="pb-full relative h-0">
									<div className="absolute inset-0">
										<img
											src={`/images/map/${value.preview}`}
											height="100%"
											width="100%"
											className="p-0 m-0"
											alt={value.description}
										/>
									</div>
								</div>
							</button>
						))}
				</div>
			</div>
			{target && (
				<div className="rounded-b-xl bg-[#008657] z-0 w-full overflow-hidden flex flex-col px-3 pt-2 pb-4 space-y-2">
					<p className="text-sm font-bold leading-tight text-white">
						{trans(
							"The zoom level and position of the map shown above will be how the map will look for the players at the start."
						)}
					</p>
					{placeType === PLACE_TYPE_PINPOINT && (
						<p className="text-sm font-bold leading-tight text-white">
							{trans(
								"Use the slider to set the size of the target, which will decide how precicely you need to aim to get points."
							)}
						</p>
					)}
				</div>
			)}
		</div>
	);
}

function Rect(options) {
	const [rect, setRect] = useState(null);

	useEffect(() => {
		if (!rect) {
			// 	const rectangle = new window.google.maps.Rectangle({
			// 	strokeColor: "#FF0000",
			// 	strokeOpacity: 0.8,
			// 	strokeWeight: 2,
			// 	fillColor: "#FF0000",
			// 	fillOpacity: 0.35,
			// 	map,
			// 	bounds, // result.geometry.bounds,
			// });

			setRect(new window.google.maps.Rectangle());
		}
		return () => {
			if (rect) {
				rect.setMap(null);
			}
		};
	}, [rect]);

	useEffect(() => {
		if (rect) {
			rect.setOptions(options);
		}
	}, [rect, options]);

	return null;
}

export const FeaturePoly = memo(function FeaturePoly({ feature, ...options }) {
	const geometry = feature?.geometry;

	const paths = useMemo(() => {
		let groups = [];

		if (geometry?.type === "MultiPolygon") {
			groups = geometry?.coordinates;
		} else if (geometry?.type === "Polygon") {
			groups = [geometry?.coordinates];
		}

		const paths = [];

		for (const group of groups) {
			paths.push(...polysToPaths(group));
		}

		return paths;
	}, [geometry]);

	return <Poly paths={paths} {...options} />;
}, isEqual);

export function Poly(options) {
	const [poly, setPoly] = useState(null);

	useEffect(() => {
		if (!poly) {
			setPoly(new window.google.maps.Polygon());
		}
		return () => {
			if (poly) {
				poly.setMap(null);
			}
		};
	}, [poly]);

	useEffect(() => {
		if (poly) {
			poly.setOptions(options);
		}
	}, [poly, options]);

	useListener(poly, "click", options?.onClick);
	useListener(poly, "mouseover", options?.onMouseOver);
	useListener(poly, "mousemove", options?.onMouseMove);

	return null;
}

export function Polyline(options) {
	const [polyline, setPolyline] = useState(null);

	useEffect(() => {
		if (!polyline) {
			setPolyline(new window.google.maps.Polyline());
		}
		return () => {
			if (polyline) {
				polyline.setMap(null);
			}
		};
	}, [polyline]);

	useEffect(() => {
		if (polyline) {
			polyline.setOptions(options);
		}
	}, [polyline, options]);

	useEffect(() => {
		if (polyline && options?.onClick) {
			const listener = window.google.maps.event.addListener(polyline, "click", options?.onClick);
			return () => void window.google.maps.event.removeListener(listener);
		}
	}, [polyline, options?.onClick]);

	useEffect(() => {
		if (polyline && options?.onMouseOver) {
			const listener = window.google.maps.event.addListener(polyline, "mouseover", options?.onMouseOver);
			return () => void window.google.maps.event.removeListener(listener);
		}
	}, [polyline, options?.onMouseOver]);

	return null;
}

export function geometryToPolys(geometry) {
	let polyGroups = [];

	if (geometry?.type === "MultiPolygon") {
		polyGroups = geometry?.coordinates;
	} else if (geometry?.type === "Polygon") {
		polyGroups = [geometry?.coordinates];
	}

	const polys = [];
	polyGroups.forEach((group) => polys.push(...group));

	return polys;
}

export function soften(poly) {
	const newPoly = [];

	const n = poly.length;

	const norm2 = (v1, v2) => Math.sqrt(Math.pow(v2[0] - v1[0], 2) + Math.pow(v2[1] - v1[1], 2));

	const lerp1 = (x1, x2, t) => (x1 != x2 ? x1 + t * (x2 - x1) : x1);
	const lerp2 = (p1, p2, t) => [lerp1(p1[0], p2[0], t), lerp1(p1[1], p2[1], t)];

	const threshold = 0.2;
	const radius = 0.1;

	for (let i = 0; i < n - 1; i++) {
		const [p0, p1, p2] = [-1, 0, 1].map((j) => poly[(i + j + n) % n]);

		const d01 = norm2(p0, p1);
		const d12 = norm2(p1, p2);

		if (d01 > threshold && d12 > threshold) {
			const p01 = lerp2(p1, p0, radius / d01);
			const p12 = lerp2(p1, p2, radius / d12);
			const p1n = [(p01[0] + p12[0] + 1 * p1[0]) / 3, (p01[1] + p12[1] + 1 * p1[1]) / 3];
			newPoly.push(p01, p1n, p12);
		} else if (d01 > threshold) {
			const p01 = lerp2(p1, p0, radius / d01);
			const p1n = [(p01[0] + p2[0] + 2 * p1[0]) / 4, (p01[1] + p2[1] + 2 * p1[1]) / 4];
			newPoly.push(p01, p1n);
		} else if (d12 > threshold) {
			const p12 = lerp2(p1, p2, radius / d12);
			const p1n = [(p0[0] + p12[0] + 2 * p1[0]) / 4, (p0[1] + p12[1] + 2 * p1[1]) / 4];
			newPoly.push(p1n, p12);
		} else {
			newPoly.push(p1);
		}
	}
	return newPoly;
}

export function polysToPaths(polys) {
	const transf = (poly) => poly.map(([lng, lat]) => ({ lat, lng }));
	return polys.map(transf);
}
