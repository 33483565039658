import { SLIDE_TYPE_REORDER, SLIDE_TYPE_TYPE_ANSWER } from "@/app-constants.mjs";

export function formatPoints(points) {
	return Intl.NumberFormat("en-US").format(points).replace(",", " ");
}

export function formatAnswerString(type, answers) {
	if (type === SLIDE_TYPE_TYPE_ANSWER) {
		for (let i = 0; i < answers.length; i++) {
			if (answers[i].isCorrect) {
				return answers[i].text;
			}
		}

		return null;
	} else if (type === SLIDE_TYPE_REORDER) {
		return [...answers]
			.sort((a, b) => a.pos - b.pos)
			.map((ans) => ans.text)
			.join(", ");
	} else {
		return answers.reduce((previousValue, currentValue) => {
			return previousValue + `${currentValue.isCorrect ? currentValue.text + ". " : ""}`;
		}, "");
	}
}
