import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import isEqual from "lodash/isEqual";

import Image from "@/components/Image";
import Reveal from "@/components/Reveal";
import GiphyVideo from "@/components/interactives/GiphyVideo";
import TimelapsePlayer from "@/components/interactives/TimelapsePlayer";
import YoutubePlayer2 from "@/components/interactives/YoutubePlayer2";
import { StreetViewPlay } from "@/components/interactives/maps/StreetView";

import useForwardRef from "@/hooks/useForwardRef";

import useSettingsStore from "@/stores/settings";

import { IS_REVEAL_TYPE_TIMELAPSE } from "@/constants";
import useRefMounted from "@/hooks/useRefMounted";

const SLIDE_IMAGE_DEFAULT_STYLE = {
	width: 0,
	height: 0,
	transform: `translate(-50%,-50%)`,
};

const SlideImage = forwardRef(function SlideImage(
	{ onSizeUpdate, onLoad, src, alt, generated, ...props },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const updateSize = useCallback(() => {
		if (ref.current && ref.current.complete) {
			const { naturalWidth, naturalHeight } = ref.current;
			onSizeUpdate && onSizeUpdate(naturalWidth || 0, naturalHeight || 0);
		} else {
			onSizeUpdate && onSizeUpdate(0, 0);
		}
	}, [onSizeUpdate, ref]);

	const onLoadImage = useCallback(() => {
		updateSize();
		if (onLoad) {
			onLoad();
		}
	}, [onLoad, updateSize]);

	return (
		<>
			<Image ref={ref} src={src} onLoad={onLoadImage} alt={alt} {...props} />;
		</>
	);
});

const SlideMedia = forwardRef(function SlideMedia(
	{
		paused: isGamePaused,
		dimMedia = false,
		onLoad,
		onError: onErrorExt,
		progress,
		visible,
		setIsPlayingWithSound = () => {},
		envelope = 1,
		mediaSize,
		...props
	},
	forwardedRef
) {
	const [, mountedRef] = useRefMounted();

	const youtubePlayerRef = useRef();
	const imageRef = useRef();
	const giphyVideoRef = useRef();
	const youtubePlayPromiseRef = useRef(null);

	const mediaVolume = useSettingsStore((state) => state.mediaVolume);

	const [error, setError] = useState(false);

	const onSlideImageSizeUpdate = useCallback((naturalWidth, naturalHeight) => {
		if (imageRef.current) {
			if (naturalWidth && naturalHeight) {
				const ratio = naturalWidth / naturalHeight / (4 / 3);
				imageRef.current.style.width = `${ratio * 100}%`;
				imageRef.current.style.height = `${(1 / ratio) * 100}%`;
			} else {
				imageRef.current.width = imageRef.current.height = 0;
			}
		}
	}, []);

	const onGiphyVideoSizeUpdate = useCallback((width, height) => {
		if (giphyVideoRef.current && giphyVideoRef.current.containerElement) {
			if (width && height) {
				const ratio = width / height / (4 / 3);
				giphyVideoRef.current.containerElement.style.width = `${ratio * 100}%`;
				giphyVideoRef.current.containerElement.style.height = `${(1 / ratio) * 100}%`;
			} else {
				giphyVideoRef.current.containerElement.width = giphyVideoRef.current.containerElement.height = 0;
			}
		}
	}, []);

	const onYoutubePlayerStart = useCallback(() => {
		if (youtubePlayPromiseRef.current) {
			youtubePlayPromiseRef.current.resolve();
			youtubePlayPromiseRef.current = null;
		}
		const silent = props.mute === true || props.volume === 0;
		setIsPlayingWithSound(!silent);
	}, [props.mute, props.volume, setIsPlayingWithSound]);

	const onYoutubePlayerEnd = useCallback(() => {
		if (props.onYoutubeEnd) {
			props.onYoutubeEnd();
		}
		setIsPlayingWithSound(false);
	}, [props, setIsPlayingWithSound]);

	useEffect(() => {
		return () => void setIsPlayingWithSound(false);
	}, [setIsPlayingWithSound]);

	const onError = useCallback(() => {
		setError(true);
		if (onErrorExt) {
			onErrorExt();
		}
	}, [onErrorExt]);

	const playMedia = useCallback(() => {
		if (youtubePlayerRef.current) {
			youtubePlayerRef.current.play();
		}

		if (giphyVideoRef.current) {
			giphyVideoRef.current.play();
		}
	}, []);

	const playMediaRef = useRef(playMedia);
	useEffect(() => void (playMediaRef.current = playMedia), [playMedia]);

	const pauseStateRef = useRef(null);
	useEffect(() => {
		if (youtubePlayerRef.current) {
			if (isGamePaused) {
				pauseStateRef.current = youtubePlayerRef.current.getDesiredState();
				youtubePlayerRef.current.pause();
			} else {
				if (pauseStateRef.current === "playing") {
					youtubePlayerRef.current.play();
					pauseStateRef.current = null;
				}
			}
		}

		if (giphyVideoRef.current) {
			if (isGamePaused) {
				giphyVideoRef.current.pause();
			} else {
				giphyVideoRef.current.play();
			}
		}
	}, [isGamePaused]);

	useEffect(() => {
		return () => {
			if (youtubePlayPromiseRef.current) {
				youtubePlayPromiseRef.current.resolve();
				youtubePlayPromiseRef.current = null;
			}
		};
	}, []);

	useImperativeHandle(
		forwardedRef,
		() => ({
			async play() {
				if (props.type === "youtube") {
					if (!youtubePlayerRef.current) {
						console.error("No youtube player instance");
						return;
					}

					if (!error) {
						try {
							await youtubePlayerRef.current.untilReady();

							if (mountedRef.current) {
								const youtubePlayPromise = new Promise(
									(resolve, reject) => void (youtubePlayPromiseRef.current = { resolve, reject })
								);

								playMediaRef.current && playMediaRef.current();

								await youtubePlayPromise; // this will be resolved when the video has started
							}
						} catch (error) {
							console.error(error);
						}
					}
				}
			},
			setVolume(value) {
				if (props.type === "youtube") {
					if (youtubePlayerRef.current) {
						youtubePlayerRef.current.setVolume(Math.round(value * 100));
					}
				}
			},
		}),
		[error, props.type]
	);

	const giphyVideoStyle = useMemo(() => {
		const translate = `translate(${(props.transformX || 0) - 50}%,${(props.transformY || 0) - 50}%)`;
		const scale = `scale(${props.transformZ || 1})`;
		return {
			transform: `${translate} ${scale}`,
		};
	}, [props.transformX, props.transformY, props.transformZ]);

	const [url, isGiphyVideo] = useMemo(() => {
		let url = props.url;

		let isGiphyVideo = false; // TODO: Replace when serverside is done hosting mp4 for Giphy
		if (url && url.indexOf("/giphy/") !== -1) {
			if (url.indexOf("?") !== -1) {
				url = url.split("?")[0]; // Remove query string
			}
			let extension = url.substring(url.length - 4, url.length);
			if (extension === ".gif") {
				url = `${url.substring(0, url.length - 4)}.mp4`;
				extension = ".mp4";
			}
			if (extension === ".mp4") {
				isGiphyVideo = true;
			}
		}

		return [url, isGiphyVideo];
	}, [props.url]);

	if (props.type === "street") {
		return (
			<StreetViewPlay
				position={props.position}
				pov={props.pov}
				dimMedia={dimMedia}
				visible={visible}
				onLoad={onLoad}
				onError={onError}
				paused={isGamePaused}
				mediaSize={mediaSize}
				interactive={props.interactiveStreetView}
				prefetch={props.prefetch}
			/>
		);
	}

	if (props.type === "youtube") {
		// Reveal is inside YoutubePlayer2
		return (
			<YoutubePlayer2
				ref={youtubePlayerRef}
				id={props.slideId}
				videoId={props.id}
				mute={props.mute || props.volume === 0}
				start={props.start}
				duration={props.duration}
				loop={props.loop}
				dim={dimMedia}
				onLoad={onLoad}
				onEnd={onYoutubePlayerEnd}
				onError={onError}
				volume={isFinite(props.volume) ? props.volume * envelope : props.volume}
				onClick={props.onYoutubeClick}
				onStart={onYoutubePlayerStart}
				volumeMultiplier={mediaVolume}
				revealType={props.revealType}
				revealSeed={props.source}
				revealProgress={progress}
				isGamePaused={isGamePaused}
			/>
		);
	} else if (
		(props.type === "image" || props.type === "svg" || props.type === "placeholder") &&
		!IS_REVEAL_TYPE_TIMELAPSE(props.revealType)
	) {
		return (
			<>
				<Reveal className="overflow-hidden" type={props.revealType} seed={props.source} progress={progress}>
					{isGiphyVideo ? (
						<GiphyVideo
							ref={giphyVideoRef}
							src={url}
							className="max-w-none left-1/2 top-1/2 absolute block min-w-full min-h-full pointer-events-none"
							style={giphyVideoStyle}
							onLoad={onLoad}
							onSizeUpdate={onGiphyVideoSizeUpdate}
						/>
					) : (
						<SlideImage
							ref={imageRef}
							src={url}
							alt=""
							className="max-w-none left-1/2 top-1/2 absolute block min-w-full min-h-full pointer-events-none"
							style={SLIDE_IMAGE_DEFAULT_STYLE}
							onLoad={onLoad}
							onSizeUpdate={onSlideImageSizeUpdate}
							generated={props.generated}
						/>
					)}
				</Reveal>
				{props.generated && (
					<img
						src="/images/icons/icon-label-ai-generated.svg"
						alt=""
						className="block w-auto bottom-[1.5%] right-[1.5%] absolute md:h-[6%] h-[8%] pointer-events-none"
					/>
				)}
				{dimMedia && <div className="absolute inset-0 bg-black bg-opacity-50 pointer-events-none"></div>}
			</>
		);
	} else if (props.type === "svg" && IS_REVEAL_TYPE_TIMELAPSE(props.revealType)) {
		return (
			<>
				<TimelapsePlayer
					className="absolute block object-contain w-full h-full pointer-events-none"
					src={props.url}
					onLoad={onLoad}
					type={props.revealType}
					seed={props.source}
					progress={progress}
				/>
				{dimMedia && <div className="absolute inset-0 bg-black bg-opacity-50 pointer-events-none"></div>}
			</>
		);
	}

	return null;
});

export default memo(SlideMedia, isEqual); // Use deep comparison
