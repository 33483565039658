import { useEffect, useState, memo, useRef } from "react";
import { apiPatchAudioClip, apiGetCachedAudioClip } from "@/api/quiz";
import useRefMounted from "@/hooks/useRefMounted";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";

import { CDN_BASE_URL } from "@/constants";
import { voicePlayer } from "@/helpers/audio";

function VoiceClip({ voice, text, play, isHost, onEnd = () => {}, onPlayError = () => {} }) {
	const [, mountedRef] = useRefMounted();
	const [url, setUrl] = useState(null);
	const [sound, setSound] = useState(null);
	const [gain, setGain] = useState(1);

	const gainRef = useRef(gain);
	useEffect(() => void (gainRef.current = gain), [gain]);

	useEffect(() => {
		setUrl(null); // null = loading

		if (text && voice) {
			setGain(voice === "Samantha" ? 0.707 : 1);
			(isHost ? apiPatchAudioClip : apiGetCachedAudioClip)(voice, text)
				.then((result) => {
					if (mountedRef.current) {
						// false = load error
						setUrl(result?.filename ? `${CDN_BASE_URL}/${result.filename}` : false);
					}
				})
				.catch(() => void setUrl(false));
		}
	}, [isHost, mountedRef, text, voice]);

	useEffect(() => {
		if (play && sound) {
			voicePlayer.play(sound, onEnd, onPlayError);
			return () => void voicePlayer.stop(sound);
		} else if (play && sound === false) {
			onPlayError();
		}
	}, [onEnd, onPlayError, play, sound, text]);

	useEffect(() => {
		if (url === false) {
			// load error
			setSound(false);
		} else if (url) {
			setSound(null);

			const sound = voicePlayer.load(url, (error, sound) => {
				if (mountedRef.current) {
					if (!error && sound) {
						sound.volume(sound._volume * gainRef.current);
						setSound(sound);
					} else {
						setSound(false);
					}
				}
			});
			return () => {
				voicePlayer.unload(sound);
				setSound(null);
			};
		}
	}, [mountedRef, url]);

	return null;
}

function TextToSpeech({ voice, text, play, isHost, onEnd = () => {}, onPlayError }) {
	const [, mountedRef] = useRefMounted();
	const [index, setIndex] = useState(0);

	if (isArray(text)) {
		return text.map((line, i) => (
			<VoiceClip
				key={i}
				voice={voice}
				text={line}
				play={play && index === i}
				isHost={isHost}
				onEnd={
					index < text.length - 1
						? () => {
								if (mountedRef.current) {
									setIndex(i + 1);
								}
						  }
						: onEnd
				}
				onPlayError={onPlayError}
			/>
		));
	} else {
		return (
			<VoiceClip voice={voice} text={text} play={play} isHost={isHost} onEnd={onEnd} onPlayError={onPlayError} />
		);
	}
}

export default memo(TextToSpeech, isEqual);
