import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";

import cloneDeep from "lodash/cloneDeep";
import isFinite from "lodash/isFinite";
import isString from "lodash/isString";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import getAutoRange from "@/helpers/getAutoRange";

import Input from "@/components/interactives/Input";
import CheckboxCyan from "@/components/interactives/CheckboxCyan";
import SlideEditorHeader from "@/components/pages/edit/SlideEditorHeader";
import RequiredFieldWarning from "@/components/pages/edit/RequiredFieldWarning";

import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";

import { RANGE_SLIDE_ERROR_SCORE_BREAKPOINT } from "@/constants";

const NUM_MAJOR_TICKS = 6;

export default function RangeSlideEditor({
	aiComplete,
	aiService,
	options,
	setOptions,
	slideHistory,
	newlyCreated,
	...props
}) {
	const setOptionsRef = useRef(setOptions);
	useEffect(() => void (setOptionsRef.current = setOptions), [setOptions]);

	const [unitVisible, setUnitVisible] = useState(false);

	const [value, unit, exactly] = useMemo(() => {
		const exactly = options.length > 2 && options[2].text === "exactly";

		const str = options[0].text;
		const i = str.indexOf(" ");

		if (i >= 0) {
			setUnitVisible(true);
			const value = str.slice(0, i);
			const unit = str.slice(i + 1);
			return [value, unit, exactly];
		} else {
			return [str, "", exactly];
		}
	}, [options]);

	const [min, max] = useMemo(() => {
		if (options[1].text === "" || options[1].text === ",") {
			return getAutoRange(value);
		}

		const arr = options[1].text.split(",");
		if (arr.length == 1 && options[1].text !== "") {
			return [arr[0], ""];
		} else if (arr.length >= 2) {
			return [arr[0], arr[1]];
		} else {
			return ["", ""];
		}
	}, [options, value]);

	const grid = useMemo(() => getGrid({ min, max, value }), [max, min, value]);
	const correctValueIndex = useMemo(() => grid.indexOf(value), [grid, value]);

	const userDefinedRange = useMemo(() => options[1].text !== "", [options]);

	const handleValueChange = useCallback(
		(ev) => {
			const newValue = ev.target.value;
			const newOptions = cloneDeep(options);
			newOptions[0].text = unit ? [newValue, unit].join(" ") : newValue;
			setOptions(newOptions);
		},
		[options, setOptions, unit]
	);

	const handleUnitChange = useCallback(
		(ev) => {
			const opts = cloneDeep(options);
			opts[0].text = [value, ev.target.value].join(" ");
			setOptions(opts);
		},
		[options, setOptions, value]
	);

	const handleMinChange = useCallback(
		(ev) => {
			const newMin = ev.target.value;
			const newOptions = cloneDeep(options);
			newOptions[1].text = /*max === "" ? newMin :*/ [newMin, max].join(",");
			setOptions(newOptions);
		},
		[max, options, setOptions]
	);

	const handleMaxChange = useCallback(
		(ev) => {
			const newMax = ev.target.value;
			const newOptions = cloneDeep(options);
			newOptions[1].text = /*newMax === "" ? min :*/ [min, newMax].join(",");
			setOptions(newOptions);
		},
		[min, options, setOptions]
	);

	const valueOutsideRange = useMemo(() => {
		const [minFloat, maxFloat, valueFloat] = [min, max, value].map(parseFloat);
		return (
			userDefinedRange &&
			[minFloat, maxFloat, valueFloat].every(isFinite) &&
			(valueFloat < minFloat || valueFloat > maxFloat)
		);
	}, [max, min, userDefinedRange, value]);

	const handleValueBlur = useCallback(
		(ev) => {
			const newOptions = cloneDeep(options);
			if (isFinite(parseFloat(value)) && !userDefinedRange) {
				const [min, max] = getAutoRange(value);
				newOptions[1].text = [min, max].join(",");
			}
			newOptions[0].text = unit ? [removeLeadingZeros(value), unit].join(" ") : removeLeadingZeros(value);
			setOptions(newOptions);
		},
		[getAutoRange, options, setOptions, unit, userDefinedRange, value]
	);

	const slideHistoryUndoEnabled = useMemo(() => {
		if (
			!slideHistory ||
			!slideHistory.current ||
			!slideHistory.previous ||
			slideHistory.current.answers.length === 0 ||
			slideHistory.previous.answers.length === 0
		) {
			return false;
		}

		const currentAnswers = slideHistory.current.answers;
		const previousAnswers = slideHistory.previous.answers;

		if (currentAnswers.length !== previousAnswers.length) {
			return true;
		}

		for (let i = 0; i < currentAnswers.length; i++) {
			if (currentAnswers[i].text !== previousAnswers[i].text) {
				return true;
			}
		}

		return false;
	}, [slideHistory]);

	const onClickSlideHistoryUndo = useCallback(() => {
		if (slideHistory) {
			if (setOptionsRef.current && slideHistory.previous.answers) {
				setOptionsRef.current(cloneDeep(slideHistory.previous.answers));
			}

			if (slideHistory.update) {
				slideHistory.update((current, previous) => {
					current.answers = cloneDeep(previous.answers);
				});
			}
		}
	}, [slideHistory]);

	if (options?.length < 2) {
		return null;
	}

	return (
		<div className="flex flex-col w-full">
			<SlideEditorHeader
				title={
					<>
						{trans("Correct value")}
						<RequiredFieldWarning hidden={newlyCreated || (options?.[0]?.text || "").length >= 1} />
					</>
				}
				titleClassName="bg-green-light"
				undoEnabled={slideHistoryUndoEnabled}
				onUndo={onClickSlideHistoryUndo}
				onReGenerate={aiService ? () => void aiComplete("answer") : null}
			/>
			<div className="bg-green-light rounded-b-xl rounded-tr-xl flex flex-col w-full gap-2 py-4 mb-4 text-white">
				<div className="flex flex-col w-full px-4 space-y-1">
					<div className="sm:gap-6 flex flex-row items-center gap-4">
						<div
							className={tailwindCascade("w-5/12 shrink-0 relative", {
								"outline-4 outline-error rounded-xl outline": valueOutsideRange,
							})}
						>
							<Input
								type="number"
								value={value}
								onChange={handleValueChange}
								onBlur={handleValueBlur}
								className="mb-0"
								placeholder={trans("Required")}
							></Input>
						</div>

						{unitVisible ? (
							<div className="flex flex-row items-center space-x-2">
								<div className="text-sm font-bold">{trans("Unit:")}</div>
								<Input
									value={unit}
									onChange={handleUnitChange}
									className="flex-grow mb-0"
									placeholder={trans("Optional")}
								></Input>
							</div>
						) : (
							<button type="button" onClick={() => void setUnitVisible(true)}>
								<span className="text-black-10 underline">{trans("Add unit")}</span>
							</button>
						)}
					</div>
					{valueOutsideRange && (
						<p className="pt-2 pb-4 text-sm font-bold leading-none">
							{trans("Value outside min-max range")}
						</p>
					)}
				</div>
			</div>

			<SlideEditorHeader title={trans("Range")} titleClassName="bg-petrol-dark" />
			<div className="bg-petrol-dark rounded-b-xl rounded-tr-xl flex flex-col w-full gap-2 py-4 text-white">
				<div className="flex flex-row justify-between w-full px-4 text-sm font-bold">
					<div>{trans("Min. value:")}</div>
					<div>{trans("Max. value:")}</div>
				</div>

				<div className="flex flex-row justify-between w-full px-4 pb-4">
					<div
						className={tailwindCascade("sm:w-4/12 w-5/12 shrink-0", {
							"outline-4 outline-error rounded-xl outline": min === "",
						})}
					>
						<Input
							type="number"
							value={min}
							onChange={handleMinChange}
							className="m-0"
							placeholder={trans("Required")}
						/>
					</div>

					<div
						className={tailwindCascade("sm:w-4/12 w-5/12 shrink-0", {
							"outline-4 outline-error rounded-xl outline": max === "",
						})}
					>
						<Input
							type="number"
							value={max}
							onChange={handleMaxChange}
							className="m-0"
							placeholder={trans("Required")}
						/>
					</div>
				</div>

				<div className="relative w-full px-4">
					{grid.length > 0 ? (
						<div className="flex flex-row items-end">
							{grid.map((x, i) => (
								<button
									key={i}
									className="basis-0 group relative flex-grow w-1"
									onClick={(ev) => {
										const newOptions = cloneDeep(options);
										newOptions[0].text = unit ? [x, unit].join(" ") : x;

										if (!userDefinedRange) {
											newOptions[1].text = [grid[0], grid[grid.length - 1]].join(",");
										}

										setOptions(newOptions);
									}}
								>
									<div
										className={tailwindCascade(
											"w-1 h-10 bg-white-50 group-hover:bg-white rounded-full relative",
											{
												"h-12": i % 10 === 0,
												"bg-white h-16": i === 0 || i === grid.length - 1,
												"bg-green-lighter":
													!exactly &&
													Math.abs(i - correctValueIndex) <
														(grid.length >= RANGE_SLIDE_ERROR_SCORE_BREAKPOINT ? 10 : 3),
												"bg-green-light group-hover:bg-green-lighter h-20 overflow-visible":
													parseFloat(x) === parseFloat(value),
											}
										)}
										title={x}
										style={{
											left: `calc(${(100 * i) / (grid.length - 1)}% - 2px)`,
										}}
									>
										{parseFloat(x) === parseFloat(value) && (
											<CorrectIcon className="fill-green-light group-hover:fill-green-lighter left-1/2 absolute top-0 w-6 h-6 text-white transform -translate-x-1/2" />
										)}
										<div
											className={tailwindCascade(
												"absolute left-1/2 transform -translate-x-1/2 md:-bottom-5 md:top-auto -top-4",
												"md:group-hover:opacity-100 text-sm text-white opacity-0",
												{
													"md:opacity-0 opacity-50":
														i % 10 == 0 &&
														i != 0 &&
														i != grid.length - 1 &&
														parseFloat(x) != parseFloat(value),
												}
											)}
										>
											{x}
										</div>
									</div>
								</button>
							))}
						</div>
					) : (
						<div className="bg-opacity-20 rounded-xl flex flex-col items-center justify-center w-full h-16 bg-black">
							<p className="text-sm font-bold">{trans("Invalid range/value combination")}</p>
						</div>
					)}
					{/* <div className="text-gray pt-4">
							<span>{trans("Values to be shown:")}</span>{" "}
							{grid.map((x, i) => (
								<span key={i} className={i % 10 === 0 ? undefined : "opacity-50"}>
									{x}
									{i < grid.length - 1 && ", "}
								</span>
							))}
						</div> */}
				</div>
				<div className="w-full px-4">
					<CheckboxCyan
						className="items-center gap-3 mt-4 text-sm font-bold leading-none"
						checked={exactly}
						onChange={(ev) => {
							const newOptions = cloneDeep(options);
							if (exactly) {
								newOptions.splice(2);
							} else {
								newOptions.splice(2, 1, { isCorrect: false, text: "exactly" });
							}
							setOptions(newOptions);
						}}
					>
						{/* {trans("Award some points for almost correct answers.")} */}

						{trans("Require exact answer for points")}
					</CheckboxCyan>
				</div>
			</div>
		</div>
	);
}
export function getGrid({ min: minInput, max: maxInput, value: valueInput }) {
	if (![minInput, maxInput].every((x) => isFinite(x) || (isString(x) && isFinite(parseFloat(x))))) {
		return [];
	}

	let [min, max, value] = [minInput, maxInput, valueInput].map(parseFloat);

	if (!isFinite(value) || value < min || value > max) {
		value = min;
	}

	const numValueDecimals = getNumDecimals(valueInput);

	value = value * 10 ** numValueDecimals;
	min = min * 10 ** numValueDecimals;
	max = max * 10 ** numValueDecimals;

	const range = max - min;

	if (range <= 0) {
		return [];
	}
	if (value < min || value > max) {
		return [];
	}

	const n = Math.min(range, (NUM_MAJOR_TICKS - 1) * 10);
	const step = range / n;

	const relativeValue = value - min;
	const quantizedRelativeValue = step * Math.floor(relativeValue / step);

	const gridShift = relativeValue - quantizedRelativeValue;
	const gridLength = Math.ceil(n * 0.1) * 10 + 1; // round up to next multiple of 10

	const values = new Array(gridLength).fill(0).map((_value, i) => gridShift + min + (range * i) / n);

	return values.map((val) => (val * 10 ** -numValueDecimals).toFixed(numValueDecimals));
}

const getNumDecimals = (str) => (isString(str) && str.includes(".") ? str.split(".")[1].length : 0);
const removeLeadingZeros = (str) => str.replace(/^(-?)(0+)(\d)/, "$1$3");
