import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useImmer } from "use-immer";
import useRefMounted from "@/hooks/useRefMounted";
import useForwardRef from "@/hooks/useForwardRef";

import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function Image(
	{ src, style = {}, alt = null, onLoad = null, className, ...props },
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);

	const [, mountedRef] = useRefMounted();

	const [state, updateState] = useImmer({
		width: 0,
		height: 0,
		complete: false,
	});

	useEffect(() => {
		updateState((draft) => {
			draft.width = 0;
			draft.height = 0;
			draft.complete = false;
		});
	}, [src]);

	useEffect(() => {
		let onLoadInternal = null;
		if (!state.complete && ref.current) {
			if (ref.current.complete) {
				updateState((draft) => {
					draft.width = ref.current.naturalWidth;
					draft.height = ref.current.naturalHeight;
					draft.complete = true;
				});
			} else {
				onLoadInternal = () => {
					if (ref.current && onLoadInternal) {
						ref.current.removeEventListener("load", onLoadInternal);
					}
					onLoadInternal = null;
					if (mountedRef.current) {
						updateState((draft) => {
							draft.width = ref.current.naturalWidth;
							draft.height = ref.current.naturalHeight;
							draft.complete = true;
						});
					}
				};
				ref.current.addEventListener("load", onLoadInternal);
			}
		}
		return () => {
			if (ref.current && onLoadInternal) {
				ref.current.removeEventListener("load", onLoadInternal);
				onLoadInternal = null;
			}
		};
	}, [state.complete]);

	useEffect(() => {
		if (state.complete && onLoad) {
			onLoad({ width: state.width, height: state.height });
		}
	}, [state.complete, onLoad]);

	return (
		<img
			ref={ref}
			className={tailwindCascade({ "opacity-0": !state.complete }, className)}
			style={style}
			src={src}
			alt={alt || ""}
			{...props}
		/>
	);
});
