import React, { useEffect, useRef, memo } from "react";

import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";

import createCustomOverlay from "@/helpers/createCustomOverlay";

import dartboardPointsSVG from "!!raw-loader!@/images/maps/dartboard-points.svg";
import dartboardPointsDoubleSVG from "!!raw-loader!@/images/maps/dartboard-points-double.svg";
import { haversineDistance } from "@/helpers/map";
import { getDartboardOpacity } from "./Dartboard";

const EARTHS_RADIUS = 6371;

export default memo(function DartboardPoints({ map, center, radius, opacity = 1, zIndex = 0, double = false }) {
	const customOverlayRef = useRef(null);

	useEffect(() => {
		const earthsRadius = EARTHS_RADIUS * 1000; /// Radius of the Earth in m
		const rlat = (radius / earthsRadius) * (180 / Math.PI);
		const rlng = rlat / Math.cos(center.lat * (Math.PI / 180));

		const bounds = new window.google.maps.LatLngBounds(
			new window.google.maps.LatLng(center.lat - rlat, center.lng - rlng),
			new window.google.maps.LatLng(center.lat + rlat, center.lng + rlng)
		);

		customOverlayRef.current = createCustomOverlay(map, bounds, center, () => {
			const div = document.createElement("div");
			const style = div.style;
			style.borderStyle = "none";
			style.borderWidth = "0px";
			style.position = "absolute";
			style.opacity = opacity * getDartboardOpacity(map, radius);
			style.zIndex = zIndex;
			div.innerHTML = double ? dartboardPointsDoubleSVG : dartboardPointsSVG;

			const svg = div.querySelector("svg");
			if (svg) {
				svg.style.width = svg.style.height = "100%";
			}
			return div;
		});
		customOverlayRef.current.setMap(map);

		const boundsChangedListener =
			map &&
			map.addListener("bounds_changed", () => {
				if (customOverlayRef.current && customOverlayRef.current.div) {
					customOverlayRef.current.div.style.opacity = opacity * getDartboardOpacity(map, radius);
				}
			});

		return () => {
			if (boundsChangedListener) {
				boundsChangedListener.remove();
			}
			if (customOverlayRef.current) {
				customOverlayRef.current.setMap(null);
				customOverlayRef.current = null;
			}
		};
	}, [map, center, radius, zIndex]);

	return null;
}, isEqual);
