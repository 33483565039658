/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";

import cloneDeep from "lodash/cloneDeep";

import useBrowser from "@/hooks/useBrowser";
import useViewportSize from "@/hooks/useViewportSize";
import useStatusSequence from "@/hooks/useStatusSequence";

import trans from "@/helpers/trans";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import SlideHeader from "@/components/pages/play/SlideHeader";
import SlideMedia from "@/components/pages/play/SlideMedia";
import ScaleContainer from "@/components/pages/play/ScaleContainer";
import FunFactMedia from "@/components/pages/play/FunFactMedia";
import Button from "@/components/interactives/Button";
import Header from "@/components/Header";

import CorrectIcon from "@/images/icons/check-bold.svg";

import {
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLACE_TYPE_COUNTRY,
	PLACE_TYPE_STATE,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_ANSWERS,
} from "@/constants";
import { SLIDE_TYPE_LOCATION, VOICES } from "@/app-constants.mjs";
import { SlidePlayers } from "@/components/pages/play/Players";
import { FUNFACT_MAX_HEIGHT } from "./BaseSlide";
import MobileQuestion from "../MobileQuestion";
import MobileMedia from "../MobileMedia";
import TextVoice, { getVoice } from "../../TextVoice";
import { BUTTON_BORDER_RADIUS } from "../../../interactives/Button";
import ProgressIndicator from "../../../ProgressIndicator";
import onWindowResize from "@/helpers/onWindowResize";
import clamp from "lodash/clamp";
import { deserialize } from "@/helpers/map";
import useGeoFeature from "@/hooks/useGeoFeature";
import { MEDIA_SIZE_BIG, MEDIA_SIZE_SMALL } from "./Location";

const QUESTION_MAX_HEIGHT = 96;
const FUN_FACT_MEDIA_LOAD_TIMEOUT = 1000;

export default function LocationSlide({
	className,
	doublePoints,
	mediaSize,
	mute,
	onLoad,
	onComplete,
	onQuestionStart,
	onAnswer,
	onLocalJoin,
	onError,
	submittedAnswer,
	submittedAnswerProgress,
	map = null,
	players,
	haveLocalPlayer,
	paused,
	setFunFactMediaIsPlayingWithSound,
	setMediaSize,
	setSlideMediaIsPlayingWithSound,
	slide,
	slideIndex,
	soloMarkerLatLng,
	statusWithProgress,
	isHost,
	showFunFact,
	showProgressBar,
	showQuestion,
	questionProgress,
	mountMedia,
	showMedia,
	waitForAnswer,
	waitForAnswerProgress,
	voiceOverride,
	placeName,
	showPlaceName,
	teams,
	teamMode,
	...props
}) {
	const ref = useRef(null);
	const browser = useBrowser();

	const viewportSize = useViewportSize();

	const { getVisibility, getProgress } = useStatusSequence(SLIDE_TYPE_LOCATION, statusWithProgress);

	const [slideMedia, setSlideMedia] = useState(null);
	const slideMediaRef = useRef(slideMedia);
	useEffect(() => {
		slideMediaRef.current = cloneDeep(slide.media);
		setSlideMedia(cloneDeep(slideMediaRef.current));
	}, [slide]);

	const [funFactMedia, setFunFactMedia] = useState(null);
	const funFactMediaRef = useRef(null);
	useEffect(() => {
		funFactMediaRef.current = cloneDeep(slide.funFactMedia);
		setFunFactMedia(cloneDeep(funFactMediaRef.current));
	}, [slide]);

	const hasQuestion = slide && slide.question;
	const hasVoice = slide && slide.id && slide.questionVoice && slide.question;
	const hasAnswerVoice = slide && slide.id && (slide.answerVoice || voiceOverride);
	const hasSlideMedia = slide && slide.media;
	const hasFunFactText = slide && slide.funFact;
	const hasFunFactMedia = slide && slide.funFactMedia;

	const slideMediaElementRef = useRef();
	const funFactMediaElementRef = useRef();

	const slideMediaIsYoutube = slideMedia?.type === "youtube";
	const slideMediaHeight = slideMediaIsYoutube ? 480 : 640;
	const slideMediaWidth = (Math.round((slideMediaIsYoutube ? 16 / 9 : 4 / 3) * slideMediaHeight) >> 1) << 1;

	const funFactMediaIsYoutube = funFactMedia?.type === "youtube";
	const funFactMediaHeight = funFactMediaIsYoutube ? 480 : 640;
	const funFactMediaWidth = (Math.round((funFactMediaIsYoutube ? 16 / 9 : 4 / 3) * funFactMediaHeight) >> 1) << 1;

	const readQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_SHOW_ANSWERS),
		[getVisibility]
	);

	const noAnimation = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const showCorrectAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_MAP_PIN, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const interactiveStreetView = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	const isSafari = browser?.satisfies({ safari: ">=1" });

	const onLoadRef = useRef(onLoad);
	useEffect(() => void (onLoadRef.current = onLoad), [onLoad]);

	const onQuestionStartRef = useRef(onQuestionStart);
	useEffect(() => void (onQuestionStartRef.current = onQuestionStart), [onQuestionStart]);

	useEffect(() => {
		if (!hasSlideMedia && onLoadRef.current) {
			onLoadRef.current();
		}
	}, [hasSlideMedia]);

	useEffect(() => {
		switch (statusWithProgress.name) {
			case PLAY_STATUS_WAIT_FOR_MEDIA:
				if (slideMediaElementRef.current) {
					let canceled = false;
					slideMediaElementRef.current
						.play()
						.then(() => {
							if (!canceled && onQuestionStartRef.current) {
								onQuestionStartRef.current();
							}
						})
						.catch(() => {
							if (!canceled && onQuestionStartRef.current) {
								onQuestionStartRef.current();
							}
						});
					return () => void (canceled = true);
				}
				break;
			case PLAY_STATUS_ALL_ANSWERS_RECEIVED:
				break;
			case PLAY_STATUS_YOUTUBE_END_1:
				break;
		}
	}, [statusWithProgress.name, statusWithProgress.progress]);

	const funFactMediaContainerRef = useRef(null);

	useEffect(() => {
		if (map && hasSlideMedia) {
			if (viewportSize.isDesktop) {
				const w = map.getDiv().offsetWidth;
				map.panBy(-0.25 * w, 0);
				// return () => void (map && map.panBy(0.25 * w, 0));
			} else {
				const h = map.getDiv().offsetHeight;
				map.panBy(0, -0.25 * h);
				// return () => void (map && map.panBy(0, 0.25 * h));
			}
		}
	}, [map, hasSlideMedia, viewportSize?.isDesktop]);

	const [funFactMediaLoaded, setFunFactMediaLoaded] = useState(false);
	useEffect(() => {
		if (!showFunFact) {
			setFunFactMediaLoaded(false);
		} else {
			const timeout = setTimeout(() => setFunFactMediaLoaded(true), FUN_FACT_MEDIA_LOAD_TIMEOUT);
			return () => void clearTimeout(timeout);
		}
	}, [showFunFact]);
	const onLoadFunFactMedia = useCallback(() => void setFunFactMediaLoaded(true), []);

	const slideMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_YOUTUBE_END_1);
	const funFactMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_HIDE_FUN_FACT);

	const { placeCode, placeType } = useMemo(() => deserialize(slide?.answers).target || {}, [slide?.answers]);

	useEffect(() => {
		if (
			statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER &&
			statusWithProgress.progress === 1 &&
			submittedAnswer === null &&
			soloMarkerLatLng
		) {
			// auto-submit answer when time's up
			onAnswer(slideIndex, soloMarkerLatLng, null, true);
		}
	}, [onAnswer, slideIndex, soloMarkerLatLng, statusWithProgress.name, statusWithProgress.progress, submittedAnswer]);

	const mobileFragment = (
		<div className="relative flex flex-col justify-between w-full h-full">
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				className={tailwindCascade(
					"relative justify-between flex flex-col items-stretch w-full p-4 gap-4 pointer-events-auto",
					{
						"opacity-0 transition-opacity duration-300":
							(slide.funFact || slide.funFactMedia || (slide.funFact && slide.media)) && showFunFact,
						"bg-black-50": showQuestion,
					}
				)}
				onClick={() => void setMediaSize(MEDIA_SIZE_BIG)}
			>
				<div
					className={tailwindCascade(
						"relative mx-auto pointer-events-auto transition-size duration-200",
						{ hidden: !slide.media },
						{ "w-full": mediaSize === MEDIA_SIZE_BIG, "w-1/6": mediaSize === MEDIA_SIZE_SMALL }
					)}
				>
					{(mountMedia || showMedia) && (
						<MobileMedia
							isPaused={paused}
							media={slide.media}
							visible={showMedia}
							onLoad={onLoad}
							onQuestionStart={onQuestionStart}
							onError={onError}
							onYoutubeClick={() => void setMediaSize(MEDIA_SIZE_BIG)}
							statusWithProgress={statusWithProgress}
							mute={mute}
							envelope={slideMediaVolumeEnvelope}
							progress={waitForAnswerProgress}
							mediaSize={mediaSize}
							interactiveStreetView={interactiveStreetView}
							noAnimation={noAnimation}
						/>
					)}
				</div>
				<MobileQuestion
					isHost={isHost}
					progress={questionProgress}
					question={slide.question}
					questionVoice={slide.questionVoice}
					slideId={slide.id}
					slideType={slide.type}
					visible={showQuestion}
					readQuestion={readQuestion}
				/>
				<ProgressIndicator
					className="px-4"
					visible={showProgressBar}
					progress={waitForAnswerProgress}
					submittedAnswerProgress={submittedAnswerProgress}
					showPoints={[PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE].includes(placeType)}
					paused={paused}
					doublePoints={doublePoints}
				/>
			</div>
			{!showPlaceName && (
				<div className="self-center max-w-xs p-4">
					<SubmitButton
						submittedAnswer={submittedAnswer}
						soloMarkerLatLng={soloMarkerLatLng}
						showHint={
							statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && mediaSize === MEDIA_SIZE_BIG
						}
						onAnswer={onAnswer}
						slideIndex={slideIndex}
						paused={paused}
					/>
				</div>
			)}
			{showPlaceName && (
				<div
					className={tailwindCascade(
						"absolute left-1/2 bottom-4 -translate-x-1/2",
						"bg-green-light text-white font-bold",
						"border-green-dark border-solid border-3",
						"leading-none",
						"px-4 py-2 rounded-full",
						"flex flex-row gap-1 items-center"
					)}
				>
					<CorrectIcon className="h-4" />
					<p className="whitespace-nowrap">{placeName}</p>
				</div>
			)}
			<div
				className={tailwindCascade(
					"absolute inset-0",
					"justify-center flex flex-col items-stretch gap-4",
					"transition-opacity opacity-0 duration-300 pointer-events-none",
					{
						"opacity-100": (slide.funFact || slide.funFactMedia) && showFunFact,
						"bg-black-50": showFunFact && slide.funFact && !slide.funFactMedia,
					}
				)}
			>
				{showFunFact && (
					<MobileMedia
						className={"p-4"}
						isPaused={paused}
						media={slide.funFactMedia}
						visible={true}
						statusWithProgress={statusWithProgress}
						dimMedia={true}
						mute={mute}
						envelope={funFactMediaVolumeEnvelope}
						interactiveStreetView={interactiveStreetView}
					/>
				)}
				{slide.funFact && (
					<div className="absolute w-full p-4">
						<Header className="relative w-full p-4 text-xl text-center">{slide.funFact}</Header>
					</div>
				)}
			</div>
		</div>
	);

	const desktopFragment = (
		<div className="flex flex-col items-stretch w-full h-full mt-4">
			<div
				className={tailwindCascade("relative flex-grow w-full", {
					"w-1/2": hasSlideMedia && (showQuestion || showMedia || showProgressBar),
				})}
			>
				<div
					className={tailwindCascade("flex flex-col items-center justify-center w-full gap-4 absolute", {
						"opacity-0": !showQuestion && !showMedia && !showProgressBar,
					})}
				>
					{hasQuestion && (
						<div
							className="relative w-full h-auto max-w-5xl mx-auto"
							style={{ width: `${slideMediaWidth}px` }}
						>
							<SlideHeader
								maxHeight={hasSlideMedia ? QUESTION_MAX_HEIGHT : null}
								progress={questionProgress}
								visible={showQuestion}
							>
								{slide.question}
							</SlideHeader>
						</div>
					)}

					{hasSlideMedia && (
						<div
							className={tailwindCascade("relative w-full overflow-hidden bg-black rounded-l", {
								"pointer-events-auto": slideMedia?.type === "street",
								"opacity-0 transition-opacity duration-300": !showMedia,
								"animate-media": showMedia && !noAnimation,
								"pointer-events-none": !showMedia,
								"sr-only": !showMedia,
							})}
							style={{
								width: `${slideMediaWidth}px`,
								height: `${slideMediaHeight}px`,
								border: "0.25rem solid #000",
								borderRadius: slideMediaIsYoutube && isSafari ? "0" : "1.5rem",
							}}
						>
							{slideMedia && (mountMedia || showMedia) && (
								<SlideMedia
									ref={slideMediaElementRef}
									paused={paused}
									onLoad={onLoad}
									onError={onError}
									{...slideMedia}
									mute={mute}
									setIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
									envelope={slideMediaVolumeEnvelope}
									progress={waitForAnswerProgress}
									visible={showMedia}
									interactiveStreetView={interactiveStreetView}
								/>
							)}
						</div>
					)}
					<div className="flex flex-col items-center">
						<div
							className={tailwindCascade("pointer-events-auto", {
								"pointer-events-none":
									statusWithProgress.name !== PLAY_STATUS_WAIT_FOR_ANSWER && !soloMarkerLatLng,
							})}
							style={{ width: `${slideMediaWidth}px` }}
						>
							<ProgressIndicator
								progress={waitForAnswerProgress}
								className="w-full h-6"
								paused={paused}
								showPoints={[PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE].includes(placeType)}
								submittedAnswerProgress={submittedAnswerProgress}
								visible={showProgressBar}
								doublePoints={doublePoints}
							/>
						</div>
						<div
							className={tailwindCascade("p-4 pointer-events-auto rounded-full", {
								"pointer-events-none":
									statusWithProgress.name !== PLAY_STATUS_WAIT_FOR_ANSWER && !soloMarkerLatLng,
							})}
						>
							<SubmitButton
								submittedAnswer={submittedAnswer}
								soloMarkerLatLng={soloMarkerLatLng}
								showHint={statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER}
								onAnswer={onAnswer}
								slideIndex={slideIndex}
								paused={paused}
							/>
						</div>
					</div>
				</div>
				<div
					className={tailwindCascade(
						"flex flex-col items-center justify-center w-full gap-8 absolute h-full",
						{
							"opacity-0 transition-opacity duration-300": !(showFunFact && funFactMediaLoaded),
							"animate-media": showFunFact && funFactMediaLoaded,
						}
					)}
				>
					{hasFunFactMedia ? (
						<div
							ref={funFactMediaContainerRef}
							className="relative w-full overflow-hidden bg-black rounded-lg"
							style={{
								width: `${funFactMediaWidth}px`,
								height: `${funFactMediaHeight}px`,
								border: "0.25rem solid #000",
								borderRadius: funFactMediaIsYoutube && isSafari ? "0" : "1.5rem",
							}}
						>
							{showFunFact && (
								<FunFactMedia
									ref={funFactMediaElementRef}
									paused={paused}
									onLoad={onLoadFunFactMedia}
									onError={onLoadFunFactMedia}
									{...funFactMedia}
									setIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
									envelope={funFactMediaVolumeEnvelope}
								/>
							)}
							{hasFunFactText && (
								<div
									className={tailwindCascade(
										"absolute inset-0 z-20 flex flex-col justify-center w-full h-full p-8 text-center bg-black bg-opacity-50",
										{
											"opacity-0 transition-opacity duration-300": !(
												showFunFact && funFactMediaLoaded
											),
										}
									)}
								>
									<SlideHeader
										className="flex flex-col justify-center"
										maxHeight={FUNFACT_MAX_HEIGHT}
										maxFontSize={2.25}
									>
										{slide.funFact}
									</SlideHeader>
								</div>
							)}
						</div>
					) : (
						hasFunFactText && (
							<SlideHeader
								className="flex flex-col justify-center px-[128px]"
								maxHeight={FUNFACT_MAX_HEIGHT}
								maxFontSize={2.25}
							>
								{slide.funFact}
							</SlideHeader>
						)
					)}
				</div>
			</div>

			<div
				className={tailwindCascade({
					"opacity-0": !showQuestion && !showMedia && !showProgressBar,
				})}
			>
				<SlidePlayers
					status={statusWithProgress.name}
					slideType={SLIDE_TYPE_LOCATION}
					slideIndex={slideIndex}
					players={players}
					allowLocalJoin={isHost}
					haveLocalPlayer={haveLocalPlayer}
					onLocalJoin={onLocalJoin}
					teams={teams}
					teamMode={teamMode}
				/>
			</div>
		</div>
	);

	const correctAnswerFeature = useGeoFeature({ placeCode, placeType });
	const correctAnswerName = useMemo(() => {
		const voice = getVoice(slide?.answerVoice, voiceOverride);
		const voiceLang = VOICES.find(({ name }) => name === voice)?.lang?.substring(0, 2);

		return correctAnswerFeature?.properties.names[voiceLang] || null;
	}, [correctAnswerFeature?.properties.names, slide?.answerVoice, voiceOverride]);

	return (
		<>
			{hasVoice && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={showQuestion}
					savedVoice={slide.questionVoice}
					text={slide.question}
					voiceOverride={voiceOverride}
				/>
			)}
			{hasAnswerVoice && correctAnswerName && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={showCorrectAnswers}
					savedVoice={slide.answerVoice}
					voiceOverride={voiceOverride}
					text={correctAnswerName}
				/>
			)}

			{!viewportSize ? (
				<></>
			) : viewportSize.isDesktop ? (
				<div
					className={tailwindCascade(
						"absolute inset-0 bg-black bg-opacity-0 duration-300 pointer-events-none",
						{
							"bg-opacity-50 transition-[background]": showFunFact && !hasFunFactMedia && hasFunFactText,
						}
					)}
				>
					<ScaleContainer
						ref={ref}
						marginBottom={true}
						className={tailwindCascade("pointer-events-none", className)}
					>
						{desktopFragment}
					</ScaleContainer>
				</div>
			) : (
				<div className={tailwindCascade("absolute w-full h-full pointer-events-none", className)}>
					{mobileFragment}
				</div>
			)}
		</>
	);
}

function SubmitButton({ submittedAnswer, soloMarkerLatLng, showHint, onAnswer, slideIndex, paused }) {
	return submittedAnswer !== null ? (
		<div
			className={tailwindCascade(
				"bg-black-50 w-auto px-8 py-4 font-black text-white",
				"transition-opacity duration-300"
			)}
			style={{ borderRadius: BUTTON_BORDER_RADIUS }}
		>
			<Header className="md:text-2xl text-lg">{trans("Answer submitted")}</Header>
		</div>
	) : !soloMarkerLatLng && showHint ? (
		<div
			className={tailwindCascade("bg-pink w-auto px-6 py-2 mb-2 font-black text-white")}
			style={{ borderRadius: BUTTON_BORDER_RADIUS }}
		>
			<span className="md:text-lg text-sm">{trans("Click on the map to set a location")}</span>
		</div>
	) : (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div
			className={tailwindCascade("p-0 pointer-events-auto", {
				"opacity-0 disabled:opacity-0 pointer-events-none":
					soloMarkerLatLng === null || submittedAnswer !== null,
			})}
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<Button
				className={tailwindCascade("w-auto", "md:text-2xl text-white", "transition-opacity duration-300")}
				color="green-light"
				border={true}
				onClick={() => {
					if (onAnswer) {
						onAnswer(slideIndex, soloMarkerLatLng);
					}
				}}
				disabled={paused || soloMarkerLatLng === null}
			>
				{trans("Submit answer")}
			</Button>
		</div>
	);
}
