import { use, useEffect, useRef } from "react";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import useBrowser from "@/hooks/useBrowser";

import { PLAY_STATUS_WAIT_FOR_MEDIA, PLAY_STATUS_YOUTUBE_END_1 } from "@/constants";

import SlideMedia from "./SlideMedia";
import { MEDIA_SIZE_BIG } from "./slides/Location";

export default function MobileMedia({
	className,
	visible,
	media,
	mute,
	isPaused,
	onLoad,
	onQuestionStart,
	onYoutubeEnd,
	onYoutubeClick,
	onError,
	statusWithProgress,
	startEvent = PLAY_STATUS_WAIT_FOR_MEDIA,
	stopEvents = [PLAY_STATUS_YOUTUBE_END_1],
	dimMedia = false,
	progress,
	setIsPlayingWithSound,
	envelope,
	mediaSize,
	interactiveStreetView,
	noAnimation,
}) {
	const ref = useRef(null);

	const browser = useBrowser();

	const onQuestionStartRef = useRef(onQuestionStart);
	useEffect(() => void (onQuestionStartRef.current = onQuestionStart), [onQuestionStart]);

	const mediaBySlideRef = useRef(media);
	useEffect(() => void (mediaBySlideRef.current = media), [media]);

	const startEventRef = useRef(startEvent);
	useEffect(() => void (startEventRef.current = startEvent), [startEvent]);

	useEffect(() => {
		if (statusWithProgress.name === startEventRef.current) {
			if (ref.current) {
				let canceled = false;
				ref.current
					.play()
					.then(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					})
					.catch(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					});
				return () => void (canceled = true);
			}
		}
	}, [statusWithProgress.name]);

	if (media) {
		return (
			<div
				className={tailwindCascade(
					"relative w-full h-full",
					{
						"opacity-0 transition-opacity duration-500": !visible,
						"animate-media": visible && !noAnimation,
						"pointer-events-none": !visible,
						"sr-only": !visible,
					},
					className
				)}
			>
				<div
					className={tailwindCascade("relative mx-auto h-0 pb-4/3 top-1/2 transform -translate-y-1/2", {
						"pb-16/9": media.type === "youtube",
					})}
				>
					<div className="absolute inset-0">
						<div
							className={tailwindCascade(
								"relative overflow-hidden",
								"w-full h-full ",
								{
									"bg-black border-black border-2": media.type !== "placeholder",
								},
								{
									"rounded-md": !(
										media.type === "youtube" &&
										browser &&
										browser.satisfies({ safari: ">=1" })
									),
								}
							)}
						>
							<SlideMedia
								ref={ref}
								paused={isPaused}
								dimMedia={dimMedia}
								{...media}
								showYoutubeLogo={false}
								onLoad={onLoad}
								onYoutubeEnd={onYoutubeEnd}
								onYoutubeClick={onYoutubeClick}
								onError={onError}
								progress={progress}
								mute={mute}
								setIsPlayingWithSound={setIsPlayingWithSound}
								envelope={envelope}
								visible={visible}
								mediaSize={mediaSize}
								interactiveStreetView={interactiveStreetView}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return null;
}
