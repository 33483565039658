import { memo, useMemo } from "react";
import isEqual from "lodash/isEqual";
import TextToSpeech from "./TextToSpeech";

function TextVoice({ savedVoice, text, isHost, play, voiceOverride, onEnd, onPlayError }) {
	const voice = useMemo(() => getVoice(savedVoice, voiceOverride), [savedVoice, voiceOverride]);

	return (
		<TextToSpeech text={text} voice={voice} isHost={isHost} play={play} onEnd={onEnd} onPlayError={onPlayError} />
	);
}

export default memo(TextVoice, isEqual);

export function getVoice(savedVoice, voiceOverride) {
	switch (voiceOverride) {
		case "Default":
			return savedVoice;
		case "Mute":
			return null;
		default:
			return voiceOverride;
	}
}
