import React, { useEffect, useState, useRef } from "react";
import { createCustomEqual } from "fast-equals";
import { GOOGLE_MAPS_MAP_IDS, MAP_TYPE_REGULAR } from "@/constants";
import { useListener } from "@/hooks/useListener";

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
	if (a instanceof window.google.maps.LatLng || b instanceof window.google.maps.LatLng) {
		return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
	}

	// use fast-equals for other objects
	return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
	const ref = useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default function Map({
	onBoundsChanged,
	onClick,
	onIdle,
	onMapLoaded,
	onTilesLoaded,
	children,
	style,
	mapType,
	onInteraction,
	onMapCapabilitiesChanged,
	onMouseMove,
	onMouseOut,
	onRenderingTypeChanged,
	streetView,
	onStreetPosChanged,
	onStreetPovChanged,
	onStreetVisibleChanged,
	onStreetStatusChanged,
	keyboardShortcuts = false,
	...options
}) {
	const ref = useRef(null);
	const [map, setMap] = useState(null);

	const onTilesLoadedRef = useRef(null);
	useEffect(() => void (onTilesLoadedRef.current = onTilesLoaded), [onTilesLoaded]);

	useEffect(() => {
		if (ref.current) {
			const { id, typeId } = GOOGLE_MAPS_MAP_IDS[mapType || MAP_TYPE_REGULAR];

			const args = {};
			if (id) {
				args.mapId = id;
			} else if (typeId) {
				args.mapTypeId = typeId;
			}

			const newMap = new window.google.maps.Map(ref.current, args);

			const listener = window.google.maps.event.addListenerOnce(newMap, "tilesloaded", () => {
				if (onTilesLoadedRef.current) {
					onTilesLoadedRef.current();
				}
			});

			setMap(newMap);

			return () => {
				listener.remove();
			};
		}
	}, [mapType, ref]);

	useEffect(() => {
		if (map && onMapLoaded) {
			onMapLoaded(map);
		}
	}, [map, onMapLoaded]);

	// because React does not do deep comparisons, a custom hook is used
	// see discussion in https://github.com/googlemaps/js-samples/issues/946
	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions({ ...options, keyboardShortcuts });
			/*
			map.moveCamera({
				zoom: options.zoom,
			});
			*/
		}
	}, [map, options, keyboardShortcuts]);

	// useEffect(() => {
	// 	if (map) {
	// 		map.setStreetView(streetView || null);
	// 	}
	// }, [map, streetView]);

	// const streetView = useMemo(() => (map ? map.getStreetView() : null), [map]);
	useListener(streetView, "position_changed", onStreetPosChanged);
	useListener(streetView, "visible_changed", onStreetVisibleChanged);
	useListener(streetView, "pov_changed", onStreetPovChanged);
	useListener(streetView, "status_changed", onStreetStatusChanged);

	useListener(map, "renderingtype_changed", onRenderingTypeChanged);
	useListener(map, "mapcapabilities_changed", onMapCapabilitiesChanged);

	useListener(map, "mousemove", onMouseMove);
	useListener(map, "mouseout", onMouseOut);

	useListener(map, "idle", onIdle);
	useListener(map, "click", onClick);
	useListener(map, "bounds_changed", onBoundsChanged);

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				ref={ref}
				style={style}
				onMouseDown={onInteraction}
				onWheel={onInteraction}
				onTouchStart={onInteraction}
			/>
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
}
