import React, { useEffect, useRef, memo } from "react";

import isEqual from "lodash/isEqual";

import createCustomOverlay from "@/helpers/createCustomOverlay";

import redArrowSVG from "!!raw-loader!@/images/maps/arrow.svg";
import greenArrowSVG from "!!raw-loader!@/images/maps/arrow-green.svg";

const EARTHS_RADIUS = 6371;
const MIN_SIZE = 64;
const MAX_SIZE = 874;

export default memo(function Arrow({ map, center, radius, zIndex = 0, greenArrow = false }) {
	const customOverlayRef = useRef(null);

	useEffect(() => {
		if (center && center.lat && center.lng) {
			const earthsRadius = EARTHS_RADIUS * 1000; /// Radius of the Earth in m
			const rlat = (radius / earthsRadius) * (180 / Math.PI);
			const rlng = rlat / Math.cos(center.lat * (Math.PI / 180));

			const bounds = new window.google.maps.LatLngBounds(
				new window.google.maps.LatLng(center.lat - rlat, center.lng - rlng),
				new window.google.maps.LatLng(center.lat + rlat, center.lng + rlng)
			);

			customOverlayRef.current = createCustomOverlay(
				map,
				bounds,
				center,
				() => {
					const div = document.createElement("div");
					const style = div.style;
					style.borderStyle = "none";
					style.borderWidth = "0px";
					style.position = "absolute";
					style.zIndex = zIndex;
					div.innerHTML = greenArrow ? greenArrowSVG : redArrowSVG;

					const svg = div.querySelector("svg");
					if (svg) {
						svg.style.width = svg.style.height = "100%";
					}

					return div;
				},
				MIN_SIZE,
				MAX_SIZE
			);
			customOverlayRef.current.setMap(map);

			return () => {
				if (customOverlayRef.current) {
					customOverlayRef.current.setMap(null);
					customOverlayRef.current = null;
				}
			};
		}
	}, [map, center, radius, zIndex, greenArrow]);

	return null;
}, isEqual);
