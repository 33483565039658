import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

import { Power1 } from "gsap";

import gsap from "@/helpers/gsap";
import { calculateGoogleMapZoom, haversineDistance } from "@/helpers/map";
import { deserialize } from "@/helpers/map";

import Dartboard from "@/components/interactives/maps/Dartboard";
import DartboardPoints from "@/components/interactives/maps/DartboardPoints";
import Arrow from "@/components/interactives/maps/Arrow";
import Map from "@/components/interactives/maps/Map";
import Avatar from "@/components/interactives/maps/Avatar";
import Marker from "@/components/interactives/maps/Marker";
import LocationSlide from "@/components/pages/play/slides/LocationSlide";
import useStatusSequence from "@/hooks/useStatusSequence";
import useGeoFeature from "@/hooks/useGeoFeature";
import ContinueButton from "@/components/interactives/ContinueButton";

import { FeaturePoly } from "@/components/pages/edit/LocationSlideEditor";
import { getGeoFeature } from "@/helpers/geo";

import { SLIDE_TYPE_LOCATION } from "@/app-constants.mjs";
import {
	GOOGLE_MAPS_API_KEY,
	GOOGLE_MAPS_API_VERSION,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_SHOW_MAP_PIN,
	PLAY_STATUS_ZOOM_MAP_PIN,
	PLAY_STATUS_BROWSE_MAP,
	GOOGLE_MAPS_LIBRARIES,
	PLACE_TYPE_COUNTRY,
	PLACE_TYPE_STATE,
	MAP_TYPE_SATELLITE,
	MAP_TYPE_HYBRID,
	PLAYING_HOST_CONNECTION_ID,
} from "@/constants";
import { BLACK, GREEN_LIGHT, YELLOW, GREEN_LIGHTER, WHITE } from "@/colors";
import { getConnectedPlayers } from "@/helpers/player";
import useRefMounted from "@/hooks/useRefMounted";
import { tailwindCascade } from "@/helpers/tailwindCascade";

const MIN_ZOOM = 2;
const DISPLAY_ZOOM = 3;

export const MEDIA_SIZE_SMALL = "small";
export const MEDIA_SIZE_BIG = "big";

// The map doesn't start at 90 degrees, but rather the degree that makes the map square. This is can be simply calaculated as: atan(sinh(PI)) * 180 / PI = 85.05112878....
const MAP_RESTRICTION = {
	latLngBounds: {
		north: (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI,
		south: -((Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI),
		east: 180,
		west: -180,
	},
};

export default function Location({
	children,
	countryCode,
	doublePoints,
	correctAnswerFeature,
	isPaused: paused,
	mute,
	onComplete,
	onLoad,
	onAnswer = null,
	onQuestionStart,
	onError,
	onLocationContinue, // host only
	placeType,
	player,
	players,
	setFunFactMediaIsPlayingWithSound,
	setPaused,
	setSlideMediaIsPlayingWithSound,
	slide,
	slideIndex,
	statusWithProgress,
	showAnswersLocation,
	submittedAnswer,
	submittedAnswerProgress,
	updateDuration, // host only
	voiceOverride,
	teams,
	...props
}) {
	const [, mountedRef] = useRefMounted();

	const { getVisibility, getProgress } = useStatusSequence(SLIDE_TYPE_LOCATION, statusWithProgress);

	const [answer, setAnswer] = useState(null);
	const onAnswerInternal = useCallback((answerSlideIndex, answer, callback, unsubmitted) => {
		setAnswer({ answerSlideIndex, answer, unsubmitted });
	}, []);

	const onAnswerRef = useRef(onAnswer);
	useEffect(() => void (onAnswerRef.current = onAnswer), [onAnswer]);

	useEffect(() => {
		if (answer && statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && !submittedAnswer) {
			if (onAnswerRef.current) {
				onAnswerRef.current(answer.answerSlideIndex, answer.answer, null, answer.unsubmitted);
			}
		}
	}, [answer, statusWithProgress.name, submittedAnswer]);

	const showQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);
	const questionProgress = useMemo(() => getProgress(PLAY_STATUS_SHOW_QUESTION), [getProgress]);

	const hasFunFactText = slide && slide.funFact;
	const hasFunFactMedia = slide && slide.funFactMedia;

	const mountMedia = useMemo(
		() =>
			getVisibility(
				PLAY_STATUS_LOAD_SLIDE,
				hasFunFactMedia ? PLAY_STATUS_SHOW_FUN_FACT : PLAY_STATUS_HIDE_FUN_FACT
			),
		[getVisibility, hasFunFactMedia]
	);

	const showMedia = useMemo(() => getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_HIDE_SLIDE), [getVisibility]);

	const showFunFact = useMemo(
		() =>
			(hasFunFactText || hasFunFactMedia) && getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility, hasFunFactText, hasFunFactMedia]
	);

	const showProgressBar = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);
	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const waitForAnswer = getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_ALL_ANSWERS_RECEIVED);

	const showMapPin = useMemo(() => getVisibility(PLAY_STATUS_SHOW_MAP_PIN), [getVisibility]);

	const showPlayerMarkers = useMemo(() => getVisibility(PLAY_STATUS_ZOOM_MAP_PIN), [getVisibility]);

	const [map, setMap] = useState(null);
	const mapRef = useRef();

	const showDartboardPoints = useMemo(() => getVisibility(PLAY_STATUS_BROWSE_MAP), [getVisibility]);

	const [markerLatLng, setMarkerLatLng] = useState(null);
	const markerLatLngRef = useRef(markerLatLng);
	useEffect(() => void (markerLatLngRef.current = markerLatLng), [markerLatLng]);

	const [soloMarkerLatLng, setSoloMarkerLatLng] = useState(null);

	const [initialZoom, setInitialZoom] = useState(DISPLAY_ZOOM);
	const initialZoomRef = useRef(initialZoom);
	useEffect(() => void (initialZoomRef.current = initialZoom), [initialZoom]);

	const [center, setCenter] = useState({
		lat: 0,
		lng: 0,
	}); // Updated on map idle
	const centerRef = useRef(center);
	useEffect(() => void (centerRef.current = center), [center]);

	const [zoom, setZoom] = useState(DISPLAY_ZOOM); // Updated on map idle
	const zoomRef = useRef(zoom);
	useEffect(() => void (zoomRef.current = zoom), [zoom]);

	const progressRef = useRef(statusWithProgress.progress);
	useEffect(() => void (progressRef.current = statusWithProgress.progress), [statusWithProgress.progress]);

	const [mapType, setMapType] = useState(null);
	const [distance, setDistance] = useState(0);
	const [placeName, setPlaceName] = useState(null);

	const [manualOverride, setManualOverride] = useState(false);
	const showContinueButton = manualOverride || statusWithProgress.name === PLAY_STATUS_BROWSE_MAP;
	const continueButtonProgress = useMemo(() => getProgress(PLAY_STATUS_BROWSE_MAP), [getProgress]);

	const [selectedFeature, setSelectedFeature] = useState(null);

	const targetSize = useMemo(() => {
		if (distance) {
			return distance * 2;
		} else if (correctAnswerFeature) {
			const [west, south, east, north] = correctAnswerFeature.bbox;
			const diagonal = haversineDistance({ lat: south, lng: west }, { lat: north, lng: east });
			return diagonal;
		} else {
			return undefined;
		}
	}, [distance, correctAnswerFeature]);
	const targetSizeRef = useRef(targetSize);
	useEffect(() => void (targetSizeRef.current = targetSize), [targetSize]);

	useEffect(() => {
		if (manualOverride || paused) {
			return;
		}

		if (
			statusWithProgress.name === PLAY_STATUS_SHOW_MAP_PIN ||
			statusWithProgress.name === PLAY_STATUS_ZOOM_MAP_PIN
		) {
			if (markerLatLngRef.current && centerRef.current && mapRef.current && targetSizeRef.current) {
				const target = {
					lat: centerRef.current.lat,
					lng: centerRef.current.lng,
					zoom: zoomRef.current,
				};

				if (Math.abs(target.lng - markerLatLngRef.current.lng) > 180) {
					// Always take the shortest route
					if (target.lng < markerLatLngRef.current.lng) {
						target.lng = target.lng + 360;
					} else {
						target.lng = target.lng - 360;
					}
				}

				const tweenVariables = {
					lat: markerLatLngRef.current.lat,
					lng: markerLatLngRef.current.lng,
					zoom: 1,
					duration: statusWithProgress.duration * (1 - progressRef.current),
					ease: Power1.easeOut,
					onUpdate: () => {
						mapRef.current.moveCamera({
							center: { lat: target.lat, lng: target.lng },
							zoom: target.zoom,
						});
					},
				};

				if (statusWithProgress.name === PLAY_STATUS_SHOW_MAP_PIN) {
					tweenVariables.zoom = Math.round(
						0.5 *
							(initialZoomRef.current +
								calculateGoogleMapZoom(
									mapRef.current.getDiv(),
									markerLatLngRef.current.lat,
									targetSizeRef.current / 2
								))
					);
				} else if (statusWithProgress.name === PLAY_STATUS_ZOOM_MAP_PIN) {
					tweenVariables.zoom = Math.round(
						calculateGoogleMapZoom(
							mapRef.current.getDiv(),
							markerLatLngRef.current.lat,
							targetSizeRef.current / 2
						)
					);
				}

				const tween = gsap.to(target, tweenVariables);
				return () => void tween.kill();
			}
		}
	}, [statusWithProgress.name, statusWithProgress.duration, manualOverride, paused]);

	const onMapLoaded = useCallback((map) => {
		mapRef.current = map;
		setMap(map);
	}, []);

	useEffect(() => {
		if (updateDuration && statusWithProgress.name === PLAY_STATUS_BROWSE_MAP) {
			setTimeout(() => {
				if (map && window?.google?.maps) {
					window.google.maps.event.addListenerOnce(map, "bounds_changed", () => {
						updateDuration(Infinity);
					});
				}
			}, 100);
		}
	}, [statusWithProgress.name, map, updateDuration]);

	useEffect(() => {
		function setPositionFromOptions() {
			const options = slide.answers;
			if (options && options.length >= 2) {
				try {
					const { target, view } = deserialize(options);

					getGeoFeature({ placeCode: target?.placeCode, placeType: target?.placeType })
						.then((feature) => {
							if (feature) {
								const [targetLng, targetLat] = feature.centroid;
								setMarkerLatLng({ lat: targetLat, lng: targetLng });
								setDistance(undefined);
								setPlaceName(feature?.properties?.name);
							} else {
								const { lat: targetLat, lng: targetLng, radius: targetRadius } = target;
								setMarkerLatLng({ lat: targetLat, lng: targetLng });
								setDistance(targetRadius);
							}

							const { center: mapCenter, type: mapType, zoom: mapZoom } = view;

							setCenter(mapCenter);
							setMapType(mapType);
							setInitialZoom(Math.max(mapZoom, DISPLAY_ZOOM));
							setZoom(Math.max(mapZoom, DISPLAY_ZOOM));
						})
						.catch(() => {});
				} catch (error) {
					//
				}
			}
		}

		if (slide) {
			setPositionFromOptions();
		}
	}, [setCenter, setInitialZoom, setZoom, slide]);

	const [mediaSize, setMediaSize] = useState(MEDIA_SIZE_BIG);
	useEffect(() => void setMediaSize(MEDIA_SIZE_BIG), [slide?.id]);

	const onInteraction = useMemo(() => {
		if (!manualOverride && [PLAY_STATUS_SHOW_MAP_PIN, PLAY_STATUS_ZOOM_MAP_PIN].includes(statusWithProgress.name)) {
			return () => void setManualOverride(true);
		} else {
			return () => void setMediaSize(MEDIA_SIZE_SMALL);
		}
	}, [manualOverride, statusWithProgress.name]);

	const [hoverPos, setHoverPos] = useState(null);
	const hoverFeature = useGeoFeature({
		lat: hoverPos?.lat,
		lng: hoverPos?.lng,
		placeType,
		country: countryCode,
		condition: [PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE].includes(placeType) && !submittedAnswer && waitForAnswer,
	});

	const [maskFeature, setMaskFeature] = useState(null);
	useEffect(() => {
		if (countryCode) {
			getGeoFeature({ placeCode: countryCode })
				.then((countryFeature) => {
					if (countryFeature) {
						const coordinates = [
							[
								[
									[-180, 85],
									[0, 85],
									[0, -85],
									[-180, -85],
								],
								[
									[0, 85],
									[180, 85],
									[180, -85],
									[0, -85],
								],
							],
						];

						for (const polys of countryFeature.geometry.coordinates) {
							coordinates.push(polys.map((poly) => [...poly].reverse()));
						}

						const feature = {
							type: "Feature",
							properties: {},
							geometry: { type: "MultiPolygon", coordinates: coordinates },
						};

						setMaskFeature(feature);
					} else {
						setMaskFeature(null);
					}
				})
				.catch(() => void setMaskFeature(null));
		} else {
			return setMaskFeature(null);
		}
	}, [countryCode]);

	const onMapClick = useCallback(
		async (ev) => {
			if (!submittedAnswer && waitForAnswer) {
				const latLng = {
					lat: Number(ev.latLng.lat().toFixed(6)),
					lng: Number(ev.latLng.lng().toFixed(6)),
				};
				if (placeType) {
					const feature = await getGeoFeature({ ...latLng, placeType, country: countryCode });
					if (feature) {
						setSelectedFeature(feature);
						setSoloMarkerLatLng(latLng);
					} else {
						setSelectedFeature(null);
						setSoloMarkerLatLng(null);
					}
				} else {
					setSoloMarkerLatLng(latLng);
				}
			}
		},
		[countryCode, placeType, submittedAnswer, waitForAnswer]
	);

	const showDartboard = showPlayerMarkers;
	const showPlayers = showAnswersLocation || showPlayerMarkers;
	const showDistance = showPlayerMarkers;
	const targetLatLng = markerLatLng;
	const targetRadius = distance;

	const [tilesLoaded, setTilesLoaded] = useState(false);

	const onIdle = useCallback(
		(map) => {
			if (map) {
				if (setZoom) {
					setZoom(map.getZoom());
				}
				if (setCenter) {
					const center = map.getCenter();
					if (center) {
						setCenter(center.toJSON());
					}
				}
			}
		},
		[setCenter, setZoom]
	);

	const answerPlayers = useMemo(
		() => getConnectedPlayers([...players.values()]).filter((player) => player.selectedAnswer),
		[players]
	);

	const setHover = useCallback((ev) => void setHoverPos(ev.latLng.toJSON()), [setHoverPos]);
	const unsetHover = useCallback((ev) => void setHoverPos(null), [setHoverPos]);

	const [flashingColor, setFlashingColor] = useState(undefined);
	useEffect(() => {
		const timeline = gsap.timeline();
		const target = { phase: 0 };
		timeline.to(
			target,
			{
				duration: 4,
				phase: 1,
				ease: "linear",
				onUpdate: () => {
					if (mountedRef.current) {
						const x = 1 - 2 * Math.abs(target.phase - 0.5);

						const rgb1 = [198, 234, 132];
						const rgb2 = [0, 167, 109];

						const rgb = [0, 1, 2].map((i) => Math.floor(x * rgb1[i] + (1 - x) * rgb2[i]));
						setFlashingColor(`rgb(${rgb.join(", ")})`);
					}
				},
				repeat: -1,
			},
			"linear"
		);
		timeline.play();

		return () => void timeline.kill();
	}, [mountedRef]);

	const [pointer, setPointer] = useState(null);
	const onBoundsChanged = useCallback(
		(map) => {
			const bounds = map.getBounds();
			const projection = map.getProjection();

			const absTopRightPt = projection.fromLatLngToPoint(bounds.getNorthEast());
			const absBottomLeftPt = projection.fromLatLngToPoint(bounds.getSouthWest());

			const widthPt = (absTopRightPt.x - absBottomLeftPt.x + 256) % 256;
			const heightPt = absBottomLeftPt.y - absTopRightPt.y;

			const absCenterPt = projection.fromLatLngToPoint(map.getCenter()); // x = 0...255
			const targetPt = projection.fromLatLngToPoint(targetLatLng); // x = 0...255

			targetPt.x = ((targetPt.x - absCenterPt.x - 128 + 512) % 256) - 128;
			targetPt.y = targetPt.y - absCenterPt.y;

			const viewTopPt = -0.5 * heightPt;
			const viewBottomPt = 0.5 * heightPt;
			const viewLeftPt = -0.5 * widthPt;
			const viewRightPt = 0.5 * widthPt;

			const scale = Math.pow(2, map.getZoom());

			let point = null;

			if (
				targetPt.x < viewLeftPt ||
				targetPt.x > viewRightPt ||
				targetPt.y < viewTopPt ||
				targetPt.y > viewBottomPt
			) {
				const paddingPt = 80 / scale; // 80px from edge of view to center of pointer
				const topPt = viewTopPt + paddingPt;
				const bottomPt = viewBottomPt - paddingPt;
				const rightPt = viewRightPt - paddingPt;
				const leftPt = viewLeftPt + paddingPt;

				let north, east, south, west;

				if (targetPt.y < topPt) {
					const y = topPt;
					const x = (y / targetPt.y) * targetPt.x;
					south = { x, y };
				}

				if (targetPt.y > bottomPt) {
					const y = bottomPt;
					const x = (y / targetPt.y) * targetPt.x;
					north = { x, y };
				}

				if (targetPt.x > rightPt) {
					const x = rightPt;
					const y = (x / targetPt.x) * targetPt.y;
					east = { x, y };
				}

				if (targetPt.x < leftPt) {
					const x = leftPt;
					const y = (x / targetPt.x) * targetPt.y;
					west = { x, y };
				}

				if (north) {
					if (east && north.x > rightPt) {
						point = east;
					} else if (west && north.x < leftPt) {
						point = west;
					} else {
						point = north;
					}
				} else if (south) {
					if (east && south.x > rightPt) {
						point = east;
					} else if (west && south.x < leftPt) {
						point = west;
					} else {
						point = south;
					}
				} else if (east) {
					point = east;
				} else if (west) {
					point = west;
				}
			}

			if (point) {
				setPointer({
					y: (0.5 * heightPt + point.y) * scale,
					x: (0.5 * widthPt + point.x) * scale,
					rotation: (Math.atan2(targetPt.y, targetPt.x) * 180) / Math.PI + 90,
				});
			} else {
				setPointer(null);
			}
		},
		[targetLatLng]
	);

	const onClickPointer = useCallback(() => void map.panTo(targetLatLng), [map, targetLatLng]);

	return (
		<div className="flex h-full">
			{showContinueButton && onLocationContinue && (
				<div className="md:container left-1/2 md:top-16 top-14 z-2 absolute flex flex-col items-end transform -translate-x-1/2 pointer-events-none">
					<ContinueButton
						progress={continueButtonProgress}
						duration={statusWithProgress.duration}
						onClick={onLocationContinue}
						paused={paused}
					/>
				</div>
			)}
			{showPlayerMarkers && pointer && (
				<button
					className={tailwindCascade(
						"z-1 absolute w-16 h-16 p-0 -translate-x-1/2 -translate-y-1/2",
						"drop-shadow-dnd-lg transform-gpu"
						// "transition-pos duration-75 ease-linear"
					)}
					style={{
						left: `${pointer.x}px`,
						top: `${pointer.y}px`,
					}}
					onClick={onClickPointer}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="-2.4 -2.4 4.8 4.8"
						width="100%"
						height="100%"
						style={{
							transform: `rotate(${pointer.rotation}deg)`,
						}}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={0.4}
							stroke={BLACK}
							fill={WHITE}
							d="M 0,-2 2,0 1,0 1,2 -1,2 -1,0 -2,0 0,-2"
						></path>
					</svg>
				</button>
			)}
			<Wrapper apiKey={GOOGLE_MAPS_API_KEY} version={GOOGLE_MAPS_API_VERSION} libraries={GOOGLE_MAPS_LIBRARIES}>
				<Map
					center={center}
					gestureHandling={"auto"}
					onClick={onMapClick}
					onMapLoaded={onMapLoaded}
					onMouseMove={setHover}
					onMouseOut={unsetHover}
					onTilesLoaded={() => void setTilesLoaded(true)}
					zoom={zoom}
					minZoom={MIN_ZOOM}
					mapType={mapType}
					disableDefaultUI={true}
					clickableIcons={false}
					onIdle={onIdle}
					onInteraction={onInteraction}
					style={{ flexGrow: "1", height: "100%", visibility: tilesLoaded ? "visible" : "hidden" }}
					zoomControl={true}
					restriction={MAP_RESTRICTION}
					onBoundsChanged={onBoundsChanged}
				>
					{showPlayers &&
						answerPlayers &&
						answerPlayers.map((player) => (
							<Avatar
								key={player.connectionId}
								position={{ lat: player.selectedAnswer.lat, lng: player.selectedAnswer.lng }}
								avatar={teams.get(player.teamId)?.avatar ?? player.avatar}
								distance={showDistance ? player.selectedAnswer.distance : null}
								name={player.name}
								isHost={player.connectionId === PLAYING_HOST_CONNECTION_ID}
							/>
						))}
					<FeaturePoly
						feature={maskFeature}
						strokeColor={BLACK}
						strokeOpacity={0}
						strokeWeight={0}
						fillColor={BLACK}
						fillOpacity={0.6}
						clickable={false}
					/>
					{correctAnswerFeature && showMapPin && (
						<FeaturePoly
							feature={correctAnswerFeature}
							strokeColor={BLACK}
							strokeOpacity={0.7}
							strokeWeight={6 ** (0.2 * zoom)}
							fillColor={GREEN_LIGHTER}
							fillOpacity={0.3}
							zIndex={3}
						/>
					)}
					{correctAnswerFeature && showMapPin && (
						<FeaturePoly
							feature={correctAnswerFeature}
							strokeColor={GREEN_LIGHTER}
							strokeOpacity={1}
							strokeWeight={1.5 ** (0.4 * zoom)}
							zIndex={4}
						/>
					)}
					{selectedFeature && (!showMapPin || correctAnswerFeature !== selectedFeature) && (
						<FeaturePoly
							feature={selectedFeature}
							strokeColor={[MAP_TYPE_SATELLITE, MAP_TYPE_HYBRID].includes(mapType) ? WHITE : BLACK}
							strokeOpacity={0.8}
							strokeWeight={2}
							fillColor={BLACK}
							fillOpacity={0.15}
							zIndex={1}
							clickable={false}
						/>
					)}
					<FeaturePoly
						feature={hoverFeature}
						strokeColor={[MAP_TYPE_SATELLITE, MAP_TYPE_HYBRID].includes(mapType) ? WHITE : BLACK}
						strokeOpacity={0.8}
						strokeWeight={2}
						fillColor={BLACK}
						fillOpacity={0}
						onClick={onMapClick}
						zIndex={2}
						clickable={false}
					/>
					{targetRadius && showDartboard && (
						<Dartboard
							center={targetLatLng}
							radius={targetRadius}
							opacity={showDartboardPoints ? 0.75 : 0.5}
							zIndex={1}
							minSize={64}
						/>
					)}
					{showMapPin && ![PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE].includes(placeType) && (
						<Arrow
							center={targetLatLng}
							radius={targetSize / 2}
							zIndex={40}
							greenArrow={placeType === PLACE_TYPE_COUNTRY || placeType === PLACE_TYPE_STATE}
						/>
					)}
					{targetRadius && showDartboardPoints && (
						<DartboardPoints center={targetLatLng} radius={targetRadius} zIndex={3} double={doublePoints} />
					)}
					{targetRadius && soloMarkerLatLng && !showPlayers && <Marker position={soloMarkerLatLng} />}
				</Map>
				<LocationSlide
					doublePoints={doublePoints}
					map={map}
					mediaSize={mediaSize}
					mute={mute}
					onAnswer={onAnswerInternal}
					onComplete={onComplete}
					onLoad={onLoad}
					onQuestionStart={onQuestionStart}
					onError={onError}
					paused={paused}
					players={players}
					setFunFactMediaIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
					setMediaSize={setMediaSize}
					setSlideMediaIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
					soloMarkerLatLng={soloMarkerLatLng}
					slide={slide}
					slideIndex={slideIndex}
					statusWithProgress={statusWithProgress}
					submittedAnswer={submittedAnswer}
					submittedAnswerProgress={submittedAnswerProgress}
					type={SLIDE_TYPE_LOCATION}
					showFunFact={showFunFact}
					showProgressBar={showProgressBar}
					showQuestion={showQuestion}
					questionProgress={questionProgress}
					mountMedia={mountMedia}
					showMedia={showMedia}
					waitForAnswer={waitForAnswer}
					waitForAnswerProgress={waitForAnswerProgress}
					voiceOverride={voiceOverride}
					placeName={placeName}
					showPlaceName={correctAnswerFeature && showMapPin && placeName}
					teams={teams}
					{...props}
				/>
			</Wrapper>
		</div>
	);
}
