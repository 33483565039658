import { useEffect, useState } from "react";
import onWindowResize from "@/helpers/onWindowResize";

export default function useWindowInnerSize() {
	// Interior size of window including scroll-bars (if present)

	const [size, setSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleWindowResize = () => {
			if (typeof window !== "undefined") {
				setSize({ width: window.innerWidth, height: window.innerHeight });
			} else {
				setSize({ width: 0, height: 0 });
			}
		};

		handleWindowResize(); // initial

		return onWindowResize(handleWindowResize);
	}, []);

	return size;
}
