import React, { useState, useCallback, useRef, useEffect, memo } from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Button from "@/components/interactives/Button";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import Input from "@/components/interactives/Input";
import trans from "@/helpers/trans";
import isBoolean from "lodash/isBoolean";
import ClearIcon from "@/images/icons/icon-crosscircle.svg";
import { MAX_ANSWER_LENGTH, SLIDE_TYPE_TYPE_ANSWER } from "@/app-constants.mjs";
import { sfx, WRONG_ANSWER_SOUNDS } from "@/helpers/audio";
import Header from "@/components/Header";

export default function TypeAnswerFragment({
	answerRef = null,
	answers,
	disabled,
	haveLocalPlayer,
	isClose,
	isCorrect,
	isNumeric = false,
	mute,
	onAnswer = null,
	setValue,
	showCorrectAnswers,
	slideIndex,
	value,
	visible = true,
}) {
	const inputRef = useRef(null);

	const type = isNumeric ? "number" : "search";
	const inputMode = isNumeric ? "numeric" : "search";
	const prefix = isNumeric ? "answer" : "search";

	const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
	const [inputChanged, setInputChanged] = useState(true);
	const [firstGuess, setFirstGuess] = useState(true);

	const lastAnswer = useRef("");

	const isInCorrect = isCorrect !== null && isCorrect === false && isClose !== null && isClose === false;

	useEffect(() => {
		if (inputRef.current) {
			if (haveLocalPlayer && visible && !disabled) {
				inputRef.current.focus();
				// mobile-safari doesnt allow bringing up the keyboard without user interaction so we add this listener
				const focus = () => {
					inputRef.current.focus();
					window.removeEventListener("click", focus);
				};
				window.addEventListener("click", focus);
				return () => void window.removeEventListener("click", focus);
			} else {
				inputRef.current.blur();
			}
		}
	}, [visible, disabled, inputRef, haveLocalPlayer]);

	const onSubmitAnswer = useCallback(() => {
		if (inputRef.current && onAnswer && lastAnswer.current !== inputRef.current.value) {
			lastAnswer.current = inputRef.current.value;
			onAnswer(slideIndex, inputRef.current.value);
			setIsWaitingForResponse(true);
		}
	}, [onAnswer, inputRef, slideIndex]);

	useEffect(() => {
		if (haveLocalPlayer && isWaitingForResponse && isBoolean(isCorrect) && inputRef.current) {
			setIsWaitingForResponse(false);
			if (isCorrect === false && isClose === false) {
				inputRef.current.value = "";
			}
		}
		if (isCorrect === true && inputRef.current) {
			inputRef.current.blur();
		}
	}, [isCorrect, isClose, isWaitingForResponse, inputRef, haveLocalPlayer]);

	useEffect(() => {
		if (haveLocalPlayer && inputRef.current) {
			inputRef.current.value = value;
			setIsWaitingForResponse(false);
		}
	}, [haveLocalPlayer, inputRef, value]);

	useEffect(() => {
		if (!mute) {
			if (isCorrect === true) {
				sfx.play("correctTypedAnswer");
			} else if (isCorrect == false) {
				sfx.play(WRONG_ANSWER_SOUNDS);
			}
		}
	}, [isCorrect, mute]);

	useEffect(() => {
		if (showCorrectAnswers && !mute) {
			sfx.play("correctAnswerReveal");
		}
	}, [showCorrectAnswers, mute]);

	useEffect(() => {
		if (isInCorrect) {
			setFirstGuess(false);
		}
	}, [isInCorrect]);

	return (
		<div className="flex flex-col space-y-3">
			<form
				className={tailwindCascade(
					"flex md:flex-col flex-row gap-4 md:gap-4 justify-between items-center md:items-stretch",
					"w-full md:max-w-sm h-16 md:h-auto max-w-md mx-auto",
					"transition-opacity duration-100",
					{
						"opacity-0 pointer-events-none": !visible,
					}
				)}
				onSubmit={(ev) => {
					ev.preventDefault();
					if (haveLocalPlayer) {
						onSubmitAnswer();
						setInputChanged(false);
						if (inputRef.current) {
							inputRef.current.focus();
						}
					}
				}}
				onReset={(ev) => {
					if (haveLocalPlayer) {
						setValue("");
						setInputChanged(true);
						if (inputRef.current) {
							inputRef.current.focus();
						}
					}
				}}
				action="."
			>
				<div ref={answerRef} className="md:w-full relative flex-1">
					<Input
						className="md:text-coal md:placeholder-coal md:w-full md:text-2xl md:font-bold md:text-center m-0"
						ref={inputRef}
						name={`${prefix}_room`}
						type={type}
						inputMode={inputMode}
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="none"
						spellCheck="false"
						required="required"
						border={true}
						disabled={disabled}
						readOnly={disabled}
						onChange={(ev) => {
							setInputChanged(true);
							setValue(ev.target.value);
						}}
						placeholder={trans("Try something")}
						maxLength={MAX_ANSWER_LENGTH[SLIDE_TYPE_TYPE_ANSWER]}
						value={showCorrectAnswers ? answers[0].text : undefined}
					/>
					{!disabled && value && (
						<button type="reset" className="top-1/2 right-2 absolute transform -translate-y-1/2">
							<ClearIcon className="h-8 opacity-50" />
						</button>
					)}
					{haveLocalPlayer && isCorrect && (
						<div className="top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full">
							<CorrectIcon className="fill-white w-8 h-8 text-black" />
						</div>
					)}
				</div>
				<div className="md:block self-center hidden">
					{isCorrect && <Header>{trans("Correct!")}</Header>}
					{isClose && <Header>{trans("Almost correct")}</Header>}
					{isInCorrect && <Header>{trans("Incorrect")}</Header>}
				</div>

				<div className={`flex-grow-0 ${isCorrect ? "hidden" : ""}`}>
					<Button
						className="md:w-full m:0 md:text-2xl text-white"
						type="submit"
						color="green-light"
						border={true}
						disabled={disabled}
						hoverClassName="group-hover:bg-opacity-40"
					>
						{firstGuess ? trans("Try") : trans("Try again")}
					</Button>
				</div>
			</form>
			<div className="md:hidden self-center block">
				{isCorrect && <Header className="text-lg">{trans("Correct!")}</Header>}
				{isClose && <Header className="text-lg">{trans("Almost correct")}</Header>}
				{isInCorrect && <Header className="text-lg">{trans("Incorrect")}</Header>}
			</div>
		</div>
	);
}
