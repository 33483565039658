import { useEffect } from "react";

export function useListener(obj, eventName, eventHandler) {
	useEffect(() => {
		if (obj && eventHandler) {
			const listener = obj.addListener(eventName, (ev) => void eventHandler(ev || obj));
			return () => void listener.remove();
		}
	}, [obj, eventName, eventHandler]);
}
