import Button from "@/components/interactives/Button";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

export default function ContinueButton({
	className,
	buttonClassName,
	duration,
	onClick,
	paused,
	progress,
	outline = true,
	children,
	...props
}) {
	return (
		<div
			className={tailwindCascade(
				{
					"animate-beat": duration === Infinity,
					"pause-anim": paused,
					"bg-opacity-30 rounded-full bg-black p-2": outline,
				},
				className
			)}
		>
			<Button
				className={tailwindCascade("flex flex-grow-0 text-white", buttonClassName)}
				color="pink"
				border={true}
				progress={duration === Infinity ? 0 : progress}
				onClick={onClick}
			>
				{children || trans("Continue")}
			</Button>
		</div>
	);
}
