import gsap from "@/helpers/gsap";

export default function animationLoop(onUpdate = null) {
	let previousTime = 0;
	const data = { time: 0 };
	const timeline = gsap.timeline({
		repeat: -1,
		onUpdate: () => {
			let delta = 0;
			if (data.time > previousTime) {
				delta = data.time - previousTime;
			} else if (data.time < previousTime) {
				delta = data.time + 1 - previousTime;
			}
			previousTime = data.time;
			if (onUpdate) {
				onUpdate(delta);
			}
		},
	});
	timeline.to(data, { time: 1.0, duration: 1.0, ease: "none" });
	return () => void timeline.kill();
}
