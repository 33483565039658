import { useCallback, useEffect, useRef } from "react";

import useBoostStore from "@/stores/boost";

import { BOOST_STATUS_SUBSCRIBED, BOOST_TYPE_GENERATE_QUIZ } from "@/constants";

export default function useBoostGuard(type = BOOST_TYPE_GENERATE_QUIZ) {
	// const mountedRef = useRef(false);
	// useEffect(() => {
	// 	mountedRef.current = true;
	// 	return () => {
	// 		mountedRef.current = false;
	// 	};
	// }, []);

	const typeRef = useRef(type);
	useEffect(() => void (typeRef.current = type));

	return useCallback((callback) => {
		// const user = useAuthStore.getState().user;

		// const isSubscribed = user?.endDate && new Date(user.endDate) >= new Date();

		// if (mountedRef.current && isSubscribed) {
		// 	callback(BOOST_STATUS_SUBSCRIBED);
		// 	return;
		// }

		useBoostStore.getState().show(typeRef.current, callback);
	}, []);
}
