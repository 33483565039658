import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { sfx } from "@/helpers/audio";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Button from "@/components/interactives/Button";
import CheckboxButton from "@/components/interactives/CheckboxButton";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-multicolor.svg";
import trans from "@/helpers/trans";
import { BUTTON_RADIUS } from "@/components/interactives/Button";
import { BUTTON_MAX_HEIGHT } from "@/components/pages/play/slides/BaseSlide";
import { upperFirst } from "@/helpers/text";
import range from "lodash/range";
import isFinite from "lodash/isFinite";
import useElementSize from "@/hooks/useElementSize";
import { useImmer } from "use-immer";
import sum from "lodash/sum";
import TextVoice, { getVoice } from "../../TextVoice";
import { clearTimeout, setTimeout } from "@/helpers/workerTimers";
import usePlayStore from "@/stores/play";
import useViewportSize from "@/hooks/useViewportSize";
import { PLAY_STATUS_WAIT_FOR_ANSWER } from "@/constants";
import useLayoutStore from "@/stores/layout";
import IconReorder from "@/images/icons/icon-reorder-tight.svg";

export default function ReorderAnswersFragment({
	className,
	haveLocalPlayer,
	isHost,
	language,
	onAnswer = null,
	paused,
	slide,
	slideIndex,
	submittedAnswer,
	showCorrectAnswers,
	statusWithProgress,
	visible = true,
	voiceOverride,
	noAnimation,
}) {
	const answers = useMemo(() => slide?.answers || [], [slide?.answers]);
	const voice = useMemo(() => getVoice(slide.answerVoice, voiceOverride), [slide.answerVoice, voiceOverride]);

	const [haveAnswered, setHaveAnswered] = useState(false);
	const [hasInteracted, setHasInteracted] = useState(false);
	const [isDragging, setIsDragging] = useState(false);
	const [highlightSubmit, setHighlightSubmit] = useState(false);

	const submitButton = useRef(null);

	useEffect(() => {
		setHighlightSubmit(false);
		if (isDragging) {
			setHasInteracted(true);
		} else if (hasInteracted) {
			// 5s after interaction, highlight the submit button
			const timeout = setTimeout(() => void setHighlightSubmit(true), 5000);
			return () => void clearTimeout(timeout);
		}
	}, [hasInteracted, isDragging]);

	useEffect(() => {
		if (highlightSubmit) {
			submitButton.current.scrollIntoView({ behavior: "smooth", inline: "nearest" });
		}
	}, [highlightSubmit]);

	const [positions, setPositions] = useState(answers.map((_answer, index) => index));
	useEffect(() => setPositions(answers.map((_answer, index) => index)), [answers]);
	const positionsRef = useRef(positions);
	useEffect(() => void (positionsRef.current = [...positions]), [positions]);

	useEffect(() => {
		if (
			haveLocalPlayer &&
			statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER &&
			statusWithProgress.progress === 1 &&
			submittedAnswer === null &&
			onAnswer &&
			hasInteracted
		) {
			// auto-submit answer when time's up
			onAnswer(slideIndex, [...positions], null, true);
			setHaveAnswered(true);
		}
	}, [
		hasInteracted,
		haveLocalPlayer,
		onAnswer,
		positions,
		slideIndex,
		statusWithProgress.name,
		statusWithProgress.progress,
		submittedAnswer,
	]);

	const handleSubmit = useCallback(() => {
		if (onAnswer) {
			onAnswer(slideIndex, [...positions]);
		}
		setHaveAnswered(true);
	}, [onAnswer, positions, slideIndex]);

	const pointerEvents = !haveAnswered && !paused;

	const swap = useCallback((a, b) => {
		const newPositions = [...positionsRef.current];

		const i = positionsRef.current.indexOf(a); // what item is at position a?
		const j = positionsRef.current.indexOf(b); // what item is at position b?

		newPositions[i] = positionsRef.current[j];
		newPositions[j] = positionsRef.current[i];
		setPositions(newPositions);
	}, []);

	const { isDesktop } = useViewportSize();

	const [sizes, updateSizes] = useImmer(answers.map(() => 0));
	const setSize = useCallback(
		(i, size) => {
			updateSizes((draft) => {
				draft[i] = size.offsetHeight;
			});
		},
		[updateSizes]
	);

	const centers = useRef([]);

	useEffect(() => {
		const gap = isDesktop ? 18 : 4;

		let y = 0;
		const newCenters = Array(positions.length).fill(0);

		for (const pos of range(positions.length)) {
			const index = positions.indexOf(pos);
			newCenters[pos] = y + 0.5 * sizes[index];

			y = y + sizes[index] + gap;
		}

		centers.current = newCenters;
	}, [isDesktop, positions, sizes]);

	const [revealAnswer, setRevealAnswer] = useState(null);

	useEffect(() => {
		if (revealAnswer !== null && revealAnswer < answers.length) {
			const newPositions = [...positionsRef.current];

			const j = answers.findIndex((ans) => ans.pos === revealAnswer);

			for (let i = 0; i < answers.length; i++) {
				if (positionsRef.current[i] < positionsRef.current[j] && positionsRef.current[i] >= revealAnswer) {
					newPositions[i] = newPositions[i] + 1;
				}
			}
			newPositions[j] = revealAnswer;

			setPositions(newPositions);

			if (!voice || revealAnswer < 0) {
				const timeout = setTimeout(
					() => {
						setRevealAnswer(revealAnswer + 1);
					},
					revealAnswer < 0 ? 1500 : 1000
				);
				return () => void clearTimeout(timeout);
			}
		} else if (isHost && revealAnswer === answers.length) {
			usePlayStore.getState().setPlaylistSkip(true);
		}
	}, [answers, isHost, revealAnswer, voice]);

	useEffect(() => {
		if (showCorrectAnswers) {
			setRevealAnswer(-1);
		}
	}, [showCorrectAnswers]);

	const onVoiceEnd = useCallback(() => {
		setRevealAnswer(revealAnswer + 1);
	}, [revealAnswer]);

	const onVoiceError = useCallback(() => {
		const timeout = setTimeout(() => {
			setRevealAnswer(revealAnswer + 1);
		}, 1000);
		return () => void clearTimeout(timeout);
	}, [revealAnswer]);

	const containerScale = useLayoutStore((state) => state.containerScale);
	const scaleRef = useRef(containerScale);
	useEffect(() => void (scaleRef.current = isDesktop ? containerScale : 1), [containerScale, isDesktop]);

	const dragStartPos = useRef(null);
	const [dragState, setDragState] = useState({ index: null, top: null });
	const [mostRecentlyDragged, setMostRecentlyDragged] = useState(-1);

	const calcPos = useCallback(
		(i) =>
			sum(
				positions
					.filter((j) => j < i)
					.map((j) => positions.indexOf(j))
					.map((j) => sizes[j] + (isDesktop ? 18 : 4))
			),
		[isDesktop, positions, sizes]
	);

	const getMousePos = useCallback((ev) => {
		const x = ev.clientX / scaleRef.current; // - rect.left;
		const y = ev.clientY / scaleRef.current; // - rect.y;
		return { x, y };
	}, []);

	const onDragMove = useCallback(
		(ev) => {
			const { index, center0, mouseY0, top0 } = dragStartPos.current;
			let { y: mouseY } = getMousePos(ev);

			const dy = mouseY - mouseY0;

			setDragState({
				top: top0 + dy,
				index,
			});

			const myPos = positionsRef.current[index];
			const myCenter = center0 + dy;

			if (myPos > 0 && myCenter < centers.current[myPos - 1]) {
				swap(myPos, myPos - 1);
			}

			if (myPos < centers.current.length - 1 && myCenter > centers.current[myPos + 1]) {
				swap(myPos, myPos + 1);
			}
		},
		[dragStartPos, getMousePos, swap]
	);

	const onMouseMove = useCallback(
		(ev) => {
			ev.preventDefault();
			onDragMove(ev);
		},
		[onDragMove]
	);

	const onTouchMove = useCallback(
		(ev) => {
			ev.preventDefault();
			ev.stopPropagation();
			// ev.stopImmediatePropagation();
			if (ev.touches.length === 1) {
				onDragMove(ev.touches[0]);
			}

			return false;
		},
		[onDragMove]
	);

	const onDragEnd = useCallback(
		(ev) => {
			setMostRecentlyDragged(dragStartPos.current.index);
			dragStartPos.current = null;
			setDragState({ index: null, top: null });

			document.removeEventListener("mousemove", onMouseMove);
			document.removeEventListener("mouseup", onDragEnd);
			document.removeEventListener("touchmove", onTouchMove, { passive: false });
			document.removeEventListener("touchend", onDragEnd);

			setIsDragging(false);
		},
		[onMouseMove, onTouchMove]
	);

	const dragStart = useCallback(
		(ev) => {
			const index = parseInt(ev.target.getAttribute("data-index"));
			const pos = parseInt(ev.target.getAttribute("data-pos"));
			const center = centers.current[pos];

			const { x, y } = getMousePos(ev);

			dragStartPos.current = {
				mouseY0: y,
				top0: calcPos(pos),
				center0: center,
				index,
			};

			document.addEventListener("mousemove", onMouseMove);
			document.addEventListener("mouseup", onDragEnd);

			setIsDragging(true);
		},
		[calcPos, getMousePos, onDragEnd, onMouseMove]
	);

	const onMouseDown = useCallback(
		(ev) => {
			ev.preventDefault();
			dragStart(ev);
		},
		[dragStart]
	);

	const onTouchStart = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();
		if (ev.touches.length === 1) {
			document.addEventListener("touchmove", onTouchMove, { passive: false }); // iOS 11 now defaults to passive: true
			document.addEventListener("touchend", onDragEnd);

			dragStart(ev.touches[0]);
		}
	};

	const enableReorder = !showCorrectAnswers && !submittedAnswer;

	useEffect(() => {
		if (isFinite(dragState.index) && !enableReorder) {
			onDragEnd();
		}
	}, [dragState, enableReorder, onDragEnd]);

	return (
		<>
			<div
				className={tailwindCascade(
					"flex flex-col items-center w-full gap-1 md:gap-[18px]",
					{
						"opacity-0 transition-opacity duration-300": !visible,
						// "pointer-events-none": disabled,
					},
					className
				)}
			>
				<div
					className={tailwindCascade("w-full h-[456px] relative flex flex-col", {
						"opacity-0 pointer-events-none": !visible,
					})}
					style={{ height: sum(sizes) + (sizes.length - 1) * (isDesktop ? 18 : 4) }}
				>
					{answers.map((answer, i, answers) => (
						<Item
							key={i}
							className={tailwindCascade(
								"absolute flex w-full transition-transform touch-none transform-gpu",
								{
									"cursor-grab": enableReorder,
									"transition-pos duration-300": dragState.index !== i,
									"duration-500 delay-500": showCorrectAnswers,
									"z-5 scale-105 cursor-grabbing drop-shadow-dnd-lg": dragState.index === i,
									"z-4": mostRecentlyDragged === i,
								}
							)}
							style={{
								top:
									dragState.index === i
										? dragState.top
										: calcPos(positions[i]) -
										  (submittedAnswer !== null && showCorrectAnswers ? 8 : 0),
								left: -(submittedAnswer !== null && showCorrectAnswers ? 8 : 0),
							}}
							setSize={setSize}
							pos={positions[i]}
							index={i}
							id={i}
							moveCard={swap}
							onMouseDown={enableReorder ? onMouseDown : undefined}
							onTouchStart={enableReorder ? onTouchStart : undefined}
						>
							<Button
								borderRadius={BUTTON_RADIUS}
								disabled={true || positions[i] === 0 || showCorrectAnswers || submittedAnswer !== null}
								disabledOpacity={false}
								color={["answer1", "answer2", "answer3", "answer4"][i % answers.length]}
								border={true}
								onMouseEnter={() => {
									if (!submittedAnswer) {
										sfx.play("hoverFeedback", false, 0.75);
									}
								}}
								className={tailwindCascade(
									"flex-grow w-full self-start transition-transform isolate leading-none",
									{
										"animate-media": visible && !noAnimation,
										"scale-110 z-5":
											showCorrectAnswers && revealAnswer !== null && answer.pos === revealAnswer,
										// "z-5 scale-105 cursor-grabbing shadow-updown-6px": dragState.index === i,
									}
								)}
								style={{
									animationDelay: `${((1 - 0.64) / answers.length) * i}s`,
									animationFillMode: "backwards",
								}}
								hoverClassName="group-hover:bg-opacity-40"
								maxHeight={isDesktop ? BUTTON_MAX_HEIGHT : undefined}
								icon={
									<div className="relative w-6 h-6">
										{enableReorder && <IconReorder className="w-full h-full opacity-50" />}
									</div>
								}
								iconAlign="right"
								align="left"
							>
								<div className="md:py-4">{upperFirst(answer.text, language)}</div>
							</Button>
							{submittedAnswer && (
								<>
									<div
										className={tailwindCascade(
											"transition-opacity opacity-0 duration-500",
											"top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full",
											{
												"opacity-100":
													submittedAnswer &&
													showCorrectAnswers &&
													// revealAnswer >= positions[i] &&
													revealAnswer === positions.length &&
													answers.every((ans, i) => ans.pos === submittedAnswer[i]),
											}
										)}
										style={{
											transitionDelay: `${(1 * positions[i]) / answers.length}s`,
										}}
									>
										<CorrectIcon className="fill-white w-8 h-8 text-black" />
									</div>
									<div
										className={tailwindCascade(
											"transition-opacity opacity-0 duration-500",
											"top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full",
											{
												"opacity-100":
													submittedAnswer &&
													showCorrectAnswers &&
													// revealAnswer >= positions[i] &&
													revealAnswer === positions.length &&
													positions[i] !== submittedAnswer[i],
											}
										)}
										style={{
											transitionDelay: `${(1 * positions[i]) / answers.length}s`,
										}}
									>
										<WrongIcon className="fill-white w-8 h-8 text-black" />
									</div>
								</>
							)}
						</Item>
					))}
					{(submittedAnswer || []).map((answer, i) => (
						<div
							key={i}
							className="transition-pos -z-1 absolute flex w-full duration-500 delay-500"
							style={{
								top:
									sum(
										submittedAnswer
											.filter((j) => j < submittedAnswer[i])
											.map((j) => submittedAnswer.indexOf(j))
											.map((j) => sizes[j] + (isDesktop ? 18 : 4))
									) + (showCorrectAnswers ? 8 : 0),
								left: showCorrectAnswers ? 8 : 0,
							}}
						>
							<Button
								borderRadius={BUTTON_RADIUS}
								disabled={true}
								disabledOpacity={false}
								color={["answer1", "answer2", "answer3", "answer4"][i % answers.length]}
								border={true}
								className={tailwindCascade("flex-grow w-full self-start leading-none")}
								maxHeight={isDesktop ? BUTTON_MAX_HEIGHT : undefined}
								icon={<div className="relative w-6 h-6"></div>}
								iconAlign="right"
								align="left"
							>
								<div className="md:py-4">{upperFirst(answers[i].text, language)}</div>
							</Button>
						</div>
					))}

					{/* {range(answers.length - 1).map((i) => (
					<Button
						key={i}
						type="div"
						borderRadius={BUTTON_RADIUS}
						disabled={showCorrectAnswers || submittedAnswer !== null}
						border={true}
						onMouseEnter={() => {
							if (!submittedAnswer) {
								sfx.play("hoverFeedback", false, 0.75);
							}
						}}
						className={tailwindCascade("text-2xl z-2 px-3", "absolute right-6 -translate-y-1/2", {
							"animate-media": visible,
							"pointer-events-none opacity-0 transition-opacity":
								showCorrectAnswers || submittedAnswer !== null,
						})}
						style={{
							animationDelay: `${((1 - 0.64) / answers.length) * i}s`,
							animationFillMode: "backwards",
							top: 105 * (i + 1),
						}}
						hoverClassName="group-hover:bg-opacity-40"
						maxHeight={BUTTON_MAX_HEIGHT}
						onClick={() => void swap(i, i + 1)}
					>
						<SwapIcon className="h-12" />
					</Button>
				))} */}
				</div>

				{(haveLocalPlayer || hasInteracted) && (
					<div className="md:mt-8 relative mt-4">
						<Button
							ref={submitButton}
							color="green-light"
							border={true}
							onClick={handleSubmit}
							className={tailwindCascade("md:text-2xl text-white", {
								"pointer-events-auto": pointerEvents,
								"pointer-events-none": !pointerEvents,
								"animate-media": visible && !noAnimation,
								"opacity-25 transition-opacity duration-300":
									submittedAnswer !== null ||
									(haveLocalPlayer && submittedAnswer === null && showCorrectAnswers),
								"opacity-0 pointer-events-none": !visible,
								"animate-flash":
									highlightSubmit &&
									submittedAnswer === null &&
									statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER,
								"pause-anim": paused,
							})}
							disabled={haveAnswered}
							style={{
								animationDelay: `${1 - 0.64}s`,
								animationFillMode: "backwards",
							}}
						>
							{trans("Submit answer")}
						</Button>
						{/* {
							<div
								className={tailwindCascade(
									"top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full opacity-0",
									{
										"opacity-100 transition-opacity":
											submittedAnswer &&
											showCorrectAnswers &&
											revealAnswer === positions.length &&
											answers.every((ans, i) => ans.pos === submittedAnswer[i]),
									}
								)}
							>
								<CorrectIcon className="fill-white w-8 h-8 text-black" />
							</div>
						} */}
					</div>
				)}
			</div>
			{answers.map((answer, i) => (
				<TextVoice
					key={i}
					isHost={isHost}
					slideId={slide.id}
					play={revealAnswer === answer.pos}
					savedVoice={slide.answerVoice}
					voiceOverride={voiceOverride}
					text={[`${answer.pos + 1}-`, answer.text]}
					onEnd={onVoiceEnd}
					onPlayError={onVoiceError}
				/>
			))}
		</>
	);
}

// function UpIcon(props) {
// 	return (
// 		<svg
// 			version="1.0"
// 			xmlns="http://www.w3.org/2000/svg"
// 			width="1280.000000pt"
// 			height="1280.000000pt"
// 			viewBox="0 0 1280.000000 1280.000000"
// 			preserveAspectRatio="xMidYMid meet"
// 			{...props}
// 		>
// 			<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
// 				<path
// 					d="M6238 12785 c-140 -27 -278 -90 -388 -178 -30 -24 -242 -224 -470
// -443 -229 -220 -1466 -1406 -2750 -2637 -1284 -1231 -2367 -2274 -2406 -2318
// -83 -94 -151 -217 -190 -344 -25 -81 -27 -105 -27 -240 0 -134 2 -159 26 -238
// 94 -307 304 -517 614 -614 l88 -28 1472 -3 1473 -2 2 -2513 3 -2512 28 -85
// c114 -341 383 -571 724 -620 56 -8 628 -10 2013 -8 2061 4 1936 1 2075 46 231
// 75 445 280 535 514 64 165 60 -7 60 2704 l0 2474 1473 2 1472 3 88 28 c308 96
// 521 309 613 612 25 81 27 105 27 240 0 134 -2 159 -26 238 -46 148 -119 275
// -219 379 -29 30 -766 739 -1638 1574 -872 836 -2107 2021 -2745 2633 -638 612
// -1187 1135 -1220 1162 -78 63 -219 133 -320 160 -106 27 -283 34 -387 14z"
// 				/>
// 			</g>
// 		</svg>
// 	);
// }

// function SwapIcon(props) {
// 	return (
// 		<svg
// 			xmlns="http://www.w3.org/2000/svg"
// 			shapeRendering="geometricPrecision"
// 			textRendering="geometricPrecision"
// 			imageRendering="optimizeQuality"
// 			fillRule="evenodd"
// 			clipRule="evenodd"
// 			viewBox="0 0 443 511.62"
// 			{...props}
// 		>
// 			<path
// 				fillRule="nonzero"
// 				d="M152.93 286.97c0 17.1-13.87 30.97-30.97 30.97-17.11 0-30.98-13.87-30.98-30.97v-177.4l-37.45 40.31c-11.63 12.5-31.19 13.2-43.68 1.57-12.49-11.62-13.19-31.18-1.57-43.68L99.33 9.79l2.06-1.94c12.69-11.35 32.2-10.26 43.55 2.43l91.05 101.47c11.35 12.69 10.26 32.2-2.43 43.55-12.68 11.36-32.19 10.27-43.55-2.42l-37.08-41.33v175.42zm236.24 71.77c11.35-12.69 30.86-13.78 43.55-2.43 12.69 11.36 13.78 30.87 2.42 43.56L344.1 501.34c-11.36 12.69-30.87 13.78-43.55 2.42l-2.02-1.97-91.09-97.95c-11.63-12.49-10.93-32.05 1.57-43.67 12.49-11.63 32.05-10.93 43.67 1.57l37.46 40.31V231.53c0-17.11 13.87-30.97 30.97-30.97s30.97 13.86 30.97 30.97v168.54l37.09-41.33z"
// 			/>
// 		</svg>
// 	);
// }

function Item({ id, index, moveCard, children, pos, setSize, style, ...props }) {
	const ref = useRef(null);

	const size = useElementSize(ref.current);
	useEffect(() => void setSize(index, size), [index, setSize, size]);

	return (
		<div ref={ref} style={style} data-pos={pos} data-index={index} {...props}>
			{children}
		</div>
	);
}
