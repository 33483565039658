import React, { useCallback, useRef, useEffect, useState, useMemo } from "react";
import { sfx } from "@/helpers/audio";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Button, { BUTTON_BORDER_RADIUS } from "@/components/interactives/Button";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import WrongIcon from "@/images/icons/icon-wrong-multicolor.svg";
import { BUTTON_MAX_HEIGHT } from "./BaseSlide";
import trans from "@/helpers/trans";
import { useImmer } from "use-immer";
import div from "react-indiana-drag-scroll";
import useLayoutStore from "@/stores/layout";
import { getGrid } from "../../edit/RangeSlideEditor";
import isFinite from "lodash/isFinite";
import useConfetti from "@/hooks/useConfetti";
import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE } from "@/colors";
import ProgressIndicator from "@/components/ProgressIndicator";
import TextVoice from "../../TextVoice";
import { formatAnswerString, formatPoints } from "@/helpers/stringHelper";
import isString from "lodash/isString";
import useStatusSequence from "@/hooks/useStatusSequence";
import {
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_HIDE_INPUT,
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_RESULTS,
	RANGE_SLIDE_ERROR_SCORE,
	RANGE_SLIDE_ERROR_SCORE_BREAKPOINT,
} from "@/constants";
import Avatar from "@/components/Avatar";
import useWindowInnerSize from "@/hooks/useWindowInnerSize";
import useElementSize from "@/hooks/useElementSize";
import clamp from "lodash/clamp";
import isInteger from "lodash/isInteger";
import Header from "@/components/Header";
import { SLIDE_TYPE_RANGE } from "@/app-constants.mjs";
import useViewportSize from "@/hooks/useViewportSize";
import useRefMounted from "@/hooks/useRefMounted";
import getAutoRange from "@/helpers/getAutoRange";

const AVATAR_SIZE = 64 * 2;

export default function RangeAnswersFragment({
	className,
	doublePoints,
	slide,
	slideIndex,
	active = true,
	visible = true,
	setAnswerRef = null,
	onAnswer = (ans) => {},
	paused,
	haveLocalPlayer,
	submittedAnswer = null,
	showCorrectAnswers,
	showProgressBar,
	waitForAnswerProgress,
	submittedAnswerProgress,
	isHost,
	statusWithProgress,
	players,
	voiceOverride,
	reducedMotion,
	teams,
	teamMode,
}) {
	const [correctAnswer, unit] = useMemo(
		() => (slide?.answers ? slide.answers[0].text.split(/ (.*)/s) : [undefined, undefined]),
		[slide?.answers]
	);

	const exactly = useMemo(() => slide?.answers[2]?.text === "exactly", [slide?.answers]);

	const [min, max] = useMemo(() => {
		if (slide.answers[1].text === "" || slide.answers[1].text === ",") {
			return getAutoRange(slide.answers[0].text);
		}

		const arr = slide.answers[1].text.split(",");
		if (arr.length == 1 && slide.answers[1].text !== "") {
			return [arr[0], ""];
		} else if (arr.length >= 2) {
			return [arr[0], arr[1]];
		} else {
			return ["", ""];
		}
	}, [slide.answers]);

	const grid = useMemo(() => getGrid({ min, max, value: correctAnswer }), [correctAnswer, max, min]);
	const [rulerValue, setRulerValue] = useState(grid[Math.floor(grid.length / 2)]);

	const { getVisibility, getProgress } = useStatusSequence(slide.type, statusWithProgress);

	const showInput = getVisibility(PLAY_STATUS_LOAD_SLIDE, PLAY_STATUS_ALL_ANSWERS_RECEIVED);
	const mountResults = getVisibility(PLAY_STATUS_HIDE_INPUT);
	const showResults = getVisibility(PLAY_STATUS_SHOW_RESULTS);

	return (
		<>
			<div
				className={tailwindCascade(
					"flex flex-col items-start md:items-center",
					"md:w-full h-full md:h-auto",
					{
						"opacity-0 transition-opacity duration-300": !visible,
						"pointer-events-none": !visible || submittedAnswer !== null || paused || !active,
						"absolute left-1/2 transform -translate-x-1/2 md:translate-x-0 md:relative md:left-auto":
							showResults,
					},
					className
				)}
			>
				{mountResults ? (
					<RangeResults
						correctAnswer={correctAnswer}
						doublePoints={doublePoints}
						exactly={exactly}
						grid={grid}
						isHost={isHost}
						players={players}
						slide={slide}
						start={showResults}
						statusWithProgress={statusWithProgress}
						unit={unit}
						visible={showResults}
						voiceOverride={voiceOverride}
						reducedMotion={reducedMotion}
						teams={teams}
						teamMode={teamMode}
					/>
				) : (
					<RangeInput
						grid={grid}
						onAnswer={onAnswer}
						paused={paused}
						setValue={setRulerValue}
						showProgressBar={showProgressBar}
						slideIndex={slideIndex}
						submittedAnswer={submittedAnswer}
						submittedAnswerProgress={submittedAnswerProgress}
						unit={unit}
						value={rulerValue}
						visible={showInput}
						waitForAnswerProgress={waitForAnswerProgress}
					/>
				)}
			</div>
		</>
	);
}

function RangeInput({
	className,
	grid,
	onAnswer,
	paused,
	setValue,
	showProgressBar,
	slideIndex,
	submittedAnswer,
	submittedAnswerProgress,
	unit,
	value,
	visible,
	waitForAnswerProgress,
	...props
}) {
	return (
		<div
			className={tailwindCascade(
				"flex flex-col items-center w-full gap-8",
				"transition-opacity duration-500",
				{
					"opacity-0": !visible,
				},
				className
			)}
		>
			<RangeRuler
				unit={unit}
				grid={grid}
				userValue={value}
				setUserValue={setValue}
				submittedAnswer={submittedAnswer}
			/>
			{submittedAnswer === null ? (
				<Button
					className={tailwindCascade("md:text-2xl md:w-80 w-full text-white", {
						"opacity-50": submittedAnswer !== null,
					})}
					border={true}
					color="green-light"
					onClick={() => void onAnswer(slideIndex, value)}
				>
					{trans("Submit")}
				</Button>
			) : (
				<div
					className={tailwindCascade(
						"bg-black-50 md:w-80 w-full px-8 py-4 font-black text-white flex flex-col items-center"
					)}
					style={{ borderRadius: BUTTON_BORDER_RADIUS }}
				>
					<Header className="md:text-2xl text-lg">{trans("Answer submitted")}</Header>
				</div>
			)}

			<ProgressIndicator
				className="w-full h-8"
				progress={waitForAnswerProgress}
				submittedAnswerProgress={submittedAnswerProgress}
				visible={showProgressBar}
				paused={paused}
				showPoints={false}
			/>
		</div>
	);
}

function RangeRuler({ className, setUserValue, unit, grid, userValue, players, submittedAnswer, ...props }) {
	const [mounted] = useRefMounted();
	const el = useRef(null);
	const [isGrabbing, setIsGrabbing] = useState(false);

	const { isDesktop } = useViewportSize();
	const containerScale = useLayoutStore((state) => state.containerScale);
	const scaleRef = useRef(containerScale);
	useEffect(() => void (scaleRef.current = isDesktop ? containerScale : 1), [containerScale, isDesktop]);

	const mouseDownX = useRef(0);
	const mouseDownScrollLeft = useRef(0);

	const [x1stStep, setX1stStep] = useState(0);
	const [xPerStep, setXPerStep] = useState(0);

	const oldPos = useRef(null);

	useEffect(() => {
		// Initial scroll to center (will also happen if you go from desktop to mobile or vice versa)
		if (el.current) {
			el.current.scrollLeft = (grid.length - 1) * 0.5 * xPerStep;
		}
		oldPos.current = (grid.length - 1) * 0.5;
	}, [grid, xPerStep]);

	const handleMouseDown = useCallback(
		(ev) => {
			if (ev.button !== 0) {
				return;
			}

			const handleMouseMove = (ev) => {
				const xrel = (ev.clientX - mouseDownX.current) / scaleRef.current;
				if (el.current) {
					el.current.scrollLeft = mouseDownScrollLeft.current - xrel;
				}
			};

			const handleMouseUp = (ev) => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
				setIsGrabbing(false);

				let previousTimeStamp = null;
				let currentScrollLeft = el.current ? el.current.scrollLeft : 0; // with fraction
				const targetScrollLeft = Math.round(currentScrollLeft / xPerStep) * xPerStep;

				function snap(timestamp) {
					if (previousTimeStamp === null) {
						previousTimeStamp = timestamp;
					}

					const a = Math.pow(0.95, timestamp - previousTimeStamp);
					currentScrollLeft = (1 - a) * targetScrollLeft + a * currentScrollLeft;

					const newScrollLeft = Math.round(currentScrollLeft);
					if (el.current) {
						el.current.scrollLeft = newScrollLeft;
					}

					previousTimeStamp = timestamp;

					if (targetScrollLeft !== newScrollLeft && el.current) {
						window.requestAnimationFrame(snap);
					}
				}

				if (isFinite(targetScrollLeft)) {
					window.requestAnimationFrame(snap);
				}
			};

			ev.preventDefault();

			mouseDownX.current = ev.clientX;
			mouseDownScrollLeft.current = el.current ? el.current.scrollLeft : 0;

			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);

			setIsGrabbing(true);

			return () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
				setIsGrabbing(false);
			};
		},
		[xPerStep]
	);

	const handleScroll = useCallback(
		(ev) => {
			const pos = clamp(Math.round(ev.target.scrollLeft / xPerStep), 0, grid.length - 1);
			if (oldPos.current !== null && pos !== oldPos.current) {
				sfx.play("hoverFeedback", false, 0.75);
			}
			oldPos.current = pos;
			setUserValue(grid[pos]);
		},
		[grid, setUserValue, xPerStep]
	);

	const handleWheel = useCallback(
		(ev) => {
			if (el.current) {
				el.current.scrollLeft = el.current.scrollLeft + 0.01 * ev.deltaY * xPerStep;
			}
		},
		[xPerStep]
	);

	const rulerRef = useRef(null);
	useEffect(() => {
		if (mounted && rulerRef.current) {
			const handleResize = (el) => {
				if (el.hasChildNodes() && el.firstChild.hasChildNodes()) {
					const rulerWidth = el.offsetWidth;
					const gridWidth = el.firstChild.offsetWidth;
					const tickWidth = el.firstChild.firstChild.offsetWidth;
					setX1stStep((rulerWidth - gridWidth + tickWidth) * 0.5);
					setXPerStep(tickWidth);
				}
			};

			handleResize(rulerRef.current);

			const observer = new ResizeObserver((entries) => void handleResize(entries[0].target));
			observer.observe(rulerRef.current);

			return () => observer.disconnect();
		}
	}, [mounted]);

	return (
		<div
			className={tailwindCascade("relative flex flex-col items-center w-full -space-y-4", className)}
			onWheel={handleWheel}
		>
			<div className="z-1 relative overflow-visible">
				<div
					className={tailwindCascade(
						"font-black text-black bg-white",
						"md:border-8 border-4 border-black border-solid rounded-2xl text-center",
						"shadow-hard-2",
						"flex flex-col gap-0 min-w-64",
						"md:px-10 px-5 md:py-4 py-2"
					)}
				>
					<div className="z-1 md:text-5xl whitespace-nowrap text-2xl">{formatValue(userValue)}</div>
					{unit && <div className="z-1 md:text-2xl text-base">{unit}</div>}
				</div>
				<div
					className={tailwindCascade(
						"absolute left-1/2 bottom-0 -translate-x-1/2 md:translate-y-[13px] translate-y-[11px]",
						"md:w-8 md:h-8 w-6 h-6 transform rotate-45 bg-white",
						"md:border-b-8 md:border-r-8 border-b-4 border-r-4 border-black border-solid"
					)}
				></div>
			</div>
			<div
				ref={el}
				className={tailwindCascade(
					"relative flex flex-row w-full p-0",
					"touch-pan-x overflow-auto select-none",
					"scrollbar-none",
					{ "snap-x snap-mandatory": !isGrabbing }
				)}
				onScroll={handleScroll}
			>
				<div className="shrink-0" style={{ width: `calc(50% - ${x1stStep}px)` }}></div>
				<div
					className={tailwindCascade("shadow-hard-2 mb-2 cursor-grab rounded-xl", {
						"cursor-grabbing": isGrabbing,
					})}
					onMouseDown={handleMouseDown}
					role="slider"
					aria-valuenow={userValue}
					aria-valuemin={grid[0]}
					aria-valuemax={grid[grid.length - 1]}
					tabIndex="0"
				>
					<div
						ref={rulerRef}
						className={tailwindCascade(
							"bg-yellow rounded-xl -shadow-inner-hard-2 box-border relative text-2xl font-black",
							"md:border-8 border-4 border-black border-solid",
							"md:pt-6 md:pb-6 md:px-32 pt-2 pb-4 px-16",
							{
								"opacity-50 transition-opacity duration-300": submittedAnswer !== null,
							}
						)}
					>
						<div className="flex flex-row items-stretch">
							{grid.map((value, i) => (
								<div
									key={i}
									className={tailwindCascade(
										"shrink-0 flex items-center justify-start flex-col overflow-visible md:h-40 h-20",
										"md:text-2xl text-lg md:w-6 w-4",
										{ "snap-center": !isGrabbing }
									)}
								>
									<div
										className={tailwindCascade(
											"md:w-1.5 w-[3px] bg-black bg-opacity-25 md:h-20 h-12 shrink-0 rounded-full",
											{
												"bg-opacity-100 md:h-24 h-16 w-1 md:w-2": i % 10 === 0,
											}
										)}
									></div>
									{i % 10 === 0 && <div className="whitespace-nowrap">{formatValue(value)}</div>}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="shrink-0" style={{ width: `calc(50% - ${x1stStep}px)` }}></div>
			</div>
		</div>
	);
}

function RangeResults({
	className,
	correctAnswer,
	doublePoints,
	exactly,
	grid,
	isHost,
	players,
	slide,
	start,
	statusWithProgress,
	unit,
	visible,
	voiceOverride,
	reducedMotion,
	teams,
	teamMode,
	...props
}) {
	const [y1stStep, setY1stStep] = useState(0);
	const [yPerStep, setYPerStep] = useState(0);

	const ref = useRef(null);
	const markerRef = useRef(null);
	const gridRef = useRef(null);

	const correctAnswerIndex = grid.indexOf(correctAnswer);

	const markerSize = useElementSize(markerRef.current);
	const gridSize = useElementSize(gridRef.current);
	const windowInnerSize = useWindowInnerSize();

	const { getVisibility, getProgress } = useStatusSequence(SLIDE_TYPE_RANGE, statusWithProgress);
	const showAvatars = getVisibility(PLAY_STATUS_SHOW_AVATAR_COLORS);
	const progress = getProgress(PLAY_STATUS_SHOW_CORRECT_ANSWER);
	const showMarker = getVisibility(PLAY_STATUS_SHOW_CORRECT_ANSWER);

	const [playAnswerVoice, setPlayAnswerVoice] = useState(false);

	const reducedMotionRef = useRef(reducedMotion);
	useEffect(() => void (reducedMotionRef.current = reducedMotion), [reducedMotion]);

	const showConfetti = useConfetti({
		particleCount: 200,
		angle: 90,
		spread: 360,
		startVelocity: 21,
		decay: 0.8,
		gravity: 0.4,
		drift: 0,
		ticks: 200,
		colors: [ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE],
		shapes: ["square", "square", "square", "circle"],
		scalar: 5,
	});
	const showConfettiRef = useRef(showConfetti);
	useEffect(() => void (showConfettiRef.current = showConfetti), [showConfetti]);

	const markerPos = (1 - (1 - progress) ** 2) * correctAnswerIndex;
	const markerValue = grid[Math.round(markerPos)];
	const scoreIndex = grid.length >= RANGE_SLIDE_ERROR_SCORE_BREAKPOINT ? 0 : 1;
	const scoreArray =
		scoreIndex === 0
			? [
					[RANGE_SLIDE_ERROR_SCORE[scoreIndex][0], 1000],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][0] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + 1) / 2, 800],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + 1) / 2, 600],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][3] + 1) / 2, 400],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][0] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + 1) / -2, 800],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + 1) / -2, 600],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][3] + 1) / -2, 400],
			  ]
			: [
					[RANGE_SLIDE_ERROR_SCORE[scoreIndex][0], 1000],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + 1) / 2, 600],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][3] + 1) / 2, 400],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][1] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + 1) / -2, 600],
					[(RANGE_SLIDE_ERROR_SCORE[scoreIndex][2] + RANGE_SLIDE_ERROR_SCORE[scoreIndex][3] + 1) / -2, 400],
			  ];

	useEffect(() => {
		if (progress === 1) {
			setPlayAnswerVoice(true);
			if (!reducedMotionRef.current && showConfettiRef.current) {
				showConfettiRef.current(ref.current.parentElement.parentElement, markerRef.current);
			}
		}
	}, [progress, setPlayAnswerVoice]);

	// useEffect(() => {
	// 	if (start) {
	// 		const target = {
	// 			progress: 0,
	// 		};

	// 		const timeline = gsap.timeline({
	// 			onUpdate: () => {
	// 				const markerIndex = target.progress * correctAnswerIndex;
	// 				setMarkerValue(grid[Math.round(markerIndex)]);
	// 				setMarkerPos(markerIndex);
	// 				setAnimationPhase(target.progress);
	// 			},
	// 			onComplete: () => {
	// 				setPlayAnswerVoice(true);
	//				if (showConfettiRef.current) {
	//					showConfettiRef.current(ref.current.parentElement.parentElement, markerRef.current);
	//				}
	// 				setAnimationPhase(1);
	// 			},
	// 		});

	// 		timeline.to(target, {
	// 			progress: 1,
	// 			duration: 3,
	// 		});

	// 		timeline.play();

	// 		return () => void timeline.kill();
	// 	}
	// }, [correctAnswerIndex, grid, setPlayAnswerVoice, showConfetti, start]);

	const playersArray = useMemo(
		() =>
			Array.from(players.values())
				.filter((player) => isString(player.selectedAnswer))
				.map((player) => ({
					...player,
					gridPos: grid.indexOf(player.selectedAnswer),
					distance: `${parseFloat(player.selectedAnswer) - parseFloat(correctAnswer)} ${unit}`,
				})),
		[correctAnswer, grid, players, unit]
	);

	useEffect(() => {
		const updateSizes = () => {
			if (ref.current && gridRef.current && gridRef.current.childNodes && gridRef.current.childNodes.length > 0) {
				setYPerStep(gridRef.current.offsetHeight / gridRef.current.childNodes.length);
				setY1stStep(
					(ref.current.offsetHeight -
						gridRef.current.offsetHeight +
						gridRef.current.childNodes[0].offsetHeight) *
						0.5
				);
			}
		};

		if (ref.current) {
			const resizeObserver = new ResizeObserver((entries) => void updateSizes());

			resizeObserver.observe(ref.current);
			return () => void resizeObserver.disconnect();
		}
	}, []);

	return (
		<div
			ref={ref}
			className={tailwindCascade(
				"md:absolute relative opacity-0 h-full",
				{
					"opacity-100 transition-opacity duration-500": visible,
				},
				className
			)}
		>
			<div
				ref={markerRef}
				className={tailwindCascade("z-2 absolute overflow-visible transform translate-y-1/2 opacity-0", {
					"opacity-100 transition-opacity duration-300": showMarker,
				})}
				style={{
					bottom: `${y1stStep + yPerStep * markerPos}px`,
					left: -Math.min((windowInnerSize.width - gridSize.offsetWidth - 16) / 2, markerSize.offsetWidth),
				}}
			>
				<div
					className={tailwindCascade(
						"font-black text-black bg-white",
						"md:border-8 border-4 border-black border-solid rounded-2xl md:px-8 px-4 text-center py-2",
						"shadow-hard-2",
						"md:min-w-64 whitespace-nowrap"
					)}
				>
					<span className="md:text-6xl whitespace-nowrap text-4xl">{formatValue(markerValue)} </span>
					{unit && <span className="md:text-3xl text-xl">{unit}</span>}
				</div>
				<div
					className={tailwindCascade(
						"absolute top-1/2 right-0 -translate-y-1/2 md:translate-x-[13px] translate-x-[9px]",
						"md:w-8 md:h-8 w-5 h-5 transform rotate-45 bg-white",
						"md:border-t-8 md:border-r-8 border-t-4 border-r-4 border-black border-solid"
					)}
				></div>
			</div>

			{!exactly &&
				scoreArray
					.filter(
						([offset, points]) =>
							correctAnswerIndex - offset >= 0 && correctAnswerIndex - offset < grid.length
					)
					.map(([offset, points], i) => (
						<Header
							key={i}
							className={tailwindCascade(
								"z-1 md:right-4 right-3 md:text-lg absolute text-base transform translate-y-1/2 opacity-0",
								{ "opacity-100 transition-opacity duration-500 delay-300": progress === 1 }
							)}
							style={{
								bottom: `${y1stStep + yPerStep * (correctAnswerIndex - offset)}px`,
							}}
						>
							{(doublePoints ? 2 : 1) * points}
						</Header>
					))}
			<div
				className={tailwindCascade(
					"bg-yellow rounded-xl -shadow-inner-hard-2 relative py-6 text-2xl font-black h-full",
					"box-border md:border-8 border-4 border-black border-solid",
					{ "bg-white-50 transition-colors duration-500 delay-500": showAvatars }
				)}
			>
				<div className="justify-evenly flex flex-col-reverse h-full" ref={gridRef}>
					{grid
						.map((value, i) => [value, Math.abs(correctAnswerIndex - i)])
						.map(([value, err], i) => (
							<div
								key={i}
								className={tailwindCascade(
									"shrink-0 flex items-center justify-start flex-row overflow-visible w-full relative h-1 flex-grow basis-0",
									"md:pl-5 md:pr-20 pl-3 pr-14",
									"md:text-2xl text-lg",
									{
										"transition-colors duration-500 delay-300": progress === 1,
										"bg-white":
											!exactly && progress === 1 && err <= RANGE_SLIDE_ERROR_SCORE[scoreIndex][3],
										"bg-cyan":
											!exactly && progress === 1 && err <= RANGE_SLIDE_ERROR_SCORE[scoreIndex][2],
										"bg-error":
											!exactly && progress === 1 && err <= RANGE_SLIDE_ERROR_SCORE[scoreIndex][1],
										"bg-yellow": progress === 1 && err <= RANGE_SLIDE_ERROR_SCORE[scoreIndex][0],
									}
								)}
							>
								{i % 10 === 0 ? (
									<div className="shrink-0 relative flex flex-row items-center w-24 h-1 gap-1 overflow-visible">
										<div className="flex-grow h-1 bg-black rounded-full"></div>
										<div className="z-1 whitespace-nowrap text-base">{formatValue(value)}</div>
										<div className="flex-grow h-1 bg-black rounded-full"></div>
									</div>
								) : (
									<div className="shrink-0 w-24 h-0.5 bg-black bg-opacity-25 rounded-full"></div>
								)}
								{/* {i % 10 === 0 && <div className="z-1 whitespace-nowrap pl-1">{formatValue(value)}</div>} */}
							</div>
						))}
				</div>
			</div>
			<div className="absolute inset-0">
				{grid.map((_value, i) => (
					<div
						key={i}
						className="absolute transform translate-y-1/2 translate-x-full right-0.5 md:right-2 flex flex-row -space-x-8"
						style={{ bottom: y1stStep + i * yPerStep }}
					>
						{playersArray
							.filter((player) => player.gridPos === i)
							.map((player) => ({ player, team: teams.get(player.teamId) }))
							.map(({ player, team }, j) => (
								<div key={j}>
									{j === 0 && (
										<div className="top-1/2 absolute left-0 w-3 h-3 transform rotate-45 -translate-x-1/2 -translate-y-1/2 bg-black"></div>
									)}
									<Avatar
										className="w-16 h-16"
										playerAvatar={(teamMode && team?.avatar) || player.avatar}
										playerName={player.name}
										playerPoints={player.previousPoints}
										playerConnectionId={player.connectionId}
										showName={false}
										width={AVATAR_SIZE}
										height={AVATAR_SIZE}
									/>
								</div>
							))}
					</div>
				))}
			</div>
			{slide?.id && (slide?.answerVoice || voiceOverride) && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={playAnswerVoice}
					savedVoice={slide.answerVoice}
					text={slide.answers[0].text}
					voiceOverride={voiceOverride}
				/>
			)}
		</div>
	);
}
function formatValue(value) {
	const valueFloat = parseFloat(value);

	if (Math.log10(valueFloat) >= 4) {
		// insert space on every third position from the right starting at a point if it exists...
		let i = value.indexOf(".");
		if (i < 0) {
			i = value.length;
		}
		while (i > 0) {
			value = value.substr(0, i) + " " + value.substr(i);
			i -= 3;
		}
	}
	return value;
}
