import { useState, useEffect } from "react";
import { getGeoFeature } from "@/helpers/geo";

export default function useGeoFeature({ lat, lng, placeType, placeCode, country, condition }) {
	const [feature, setFeature] = useState(null);
	useEffect(() => {
		if (condition === false) {
			setFeature(null);
		} else {
			let mounted = true;
			(async () => {
				try {
					const feature = await getGeoFeature({ lat, lng, placeType, placeCode, country });
					if (mounted) {
						setFeature(feature);
					}
				} catch (error) {
					if (mounted) {
						setFeature(null);
					}
				}
			})();
			return () => void (mounted = false);
		}
	}, [condition, country, lat, lng, placeCode, placeType]);

	return feature;
}
