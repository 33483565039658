import React, { useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";

const DEFAULT_VIEWPORT_SIZE = { width: 390, height: 844, isDesktop: false }; /* iphone 12 pro */

function getViewportSize(initialValue) {
	if (typeof window !== "undefined") {
		const width = window.visualViewport.width;
		const height = window.visualViewport.height;
		return { width, height, isDesktop: width >= 768 };
	}

	return DEFAULT_VIEWPORT_SIZE;
}

export default function useViewportSize() {
	const [viewportSize, setViewportSize] = useState(getViewportSize());
	const viewportSizeRef = useRef(viewportSize);
	useEffect(() => void (viewportSizeRef.current = viewportSize), [viewportSize]);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const onResize = (update = false) => {
				const viewportSize = getViewportSize();
				if (update || !isEqual(viewportSize, viewportSizeRef.current)) {
					setViewportSize(getViewportSize());
				}
			};

			onResize(true); // initial state update

			const _onResize = () => onResize();
			window.visualViewport.addEventListener("resize", _onResize);
			return () => void window.visualViewport.removeEventListener("resize", _onResize);
		}
	}, []);

	return viewportSize;
}
