import React, { useEffect, useState } from "react";

import isNumber from "lodash/isNumber";
import clamp from "lodash/clamp";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import { getPointsFromPointsProgress, getPointsProgressFromAnswerProgress } from "@/helpers/answerTimes";
import { sfx } from "@/helpers/audio";
import shade from "@/helpers/shade";

import Header from "@/components/Header";

import { ANSWER1, ANSWER2, ANSWER3, ANSWER4 } from "@/colors";

const BACKGROUNDS_DARK_OPACITY = 0.5;

const BACKGROUNDS = [ANSWER1, ANSWER3, ANSWER2, ANSWER4];
const BACKGROUNDS_DARK = [
	shade(BACKGROUNDS[0], -BACKGROUNDS_DARK_OPACITY),
	shade(BACKGROUNDS[1], -BACKGROUNDS_DARK_OPACITY),
	shade(BACKGROUNDS[2], -BACKGROUNDS_DARK_OPACITY),
	shade(BACKGROUNDS[3], -BACKGROUNDS_DARK_OPACITY),
];

export default function ProgressIndicator({
	className,
	doublePoints = false,
	isQuestion = true,
	paused,
	progress,
	showPoints = true,
	submittedAnswerProgress = null,
	visible,
}) {
	progress = clamp(progress, 0, 1);

	const hasSubmittedAnswer = submittedAnswerProgress !== null;
	const hide = !hasSubmittedAnswer && progress === 1 && showPoints;

	useEffect(() => {
		if (!hasSubmittedAnswer && progress === 1 && isQuestion) {
			sfx.play("timerEnd");
		}
	}, [hasSubmittedAnswer, isQuestion, progress]);

	const transform = isQuestion ? (progress) => getPointsProgressFromAnswerProgress(progress) : (progress) => progress;

	const globalProgress = transform(progress);
	const myProgress = isNumber(submittedAnswerProgress) ? transform(submittedAnswerProgress) : undefined;
	const pointsProgress = isNumber(myProgress) ? myProgress : globalProgress;

	const [soundName, setSoundName] = useState(null);

	useEffect(() => {
		if (!isQuestion || paused || hasSubmittedAnswer || progress === 0 || progress === 1) {
			setSoundName(null);
		} else {
			let name = null;

			if (progress < 0.6) {
				name = "timer89bpm";
			} else if (progress < 0.8) {
				name = "timerDubbelTempo";
			} else if (progress < 1) {
				name = "timerTrippelTempo";
			}

			if (name !== soundName) {
				setSoundName(name);
			}
		}
	}, [hasSubmittedAnswer, isQuestion, paused, progress, soundName]);

	useEffect(() => {
		if (soundName) {
			const id = sfx.play(soundName, true);
			return () => void sfx.stop(id);
		}
	}, [soundName]);

	return (
		<div
			className={tailwindCascade(
				"pointer-events-none opacity-0 duration-300 transition-opacity",
				{ "opacity-100": visible && !hide },
				className
			)}
		>
			<div className="relative">
				<div className="relative z-0 overflow-hidden rounded-full h-[8px] md:h-[21px] border-2 md:border-4 border-black border-solid">
					<div className="opacity-30 absolute top-0 left-0 w-full h-full bg-black" />
					<div className="absolute top-0 left-0 w-full h-full">
						<Bar
							progress={myProgress}
							dark={true}
							className={tailwindCascade({ "opacity-0": !isNumber(myProgress) })}
						/>
						<Bar progress={globalProgress} />
					</div>
				</div>
				{isQuestion && showPoints && isNumber(pointsProgress) && (
					<div className="z-1 absolute top-0 left-0 w-full h-full">
						<div
							className={tailwindCascade("top-1/2", "absolute", "transition-opacity", "duration-500", {
								"opacity-0": pointsProgress >= 1,
							})}
							style={{ left: `${100 - pointsProgress * 100}%` }}
						>
							<div
								className={tailwindCascade("absolute", "top-0", "left-0", "origin-center", {
									"animate-bounce": hasSubmittedAnswer,
								})}
							>
								<Header
									className="md:text-3xl absolute text-lg font-black"
									style={{
										transform: `translate(${-100 + 100 * pointsProgress}%,-50%)`,
									}}
								>
									{Math.round((doublePoints ? 2 : 1) * getPointsFromPointsProgress(pointsProgress)) ||
										""}
								</Header>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

function Bar({ progress, dark = false, className }) {
	return isNumber(progress) ? (
		<div
			className={tailwindCascade(
				"absolute",
				"w-full",
				"h-full",
				"overflow-hidden",
				"md:border-r-4",
				"border-r-2",
				"border-black",
				"border-solid",
				className
			)}
			style={{
				transform: `translate(${-progress * 100}% ,0%)`,
			}}
		>
			<div
				className="absolute w-full h-full"
				style={{
					transform: `translate(${progress * 100 * 3}% ,0%)`,
				}}
			>
				<img
					className="absolute top-0 -left-[200%] max-w-none w-[350%] h-full object-cover"
					src={dark ? "/images/progress/progress-dark.svg" : "/images/progress/progress.svg"}
					alt=""
					draggable={false}
				/>
			</div>
			<div className="opacity-40 absolute top-0 left-0 w-full bg-white md:h-[3px] h-[1px]" />
			<div className="opacity-20 absolute bottom-0 left-0 w-full bg-black md:h-[3px] h-[1px]" />
		</div>
	) : (
		<></>
	);
}
